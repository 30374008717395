<template>
  <v-container fluid fill-height>
    <fc-map />
    <fc-map-bar :loading="loading" @update="updateRtu" />
    <slot />
    <scrollbar-size-handler />
  </v-container>
</template>

<script>
import FcMap from '~/components/map/Map'
import FcMapBar from '~/components/map/Bar'
import ScrollbarSizeHandler from '~/components/utils/ScrollbarSizeHandler'
import generalFormMixin from '~/components/mixins/generalFormMixin'
import configApi from '~/config/api'

export default {
  components: {FcMap, FcMapBar, ScrollbarSizeHandler},
  mixins: [generalFormMixin],
  created () {
    this.$bus.$on('refreshSnapshot', this.refreshSnapshot)
    this.loadFields({showLoading: false})
    this.loadStations({showLoading: false})
    this.loadMeteos({showLoading: false})
    this.loadPumps({showLoading: false})
    return this.updateRtu({showLoading: false})
  },
  beforeDestroy () {
    this.$bus.$off('refreshSnapshot', this.refreshSnapshot)
    this._timeout && clearTimeout(this._timeout)
  },
  methods: {
    loadFields (options) {
      const showLoading = !options || !Object.prototype.hasOwnProperty.call(options, 'showLoading') || options.showLoading
      this.setLoading(showLoading)
      this._timeout && clearTimeout(this._timeout)
      this.setLoading(showLoading)
      return Promise.resolve()
          .then(() => this.$store.dispatch('field/fetch', {force: true}))
          .catch((error) => {
            // Hide this error (404 probably)
            console.log(error)
          })
    },
    loadStations (options) {
      const showLoading = !options || !Object.prototype.hasOwnProperty.call(options, 'showLoading') || options.showLoading
      this.setLoading(showLoading)
      this._timeout && clearTimeout(this._timeout)
      this.setLoading(showLoading)
      return Promise.resolve()
          .then(() => this.$store.dispatch('station/fetch', {force: true}))
          .catch((error) => {
            // Hide this error (404 probably)
            console.log(error)
          })
    },
    loadMeteos (options) {
      const showLoading = !options || !Object.prototype.hasOwnProperty.call(options, 'showLoading') || options.showLoading
      this.setLoading(showLoading)
      this._timeout && clearTimeout(this._timeout)
      this.setLoading(showLoading)
      return Promise.resolve()
          .then(() => this.$store.dispatch('meteos/fetch', {force: true}))
          .catch((error) => {
            // Hide this error (404 probably)
            console.log(error)
          })
    },
    loadPumps (options) {
      const showLoading = !options || !Object.prototype.hasOwnProperty.call(options, 'showLoading') || options.showLoading
      this.setLoading(showLoading)
      this._timeout && clearTimeout(this._timeout)
      this.setLoading(showLoading)
      return Promise.resolve()
          .then(() => this.$store.dispatch('pumps/fetch', {force: true}))
          .catch((error) => {
            // Hide this error (404 probably)
            console.log(error)
          })
    },
    updateRtu (options) {
      const showLoading = !options || !Object.prototype.hasOwnProperty.call(options, 'showLoading') || options.showLoading
      this._timeout && clearTimeout(this._timeout)
      this.setLoading(showLoading)
      return Promise.resolve()
        .then(() => this.$store.dispatch('rtu/fetch', {force: true}))
        .then(() => this.$store.dispatch('pumps/fetch', {force: true}))
        .catch((error) => {
          // Hide this error (404 probably)
          console.log(error)
        })
        .finally(() => {
          this.setLoading(false)
          this._timeout = setTimeout(() => this.updateRtu({showLoading}), configApi.rtuRequestInterval || 60000)
        })
    },

    refreshSnapshot ({id, resolve, reject}) {
      this.setLoading(true)
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.updateSnapshot', data: {id}}))
        .then((response) => {
          if (response.data.snapshot === 'error') {
            throw new Error(this.$t('errors.updateSnapshot'))
          } else if (response.data.snapshot === 'ok') {
            return this.$store.dispatch('rtu/updateRandomToken')
          }
        })
        .then(() => {
          resolve && resolve()
        })
        .catch((error) => {
          reject && reject(error)
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
  }
}
</script>
