<template>
  <component
    :is="component"
    :value="innerValue"
    :error="!!(errors || []).length"
    :error-messages="errors || []"
    v-bind="inputOptionsComputed"
    @input="handleInput"
    @change="handleChange"
  />
</template>

<script>

export default {
  props: ['value', 'label', 'errors', 'inputOptions'],
  data () {
    return {
      innerValue: this.value
    }
  },
  computed: {
    component () {
      if ((this.inputOptions || {}).textarea) { return 'v-textarea' }
      return 'v-text-field'
    },
    inputOptionsComputed () {
      const out = {label: this.label, ...this.inputOptions}
      delete out.textarea
      return out
    }
  },
  watch: {
    value (value) {
      this.innerValue = value
    }
  },
  methods: {
    handleInput (value) {
      this.innerValue = value
      this.$emit('update:errors', null)
      this.$emit('input', value)
    },
    handleChange (value) {
      this.$emit('change', value)
    }
  }
}
</script>
