<template>
  <v-container fluid fill-height class="fc-login-background">
    <v-layout>
      <v-row align="center" justify="center">
        <slot />
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {

}
</script>
