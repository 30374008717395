import Vue from 'vue'
import Vuetify, {VTextField, VTextarea, VForm, VDivider} from 'vuetify/lib'
import colors from '~/config/colors'
import en from 'vuetify/lib/locale/en'
import ru from 'vuetify/lib/locale/ru'

Vue.use(Vuetify, {
  components: {VTextField, VTextarea, VForm, VDivider}
})

export default new Vuetify({
  lang: {
    locales: {en, ru},
    current: 'en'
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: colors
    }
  }
})
