<script>
import {icon, marker} from 'leaflet'

export default {
  props: ['item', 'map', 'zoom', 'selected', 'editing', 'iconMode'],
  data () {
    return {
      boxCenter: [100, 100],
      boxScale: 100,
      mapObject: null,
      selectedArrowObject: null,
      iconWaterCheck: null,
      dotObject: null,
      overed: false,
      editingObjects: {}
    }
  },
  computed: {
    displaySettings () { return this.item.displaySettings || {} },
    type () { return this.item.type },
    icon () { return this.item.displaySettings.marker },
    center () {
      if (this.editing && this.editingCoords && this.editingCoords.center) {
        return this.editingCoords.center
      }
      return this.displaySettings.center
    },
    drawTrigger () {
      return JSON.stringify({center: this.center, type: this.type})
    }
  },
  watch: {
    drawTrigger: {
      immediate: true,
      handler () {
        if (this.mapObject) {
          this.mapObject.remove()
        }
        if (!this.map) { 
          return false 
        }
        this.mapObject = marker(this.center, {
          zIndexOffset: 2, 
          icon: icon({ 
            iconUrl: require(`~/assets/icons/${this.icon}`), 
            iconSize: 50, 
            iconAnchor: [25, 50] 
          })
        }).addTo(this.map)
        this.mapObject.addEventListener('click', (e) => {
          e.originalEvent.stopPropagation()
          this.$emit('click')
        })
      }
    }
  },
  render () {
    return null
  },
  beforeDestroy() {
    this.mapObject && this.mapObject.remove()
  }
}
</script>
