<template>
  <v-text-field
    :value="innerValue"
    :error="!!innerErrors.length"
    :error-messages="innerErrors"
    v-bind="inputOptionsComputed"
    @input="parseInput"
    @keydown="handleKeydown"
    @blur="setFocus(false)"
    @focus="setFocus(true)"
  />
</template>

<script>
export default {
  props: {
    value: {required: true},
    label: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default () {
        return []
      }
    },
    inputOptions: {
      type: Object,
      default () {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: [Number, Object],
      default: null
    },
    max: {
      type: [Number, Object],
      default: null
    },
    decimal: {
      type: [Number, Object],
      default: null
    },
    dropZeroDecimals: {
      type: Boolean,
      default: false
    },
    allowNegative: {
      type: Boolean,
      default: false
    },
    allowSpaces: {
      type: Boolean,
      default: false
    },
    autosave: {
      type: Boolean,
      default: false
    },
    thousandDelimiter: {
      type: [String, Object],
      default: null
    },
    fromCents: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const out = {
      innerValue: '',
      innerErrors: this.errors || [],
      focused: false
    }
    const result = this.parseNumber(this.value, 'inner')
    out.innerValue = result.valid ? result.formattedValue : result.originalValue
    if (!result.valid) {
      out.innerErrors.push('Invalid number')
    }
    return out
  },
  computed: {
    inputOptionsComputed () {
      const out = {
        ...this.inputOptions
      }
      const propsToOptions = ['readonly', 'disabled', 'loading', 'label']
      propsToOptions.forEach((prop) => {
        if (this[prop]) {
          out[prop] = this[prop]
        }
      })
      return out
    }
  },
  watch: {
    errors (errors) {
      this.innerErrors = errors || []
    },
    focused (value) {
      this.$emit(value ? 'focus' : 'blur')
    },
    innerErrors (errors) {
      this.$emit('update:errors', errors)
    },
    value (value) {
      const result = this.parseNumber(value, 'inner')
      if (result.valid) {
        this.innerErrors = []
        if (!this.focused) {
          this.innerValue = result.formattedValue
        }
      } else {
        this.innerErrors = ['Invalid number']
        if (!this.focused) {
          this.innerValue = result.originalValue
        }
      }
    },
    innerValue (value) {
      if (!this.autosave) {
        const result = this.parseNumber(value, 'value')
        if (result.empty) {
          this.emitChange(null)
        } else if (result.valid) {
          this.emitChange(result.value)
        }
      }
    }
  },
  methods: {
    getFormattedValue (value) {
      if (_.isNumber(this.decimal)) {
        value = parseFloat(value).toFixed(this.decimal).toString()
      } else {
        value = parseFloat(value).toString()
      }
      if (this.dropZeroDecimals) {
        value = value.replace(/\.0+$/, '')
      }
      if (this.thousandDelimiter) {
        const n = value.length - (/\./.test(value) ? 3 : 0) - 3
        if (n > 0) {
          value = value.substr(0, n) + this.thousandDelimiter + value.substr(n)
        }
      }
      return value
    },
    parseNumber (value, direction) {
      const out = {valid: false, empty: false, value: null, originalValue: value, formattedValue: null}
      if (typeof value === 'number') {
        value = value.toString()
      } else {
        value = (value || '').toString().replace(/[^\d-.,]/g, '').replace(/,/g, '.')
      }
      if (!value.length) {
        out.valid = true
        out.empty = true
        out.value = 0
        out.formattedValue = ''
      } else {
        out.value = parseFloat(value)
        out.valid = _.isNumber(out.value)
        if (typeof this.min === 'number' && out.value < this.min) out.value = this.min
        if (typeof this.max === 'number' && out.value > this.max) out.value = this.max
        if (!this.allowNegative && out.value < 0) out.value = 0
        if (this.fromCents && out.valid) {
          if (direction === 'inner') out.value = Math.round(out.value) / 100
          else out.value = Math.round(100 * out.value)
        }
        out.formattedValue = this.getFormattedValue(out.value)
      }
      return out
    },
    emitChange (value) {
      if (!this.value && !value) return false
      if (this.value !== value) {
        this.$emit('input', value)
        this.$emit('inputImmediate', value)
        this.$emit('change', value)
      }
    },
    setFocus (value) {
      this.focused = value
      if (!value) {
        this.setNumber()
      }
    },
    setNumber () {
      const result = this.parseNumber(this.innerValue, 'value')
      if (result.valid) {
        const innerResult = this.parseNumber(result.formattedValue, 'inner')
        if (innerResult.valid) {
          this.innerValue = innerResult.formattedValue
        }
        this.emitChange(result.value)
      } else {
        this.innerErrors = ['Invalid date']
      }
    },
    parseInput (value) {
      this.innerValue = value
      this.innerErrors = []
    },
    handleKeydown (e) {
      if (e.key === 'Enter' || e.key === 'Tab') {
        this.setNumber()
      } else if (e.key === 'Escape') {
        const result = this.parseNumber(this.value, 'inner')
        this.innerValue = result.valid ? result.formattedValue : result.originalValue
        if (!result.valid) {
          this.innerErrors = ['Invalid number']
        }
      } else if (e.key === '+' || e.key === 'ArrowUp') {
        const result = this.parseNumber(this.innerValue, 'value')
        if (result.valid && !result.empty) {
          e.preventDefault()
          if (_.isNumber(this.decimal) && Math.floor(result.value / (this.fromCents ? 100 : 1) * Math.pow(10, this.decimal)) !== Math.floor(result.value / (this.fromCents ? 100 : 1)) * Math.pow(10, this.decimal)) {
            result.value = Math.ceil(result.value / (this.fromCents ? 100 : 1)) * (this.fromCents ? 100 : 1)
          } else {
            result.value += this.fromCents ? 100 : 1
          }
          const nextResult = this.parseNumber(result.value, 'inner')
          this.innerValue = nextResult.formattedValue
        }
      } else if (e.key === '-' && !e.target.selectionStart && this.allowNegative && (this.innerValue.length === 0 || this.innerValue.substr(0, 1) !== '-')) {
        // Do nothing;
      } else if (e.key === '-' || e.key === 'ArrowDown') {
        const result = this.parseNumber(this.innerValue, 'value')
        if (result.valid && !result.empty) {
          e.preventDefault()
          if (_.isNumber(this.decimal) && Math.floor(result.value / (this.fromCents ? 100 : 1) * Math.pow(10, this.decimal)) !== Math.floor(result.value / (this.fromCents ? 100 : 1)) * Math.pow(10, this.decimal)) {
            result.value = Math.floor(result.value / (this.fromCents ? 100 : 1)) * (this.fromCents ? 100 : 1)
          } else {
            result.value -= this.fromCents ? 100 : 1
          }
          const nextResult = this.parseNumber(result.value, 'inner')
          this.innerValue = nextResult.formattedValue
        }
      } else if ((e.key === '.' || e.key === ',') && this.decimal && this.innerValue.indexOf('.') === -1) {
        // Do nothing
      } else if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Backspace' || e.key === 'Delete' || !isNaN(e.key) || e.ctrlKey) {
        // Do nothing
      } else {
        e.preventDefault()
      }
    }
  }
}
</script>
