<template>
  <v-tooltip left>
    <template v-if="status" v-slot:activator="{ on }">
        <v-icon v-on="on" :class="status.color">{{status.icon}}</v-icon>
    </template>
    <span>{{ status.tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  //   mixins: [dateTimeFormat],
  props: ["item"],
  computed: {
    status() {
        if (this.item.guards.on && this.item.guards.alarm) {
            return {
                'icon':'mdi-lock-alert',
                'tooltip': 'Обнаружено движение',
                'color': 'icon-guard-red-blink'
            }
        }
        else if (!this.item.guards.online) {
            return {
                'icon':'mdi-lock-open-remove',
                'tooltip': 'Защита не в сети',
                'color': false
            }
        }
        else if (this.item.guards.block) {
            return {
                'icon':'mdi-lock-off',
                'tooltip': 'Блокировка защиты',
                'color': false
            }
        }
        else if (this.item.guards.on) {
            return {
                'icon':'mdi-lock',
                'tooltip': 'Защита активирована',
                'color': 'icon-guard-green'
            }
        }
        else if (this.item.guards.online) {
            return {
                'icon':'mdi-lock-open-check',
                'tooltip': 'Защита в сети',
                'color': 'icon-guard-green'
            }
        }
        return false
    }
  },
};
</script>

<style scoped>
.v-icon.icon-guard-green {
    color: green!important;
}
.v-icon.icon-guard-red {
    color: red!important;
}
.v-icon.icon-guard-red-blink {
    color: red!important;
    font-size: 1.8em;
    animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>