<template>
  <div class="highcharts-wrapper" v-if="showChart">
    <v-btn icon @click="fullScreen" class="fullscreen-icon">
      <v-icon>mdi-overscan</v-icon>
    </v-btn>
    <highcharts v-if="showChart" :options="options" ref="highcharts"/>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => []
    }
  },
  computed: {
    showChart () {
      return this.data['Solar radiation'] && !_.isEmpty(this.data['Solar radiation'])
    },
    options () {
      if (!this.showChart) {
        return null
      }
      return {
        exporting: {
          enabled: false,
        },
        chart: {
          type: '',
          spacingLeft: 0,
          alignTicks: true,
          animation: false,
          height: 250,
        },
        credits: {
          enabled: false
        },
        title: {
          text: null
        },
        subtitle: {
          text: null
        },
        xAxis: {
          categories: this.data.dates,
          // tickInterval: 4,
          crosshair: {
            width: 1,
            color: 'red',
            zIndex: 10
          },
          labels: {
            rotation: -45,
          }
        },
        yAxis: {
          tickInterval: 50,
          min: 0,
          startOnTick: false,
          endOnTick: false,
          title: {
            text: this.$t('charts.solar_radiation'),
            margin: 15
          },
          labels: {
            enabled: true
          },
          gridLineWidth: 0.5,
        },
        plotOptions: {
          series: {
            animation: false,
            pointWidth: 0,
            marker: {
              symbol: 'circle',
              enabled: false
            },
          },
        },
        tooltip: {
          enabled: true,
          formatter: function () {
            return this.y
          }
        },
        legend: {
          enabled: false
        },
        series: [{
          yAxis: 0,
          zIndex: 2,
          events:{
            legendItemClick: function () {
              return false
            }
          },
          data: this.data['Solar radiation'],
          type: 'areaspline',
          lineColor: 'rgba(255, 255, 153, 0.75)',
          color: 'rgba(255, 255, 153, 0.75)',
          lineWidth: 1,
          marker: {
            symbol: 'circle',
            enabled: true
          }
        }]
      }
    }
  },
  methods: {
    fullScreen() {
      this.$refs.highcharts.chart.fullscreen.toggle()
    },
  }
}
</script>
