import moment from 'moment'
import {getTime, getMinute} from '~/utils/time'

export default {
  methods: {
    getTime,
    getMinute,
    formatDate (date, dateFormat) {
      if (!date) {
        return null
      }
      if (!dateFormat) {
        dateFormat = this.$store.getters['ux/serverSettings'].dateFormat
      }
      const timeOffset = this.$store.getters['ux/serverSettings'].timeOffset
      const m = moment.utc(date, 'YYYY-MM-DD').utcOffset(timeOffset)
      return m.isValid() ? m.format(dateFormat) : null
    },
    formattedDateIsValid (str) {
      const dateFormat = this.$store.getters['ux/serverSettings'].dateFormat
      return moment.utc(str, dateFormat).isValid()
    },
    unformatDate (str, dateFormat) {
      if (!dateFormat) {
        dateFormat = this.$store.getters['ux/serverSettings'].dateFormat
      }
      const timeOffset = this.$store.getters['ux/serverSettings'].timeOffset
      const m = moment.utc(str, dateFormat).utcOffset(timeOffset)
      return m.isValid() ? m.format('YYYY-MM-DD') : null
    },
    formatTime (time, timeFormat) {
      if (!timeFormat) {
        timeFormat = this.$store.getters['ux/serverSettings'].timeFormat
      }
      const timeOffset = this.$store.getters['ux/serverSettings'].timeOffset
      const m = moment.utc(time, 'H:m').utcOffset(timeOffset)
      return m.isValid() ? m.format(timeFormat) + timeOffset : null
    },
    formatTimeFromDateTime (datetime, timeFormat) {
      if (!timeFormat) {
        timeFormat = this.$store.getters['ux/serverSettings'].timeFormat
      }
      const timeOffset = this.$store.getters['ux/serverSettings'].timeOffset
      const m = moment.utc(datetime).utcOffset(timeOffset)
      return m.isValid() ? m.format(timeFormat) : null
    },
    formatTimeFromMinute (minute, timeFormat) {
      const time = ('0' + Math.floor(minute / 60)).slice(-2) + ':' + ('0' + (minute % 60)).slice(-2)
      return this.formatTime(time, timeFormat)
    },
    formattedTimeIsValid (str, timeFormat) {
      if (!timeFormat) {
        timeFormat = this.$store.getters['ux/serverSettings'].timeFormat
      }
      return moment(str, timeFormat).isValid()
    },
    unformatTime (str, timeFormat) {
      if (!timeFormat) {
        timeFormat = this.$store.getters['ux/serverSettings'].timeFormat
      }
      const timeOffset = this.$store.getters['ux/serverSettings'].timeOffset
      const m = moment.utc(str, timeFormat).utcOffset(timeOffset)
      return m.isValid() ? m.format('HH:mm') : null
    },
    formatDateTime (datetime, dateFormat, timeFormat) {
      if (!dateFormat) {
        dateFormat = this.$store.getters['ux/serverSettings'].dateFormat
      }
      if (!timeFormat) {
        timeFormat = this.$store.getters['ux/serverSettings'].timeFormat
      }
      const timeOffset = this.$store.getters['ux/serverSettings'].timeOffset
      const m = moment.utc(datetime).utcOffset(timeOffset)
      return m.isValid() ? m.format(dateFormat + ' ' + timeFormat) : null
    },
    formatDateTimeFromNow (datetime) {
      const timeOffset = this.$store.getters['ux/serverSettings'].timeOffset
      const m = moment.utc(datetime).utcOffset(timeOffset)
      return m.isValid() ? m.fromNow() : null
    }
  }
}
