<template>
  <div class="fc-map__page-body tab-settings">
    <div class="body-1 my-3">
      <v-alert v-if="!instance.length" type="info" outlined>
        {{ $t("success.noSystemSettings") }}
      </v-alert>
      <v-simple-table v-else>
        <thead>
          <tr>
            <th class="col-9">{{ $t("pivot.field") }}</th>
            <th>{{ $t("pivot.value") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="setting in instance" :key="setting.key">
            <td>{{ setting.key }}</td>
            <td>
              <div v-if="isFC">
                <field-control 
                v-if="setting.key !== 'Pressure P0 Val' && setting.key !== 'Pressure P1 Val' && setting.key !== 'Water Cons P0 Val' && setting.key !== 'Water Cons P1 Val'"
                v-model="setting.value" 
                :disabled="!editingInstance" 
                :field="fieldsRev[setting.key]"
                />

                <v-text-field
                v-if="setting.key === 'Pressure P0 Val' || setting.key === 'Pressure P1 Val' || setting.key === 'Water Cons P0 Val' || setting.key === 'Water Cons P1 Val'"
                v-model="setting.value" 
                :disabled="!editingInstance" 
                :field="fieldsRev[setting.key]"
                :loading="loading"
                hide-details
                single-line
                append-icon="mdi-swap-horizontal"
                @click:append="requestADC(setting.key)" 
                :key="setting.key"/>
              </div>
              <div v-else>{{ setting.value }}</div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-row v-if="isFC" class="mt-3">
        <v-col class="d-flex flex-wrap ustify-end">
          <template v-if="editingInstance">
            <v-btn class="mr-3" :loading="loading" text @click="cancel">{{
              $t("actions.cancel")
            }}</v-btn>
            <v-btn class="mr-3" :loading="loading" color="primary" @click="save">{{
              $t("actions.save")
            }}</v-btn>
          </template>
          <template v-else>
            <v-btn
              :loading="loading"
              class="mr-3"
              text
              @click="request('pivot')"
              >{{ $t("actions.requestPivot") }}</v-btn>
            <v-btn class="mr-3" text @click="start">{{ $t("actions.edit") }}</v-btn>
            <v-btn class="mr-3" :loading="loading" v-if="remoteFirmware" color="warning" @click="firmware">{{ $t("actions.firmware") }}</v-btn>
          </template>
        </v-col>
      </v-row>
      <v-row v-else class="mt-3">
        <v-btn :loading="loading" text class="ml-3" @click="request('pivot')">
          {{ $t("actions.requestPivot") }}
        </v-btn>
        <v-btn :loading="loading" text class="ml-3" @click="request('pump')">
          {{ $t("actions.requestPump") }}
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import configFields from '~/config/fields'
import generalFormMixin from "~/components/mixins/generalFormMixin";

export default {
  mixins: [generalFormMixin],
  props: ["id", "tab"],
  data() {
    return {
      instance: [],
      editingInstance: false,
    };
  },
  computed: {
    isAdmin () { return this.$store.getters['auth/isAdmin'] },
    items() {
      return this.$store.getters["rtu/items"];
    },
    item() {
      return this.items.find((item) => item.id === this.id);
    },
    isFC() {
      if (
        this.item &&
        this.item.deviceSettings &&
        this.item.deviceSettings.settings &&
        this.item.manufacturer === "FC" && 
        Object.keys(this.item.deviceSettings.settings).includes("Use Uart Connect")
      ) {
        return true;
      } else {
        return false;
      }
    },
    remoteFirmware () {
      return (this.item.manufacturer === 'FC' && this.item.rfw === true) ?? false;
    },
    fields () { return configFields.primarySettings },
    fieldsRev () {
      return this.fields.reduce((out, field) => {
        out[field.key] = field
        return out
      }, {})
    },
    updateInstanceTrigger () {
      return JSON.stringify({id: this.id, item: this.item})
    },
    settings() {
      const out = [];
      if (
        this.item &&
        this.item.deviceSettings &&
        this.item.deviceSettings.settings
      ) {
        Object.keys(this.item.deviceSettings.settings).forEach((key) => {
          out.push({ key, value: this.item.deviceSettings.settings[key] });
        });

        if (this.item.deviceSettings.motohours) {
          Object.keys(this.item.deviceSettings.motohours).forEach((key) => {
            out.push({ key, value: this.item.deviceSettings.motohours[key] });
          });
        }
      }
      return out;
    },
  },
  watch: {
    updateInstanceTrigger: {
      immediate: true,
      handler () {
        if (this.editingInstance) { return false }
        this.instance = this.preparePrimarySettingsInstance(this.item)
      }
    },
  },
  methods: {
    preparePrimarySettingsInstance(item) {
      const out = [];
      if (
        item &&
        item.deviceSettings &&
        item.deviceSettings.settings
      ) {
        Object.keys(item.deviceSettings.settings).forEach((key) => {
          if ((key == 'Machine Id' || key == 'Server Address' || key == 'Server Port' || key == 'Gnss UID' || key == 'Use Uart Connect') && this.isAdmin || (key != 'Machine Id' && key != 'Server Address' && key != 'Server Port' && key != 'Gnss UID' && key != 'Use Uart Connect')) {
            out.push({ key, value: item.deviceSettings.settings[key] });
          }
        });

        // if (item.deviceSettings.motohours) {
        //   Object.keys(item.deviceSettings.motohours).forEach((key) => {
        //     out.push({ key, value: item.deviceSettings.motohours[key] });
        //   });
        // }
        return out
      } else {
        return []
      }

    },
    start() {
      this.editingInstance = true;
    },
    save () {
      this.setLoading(true)
      const data = this.instance
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {
          path: 'rtu.updatePivotSettings', 
          data: {deviceId: this.id, ...data}
        }))
        .then((response) => {
          if (response.data && response.data.error) {
            throw new Error(response.data.error)
          }
        })
        .then(() => new Promise((resolve) => {
          this._timeout && clearTimeout(this._timeout)
          this._timeout = setTimeout(resolve, 500)
        }))
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.id', data: {id: this.item.id}}, {root: true}))
        .then((response) => {
          return this.$store.dispatch('rtu/updateItemId', response.data)
        })
        // .then(() => {
        //   this.$swal({icon: 'success', text: this.$t('success.settingsUpdated')})
        //   this.cancel()
        // })
        .then(() => {
          this.$snack({type: 'success', message: this.$t('success.settingsUpdated')})
          this.cancel()
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    firmware() {
      this.$swal({
          icon: 'warning',
          text: this.$t('success.confirmFirmware'),
          showCancelButton: true,
          confirmButtonText: this.$t('actions.firmware'),
          cancelButtonText: this.$t('actions.cancel'),
          focusConfirm: false
        }).then((result) => {
          if(result.value) {
            this.setLoading(true)     
            Promise.resolve()
              .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.firmwareInit', data: {id: this.id}}, {root: true}))
              .then(() => new Promise((resolve) => {
                this._timeout && clearTimeout(this._timeout)
                this._timeout = setTimeout(resolve, 2000)
              }))
              .then(() => this.$store.dispatch('rtu/fetch'))
              .then(() => {
                this.$swal({icon: 'success', text: this.$t('success.firmwareStart')})
              })
              .catch(this.handleError)
              .finally(() => {
                this.setLoading(false)
              })
          }
        });
    },
    cancel() {
      this.instance = this.preparePrimarySettingsInstance(this.item)
      this.editingInstance = false;
    },
    requestADC(target) {
      this.setLoading(true);
      this.editingInstance = false;
      if (target === 'Pressure P0 Val' || target === 'Pressure P1 Val') {
        if (target === 'Pressure P0 Val') {
          target = 'pp0adc'
        }
        if (target === 'Pressure P1 Val') {
          target = 'pp1adc'
        }
      }
      if (target === 'Water Cons P0 Val' || target === 'Water Cons P1 Val') {
        if (target === 'Water Cons P0 Val') {
          target = 'pp0wtrc'
        }
        if (target === 'Water Cons P1 Val') {
          target = 'pp1wtrc'
        }
      }

      return Promise.resolve()
        .then(() =>
          this.$store.dispatch("fetch/fetch", {
            path: `rtu.${target}Request`,
            data: { id: this.id },
          })
        )
        .then(() => new Promise((resolve) => {
          this._timeout && clearTimeout(this._timeout)
          this._timeout = setTimeout(resolve, 4000)
        }))
        .then(() => this.$store.dispatch('rtu/fetch', {force: true}))
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false);
          this.editingInstance = true;
        });
    },
    request(target) {
      this.setLoading(true);      
      return Promise.resolve()
        .then(() =>
          this.$store.dispatch("fetch/fetch", {
            path: `rtu.${target}Request`,
            data: { id: this.id },
          })
        )
        .then(() => this.$store.dispatch("rtu/fetch", { force: true }))
        .then(() => {
          this.$swal({
            icon: "success",
            text: this.$t(`success.${target}Requested`),
          });
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false);
        });
    },
  },
};
</script>
<style>
 .tab-settings .v-text-field, .tab-settings .v-input--selection-controls {
   margin-top: 0;
   padding-top: 0;
 }
</style>

