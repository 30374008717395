<template>
  <div v-if="showActual" class="fc-map__page-body" style="overflow: unset">
    <div class="body-1">
      <v-card-text>
        <div class="title mb-2">Сводка</div>
        <v-row>
          <v-col>
            <div>
              Макс. температура: {{ t_max }}°C
            </div>
            <div>
              Мин. температура: {{ t_min }}°C
            </div>
            <div>
              Сред. температура: {{ t_mean }}°C
            </div>
          </v-col>
          <v-col>
            <div>
              Макс. влажность: {{ air_rh_max }}%
            </div>
            <div>
              Мин. влажность: {{ air_rh_min }}%
            </div>
            <div>
              Сред. влажность: {{ air_rh_mean }}%
            </div>
          </v-col>
          <v-col>
            <div>
              Итого осадков: {{ precipitation }} {{ $t('charts.units.mm') }}
            </div>
            <div>
              Ср. скорость ветра: {{ wind_speed_mean }} {{ $t('charts.units.ms') }}
            </div>
            <div>
              Порывы ветра: {{ wind_speed_max }} {{ $t('charts.units.ms') }}
            </div>
          </v-col>
        </v-row>

      </v-card-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  computed: {
    showActual() {
      return this.data && !_.isEmpty(this.data);
    },
    tMax() {
      return this.data.Temperature.max
    },
    tMin() {
      return this.data.Temperature.min
    },
    AirRhMax() {
      return this.data['Air Rh'].max
    },
    AirRhMin() {
      return this.data['Air Rh'].min
    },
    WindSpeed() {
      return this.data['Wind Speed']
    },
    Precipitation() {
      return this.data['Precipitation']
    },
    t_min() {
      return Math.min(...this.tMin)
    },
    t_max() {
      return Math.max(...this.tMax)
    },
    t_mean() {
      return ((this.t_min + this.t_max) / 2).toFixed(1)
    },
    air_rh_min() {
      return Math.min(...this.AirRhMin)
    },
    air_rh_max() {
      return Math.min(...this.AirRhMax)
    },
    air_rh_mean() {
      return ((this.air_rh_min + this.air_rh_max) / 2).toFixed(1)
    },
    precipitation() {
      let sum = 0
      sum = this.Precipitation.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }, 0)
      return sum.toFixed(1)
    },
    wind_speed_mean() {
      let sum = 0
      sum = this.WindSpeed.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }, 0)
      return sum > 0 ? (sum / this.WindSpeed.length).toFixed(1) : 0
    },
    wind_speed_max() {
      return Math.max(...this.WindSpeed)
    }
  },
};
</script>