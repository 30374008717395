<template>
  <v-autocomplete
    :value="innerValue"
    :items="items"
    :error-messages="validationErrors"
    v-bind="selectOptionsComputed"
    @change="handleChange"
  />
</template>

<script>
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  mixins: [generalFormMixin],
  props: ['value', 'label', 'multiple', 'errors', 'selectOptions', 'dataSource'],
  data () {
    return {
      innerValue: this.value,
      validationErrors: this.errors || [],
      items: [],
      loading: false
    }
  },
  computed: {
    selectOptionsComputed () {
      return {
        label: this.label,
        multiple: this.multiple,
        itemText: 'name',
        itemValue: 'id',
        returnObject: true,
        openOnItemsChanged: false,
        loading: this.loading,
        ...this.selectOptions
      }
    }
  },
  watch: {
    value (value) {
      this.innerValue = value
    },
    errors (errors) {
      this.validationErrors = errors || []
    },
    dataSource: {
      immediate: true,
      handler: 'processDataSource'
    }
  },
  methods: {
    handleChange (value) {
      this.$emit('update:errors', null)
      this.$emit('input', value)
      this.$emit('change', value)
    },
    processDataSource () {
      if (Array.isArray(this.dataSource)) {
        this.items = [...this.dataSource]
      } else {
        this.setLoading(true)
        this.$store
          .dispatch('fetch/fetch', this.dataSource)
          .then((response) => {
            this.items = response.data
          }, this.handleError)
          .finally(() => this.setLoading(false))
      }
    }
  }
}
</script>
