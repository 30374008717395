const prepareItem = (item) => {
  item = {
    ...item,
    deviceStatus: {
      ...(item.deviceStatus || {})
    }
  }
  item.fullName = `${item.name} (${item.uuid})`
  item.deviceStatus.real_move = item.deviceStatus.move
  if (item.deviceStatus.move === 'finish') {
    item.deviceStatus.move = 'stop'
  }
  return item
}

export default {
  baseURLS: [
    process.env.VUE_APP_BASE_URL
  ],
  rtuRequestInterval: 10000,
  rtu: {
    list: {
      get: '/rtu/all',
      prepareItem,
      cache: 0
    },
    id: {
      get: '/rtu/:id',
    },
    cmd: {
      post: '/rtu/:id/cmd'
    },
    updateCoords: {
      put: '/rtu/:id'
    },
    updateComment: {
      put: '/rtu/:id'
    },
    updateEndGuns: {
      post: '/rtu/:deviceId/guns'
    },
    updateHoseStops: {
      post: '/rtu/:deviceId/hose-stops'
    },
    updateAreaPlans: {
      post: '/rtu/:deviceId/area-plans'
    },
    updatePivotSettings: {
      post: '/rtu/:deviceId/primary-settings'
    },
    updateWatermark: {
      post: '/rtu/:deviceId/watermark'
    },
    updateSoftBarrierSettings: {
      post: '/rtu/:deviceId/barrier'
    },
    request: {
      main: {
        get: '/rtu/:id/info'
      },
      guns: {
        post: '/rtu/:id/guns/info'
      },
      hoseStop: {
        post: '/rtu/:id/hose-stops/info'
      },
      areaPlan: {
        post: '/rtu/:id/area-plans/info'
      },
      watermark: {
        post: '/rtu/:id/watermark/info'
      }
    },
    powerRelay: {
      start: {
        post: '/rtu/:id/relay/power-start'
      },
      stop: {
        post: '/rtu/:id/relay/power-stop'
      }
    },
    updateCamera: {
      post: '/rtu/:id/camera'
    },
    updateAdminSettings: {
      put: '/rtu/:id'
    },
    updateSnapshot: {
      post: '/rtu/:id/snapshot/refresh'
    },
    pivotRequest: {
      get: '/rtu/:id/pivot-settings'
    },
    pumpRequest: {
      get: '/rtu/:id/pump-settings'
    },
    barrierRequest: {
      get: '/rtu/:id/barrier'
    },
    pp0adcRequest: {
      get: '/rtu/:id/adc/pp0'
    },
    pp1adcRequest: {
      get: '/rtu/:id/adc/pp1'
    },
    pp0wtrcRequest: {
      get: '/rtu/:id/wtrc/pp0'
    },
    pp1wtrcRequest: {
      get: '/rtu/:id/wtrc/pp1'
    },
    reportRequest: {
      get: '/rtu/:id/report'
    },
    logRequest: {
      post: '/rtu/:id/log'
    },
    getLogRequest: {
      get: '/rtu/:id/log'
    },
    firmwareInit: {
      post: '/rtu/:id/firmware-initial'
    }
  },
  auth: {
    me: {
      post: '/auth/me'
    },
    login: {
      post: '/auth/login'
    },
    restorePassword: {
      post: '/auth/reset'
    },
    settings: {
      post: '/auth/personalize'
    }
  },
  admin: {
    users: {
      get: '/user'
    },
    user: {
      create: {
        post: '/user'
      },
      update: {
        put: '/user/:id'
      },
      delete: {
        delete: '/user/:id'
      }
    },
    organization: {
      create: {
        post: '/org'
      },
      update: {
        put: '/org/:id'
      },
      delete: {
        delete: '/org/:id'
      }
    },
    pivot: {
      create: {
        post: '/pivot'
      },
      update: {
        put: '/pivot/:id'
      },
    },
    manufacturers: {
      get: '/man'
    },
    organizations: {
      get: '/org'
    },
    pivots: {
      get: '/pivot'
    }
  },
  report: {
    post: '/report-old'
  },
  newReport: {
    post: '/report'
  },
  waterchargeReport: {
    post: '/report2'
  },
  motoHoursReport: {
    post: '/report/mh'
  },
  exportToXls: {
    post: '/report/export'
  },
  station: {
    list: {
      get: '/stations',
    },
    request: {
      post: '/station/:id'
    },
    update: {
      put: '/station/:id'
    }
  },
  meteo: {
    list: {
      get: '/meteo',
    },
    request: {
      post: '/meteo/:id'
    },
    update: {
      put: '/meteo/:id'
    }
  },
  fields: {
    get: '/fields'
  },
  pumps: {
    get: '/pumps',
    prepareItem,
    cache: 0
  },
  notifications: {
    list: {
      get: '/notifications',
    },
    create: {
      post: '/notifications'
    },
    update: {
      put: '/notifications/:id'
    },
    delete: {
      delete: '/notifications/:id'
    }
  }
}
