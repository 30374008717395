<template>
    <div class="fc-map__page-body">
        <table class="fc-map__table table_meteo_summary_data mt-3">
            <thead>
                <tr class="fc-map__table-row">
                    <th v-for="column in columns" :key="column.key">
                        <div v-if="column.key !== 'summary'" class="fc-map-table__header-link">
                            {{ $t('meteosColumns.' + column.key) }}
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in sortedItems" :key="item.id" class="fc-map__table-row">
                    <td v-for="column in columns" :key="column.key">
                        <template v-if="column.key === 'name'">
                            <router-link :to="{ name: item.displaySettings.type, query: { id: item.id } }">
                                {{ item.name }}
                            </router-link>
                        </template>
                        <template v-else-if="column.key === 'type'">
                            {{ $t(item.type) }}
                        </template>
                        <template v-else-if="column.key === 'moisture'">
                            <AverageBar :average="item.average.value" :averageColor="item.average.color" />
                        </template>
                        <template v-else-if="column.key === 'summary'">
                            <summary-chart :data="item.summary" />
                        </template>
                        <template v-else-if="column.key === 'connection'">
                            <item-online :item="item" />
                        </template>
                    </td>

                </tr>
            </tbody>
        </table>

        <!-- <scrollable-table
          :head-size="40"
          class="fc-map-table"
        >
          <template #head>
            <div class="fc-map-table__row fc-map-table__row--header">
              <div
                v-for="column in columns"
                :key="column.key"
                :style="{
                  flex: column.flex
                }"
                :class="'fc-map-table__cell--' + column.key"
                class="fc-map-table__cell fc-map-table__cell--header"
              >
                <div
                  v-if="column.key !== 'summary'"
                  class="fc-map-table__header-link"
                >
                  {{ $t('meteosColumns.' + column.key) }}
                </div>
              </div>
            </div>
          </template>
          <template #body>
            <div
              v-for="item in sortedItems"
              :key="item.id"
              class="fc-map-table__row"
            >
              <div
                v-for="column in columns"
                :key="column.key"
                :style="{
                  flex: column.flex
                }"
                :class="'fc-map-table__cell--' + column.key"
                class="fc-map-table__cell"
              >
                <template v-if="column.key === 'name'">
                  <router-link :to="{name: item.displaySettings.type, query: {id: item.id}}">
                    {{ item.name }}
                  </router-link>
                </template>
                <template v-else-if="column.key === 'type'">
                  {{ $t(item.type) }}
                </template>
                <template v-else-if="column.key === 'moisture'">
                  <AverageBar
                    :average="item.average.value"
                    :averageColor="item.average.color"
                  />
                </template>
                <template v-else-if="column.key === 'summary'">
                  <summary-chart
                    :data="item.summary"
                  />
                </template>
                <template v-else-if="column.key === 'connection'">
                  <item-online :item="item" />
                </template>
              </div>
            </div>
          </template>
  
        </scrollable-table> -->
    </div>
</template>
  
<script>

import dateTimeFormat from '~/components/mixins/dateTimeFormat'
import generalFormMixin from '~/components/mixins/generalFormMixin'
// import ScrollableTable from "@/components/utils/ScrollableTable";
import ItemOnline from "@/components/map/ItemOnline";
import AverageBar from '~/components/chart/AverageBar'
import SummaryChart from '~/components/chart/SummaryChart'

export default {
    components: {
        ItemOnline,
        // ScrollableTable,
        AverageBar,
        SummaryChart
    },
    mixins: [dateTimeFormat, generalFormMixin],
    data() {
        return {
            columns: [
                { key: 'name', flex: '0 0 250px' },
                { key: 'type', flex: '0 0 120px' },
                { key: 'moisture', flex: '0 0 210px' },
                { key: 'summary', flex: '0 0 10px' },
                { key: 'connection', flex: '0 0 70px' },
            ],
            instance: {}
        }
    },
    computed: {
        deviceStatus() { return this.item.deviceStatus || {} },
        items() { return this.$store.getters['station/items'] },
        order() { return this.$route.query.order || 'equipment' },
        sortedItems() {
            return [...this.items]
        }
    },
    watch: {
        instanceTrigger: {
            immediate: true,
            handler() {
                const instance = {}
                const loading = {}


                this.instance = instance
                this.loading = loading
            }
        }
    },
    beforeDestroy() {
        this._timeout && clearTimeout(this._timeout)
    },
    methods: {

    }
}
</script>
  