<script>
import getScrollbarSize from '~/utils/get_scrollbar_size'

export default {
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width + '-' + this.$vuetify.breakpoint.height
    }
  },
  watch: {
    screenSize: 'updateScrollbarSize'
  },
  beforeMount() {
    this.updateScrollbarSize()
  },
  methods: {
    updateScrollbarSize() {
      this.$store.dispatch('ux/setScrollbarSize', getScrollbarSize())
    }
  },
  render: () => null
}
</script>
