/*
  About the autosave prop:
  click outside works in the nextTick (timeout 0).
  If autosave=true, we should update the value after blur;
  Otherwise there's a save button somewhere. Suppose we opened date/time select, typed something. The value was not yet updated after each change (because it should work this way for autosave=true).
  Then without loosing a focus we clicked on save button. Then the save action will trigger before click outside, so the final date/time select value is not updated.
  That's why for the case autosave=false we update value after each keypress/change;
  That's the only goal of autosave: update value after every inner change or only after loosing a focus.
*/

export default {
  props: ['value', 'inputOptions', 'pickerOptions', 'menuOptions', 'readonly', 'loading', 'disabled', 'errors', 'label', 'autosave'],
  computed: {
    isMobile () { return this.$store.getters['ux/layout'] === 'mobile' },
    inputOptionsComputed () {
      const out = {
        ...this.inputOptions
      }
      const propsToOptions = ['readonly', 'disabled', 'loading', 'label']
      propsToOptions.forEach((prop) => {
        if (this[prop]) {
          out[prop] = this[prop]
        }
      })
      if (this.isMobile && this.readonlyOnMobile) {
        out.readonly = true
      }
      return out
    },
    focused () {
      return this.inputFocused || this.pickerVisible
    }
  },
  watch: {
    errors (errors) {
      this.innerErrors = errors || []
    },
    focused (value) {
      this.$emit(value ? 'focus' : 'blur')
    },
    innerErrors (value) {
      this.$emit('update:errors', value)
    }
  },
  methods: {
    determinePickerPosition () {
      const $target = $(this.$refs.container)
      const to = $target.offset()
      const tw = $target.width()
      const th = $target.height()
      const ww = $(window).width()
      const wh = $(window).height()
      const out = {}
      if (to.top + th + this.pickerNudge + this.pickerHeight > wh) {
        out.top = true
        out.bottom = false
        out.positionY = to.top - this.pickerNudge
      } else {
        out.top = false
        out.bottom = true
        out.positionY = to.top + th + this.pickerNudge
      }
      if (to.left + this.pickerWidth > ww) {
        out.left = true
        out.right = false
        out.positionX = to.left + tw
      } else {
        out.left = false
        out.right = true
        out.positionX = to.left
      }
      return out
    },
    determinePickerWidth () {
      const $target = $(this.$refs.container)
      const tw = $target.width()
      return tw
    },
    setPickerPosition () {
      this.pickerOptionsPosition = this.determinePickerPosition()
    },
    setFocus (value) {
      if (value) {
        this.showPicker()
      }
      this.inputFocused = value
    },
    showPicker (e) {
      this.setPickerPosition()
      this.pickerVisible = true
    },
    hidePicker (e) {
      this.pickerVisible = false
    },
    emitChange (value) {
      if (!this.value && !value) return false
      if (this.value !== value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  },
  mounted () {
    this._resize = () => {
      if (this.pickerVisible) {
        this.setPickerPosition()
      }
    }
    window.addEventListener('resize', this._resize)
  },
  beforeDestroy () {
    this._resize && window.removeEventListener('resize', this._resize)
  }
}
