<script>
import VuetifyClickOutside from 'vuetify/lib/directives/click-outside'

export default {
  directives: {
    'click-outside': VuetifyClickOutside
  },
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    checkAgainst: {
      type: null,
      default: () => []
    }
  },
  computed: {
    directives() {
      return this.value ? [{
        name: 'click-outside',
        value: {
          handler: this.blurMethod,
          closeConditional: this.closeConditionalMethod
        }
      }] : undefined
    }
  },
  methods: {
    closeConditionalMethod(e) {
      if (!this.$el || this.$el.contains(e.target)) { return false }
      if (e.target === this.$el) { return false }
      const checkAgainst = Array.isArray(this.checkAgainst) ? this.checkAgainst : [this.checkAgainst]
      for (let i = 0; i < checkAgainst.length; i++) {
        let checkAgainstEntry = checkAgainst[i]
        if (checkAgainstEntry && typeof checkAgainstEntry === 'string') {
          checkAgainstEntry = document.querySelector(checkAgainstEntry)
        }
        if (
          checkAgainstEntry &&
          (checkAgainstEntry instanceof Element || checkAgainstEntry instanceof HTMLDocument) &&
          checkAgainstEntry.contains(e.target)
        ) { return false }
      }
      return true
    },
    blurMethod() {
      this.$emit('click-outside', false)
    }
  },
  render(createElement) {
    return createElement('div', {
      directives: this.directives,
      attrs: this.$attrs,
      props: this.$props,
      on: this.$listeners
    }, this.$slots.default)
  }
}
</script>
