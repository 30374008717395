<script>
import {icon, marker} from 'leaflet'

export default {
  props: ['item', 'map', 'zoom', 'selected', 'editing', 'iconMode'],
  data () {
    return {
      boxCenter: [100, 100],
      boxScale: 100,
      mapObject: null,
      selectedArrowObject: null,
      iconWaterCheck: null,
      dotObject: null,
      overed: false,
      editingObjects: {}
    }
  },
  computed: {
    center () {
      return this.item.coords
    },
    drawTrigger () {
      return JSON.stringify({map: !!this.map, center: this.center})
    },
    selectedTrigger () {
      return JSON.stringify({selected: this.selected, editing: this.editing, position: this.center, start: this.lateralStart, end: this.lateralEnd })
    },
  },
  watch: {
    drawTrigger: {
      immediate: true,
      handler () {
        if (this.mapObject) {
          this.mapObject.remove()
        }
        if (!this.map) { 
          return false 
        }
        this.mapObject = marker(this.center, {
          zIndexOffset: 2, 
          icon: icon({ 
            iconUrl: require(`~/assets/icons/icon-pump-pipe.svg`), 
            iconSize: 50, 
            iconAnchor: [25, 50] 
          })
        }).addTo(this.map)
        this.mapObject.addEventListener('click', (e) => {
          e.originalEvent.stopPropagation()
          this.$emit('click')
        })
      }
    }
  },
  render () {
    return null
  },
  beforeDestroy() {
    this.mapObject && this.mapObject.remove()
  }
}
</script>
