import { registerHeightObserver, unregisterHeightObserver } from 'element-height-observer'

export default {
  bind(el, binding) {
    const trigger = () => {
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      window.requestAnimationFrame(() => {
        if (binding.value && typeof binding.value === 'function') {
          binding.value({
            width: el.offsetWidth,
            height: el.offsetHeight
          })
        }
      })
    }
    if (window.ResizeObserver) {
      el._resizeObserver = new window.ResizeObserver(trigger)
      el._resizeObserver.observe(el)
    } else {
      registerHeightObserver(el, {direction: 'both'}, trigger)
    }
    trigger()
  },
  unbind(el) {
    if (window.ResizeObserver) {
      el._resizeObserver && el._resizeObserver.disconnect()
    } else {
      unregisterHeightObserver(el)
    }
  }
}
