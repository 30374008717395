<template>
  <click-outside 
    :value="true" 
    :check-against="this.$refs.menu ? [this.$refs.menu.$refs.content] : []" 
    @click-outside="pickerClickOutside"
  >
    <div ref="container">
      <v-text-field
        ref="input"
        :value="innerValue"
        :error-messages="innerErrors"
        v-bind="inputOptionsComputed"
        @blur="setFocus(false)"
        @focus="setFocus(true)"
        @click="showPicker"
        @input="parseInput"
        @keydown="handleKeydown"
      />
      <v-menu
        ref="menu"
        v-model="pickerVisible"
        v-bind="menuOptionsComputed"
        :close-on-click="false"
        :close-on-content-click="false"
        :max-width="pickerWidth + 'px'"
        absolute
      >
        <v-date-picker
          :value="innerDate"
          v-bind="pickerOptions"
          no-title
          scrollable
          @input="setDateFromPicker"
        >
          <v-btn block text @click="clearDate(false)">
            Clear
          </v-btn>
          <v-btn block text color="primary" @click="setDate">
            Ok
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
  </click-outside>
</template>

<script>
import moment from 'moment'
import dateTimeFormat from '~/components/mixins/dateTimeFormat'
import dateTimeMixin from '~/components/mixins/dateTimeMixin'

export default {
  mixins: [dateTimeFormat, dateTimeMixin],
  data () {
    const out = {
      innerValue: '',
      innerErrors: this.errors || [],
      pickerVisible: false,
      pickerOptionsPosition: {},
      pickerWidth: 330,
      pickerHeight: 400,
      pickerNudge: 0,
      inputFocused: false,
      readonlyOnMobile: true
    }
    const date = this.parseDate(this.value)
    if (date.valid) {
      out.innerValue = date.formattedDate
    } else {
      out.innerValue = this.value
      out.innerErrors.push('Invalid date')
    }
    return out
  },
  computed: {
    menuOptionsComputed () {
      return {
        ...this.menuOptions,
        ...this.pickerOptionsPosition
      }
    },
    innerDate () {
      const date = this.parseDate(this.innerValue)
      if (date.valid && !date.empty) return date.date
      return null
    }
  },
  watch: {
    value (value) {
      const date = this.parseDate(value)
      if (date.valid) {
        this.innerErrors = []
        if (!this.focused) {
          this.innerValue = date.formattedDate
        }
      } else {
        this.innerErrors = ['Invalid date']
        if (!this.focused) {
          this.innerValue = value
        }
      }
    },
    innerValue (value) {
      if (!this.autosave) {
        const date = this.parseDate(this.innerValue)
        if (date.empty) {
          this.emitChange(null)
        } else if (date.valid) {
          this.emitChange(date.date)
        }
      }
    }
  },
  methods: {
    parseDate (value) {
      value = (value || '').toLowerCase().trim()
      const date = {valid: false, empty: false, date: null}
      const digits = (value || '').split(/[^\d]+/).filter(part => !!part).map(part => parseInt(part))
      // **** (four digit) counts as year, 7 2017 -> for 2018-07-15 that is 2018-08-07;
      // 7 -> closest 7-th day of month in future, for 2018-07-15 that is 2018-08-07, for 2018-07-01 that is 2018-07-07;
      // 5july, july5, jul5, julietta5, asdjul5 -> 2018-07-05
      const digitsWY = [...digits]
      const yearIndex = digits.findIndex(digit => digit.toString().length === 4)
      if (yearIndex !== -1) {
        date.year = digits[yearIndex]
        date.yearFixed = true
        date.yearFirst = yearIndex === 0
        digitsWY.splice(yearIndex, 1)
      } else if (digits.length === 3 && digits[2].toString().length === 2) {
        date.year = 2000 + digits[2]
        date.yearFixed = true
        digitsWY.splice(2, 1)
      }
      if (!value.length) {
        date.valid = true
        date.empty = true
      } else if (digitsWY.length === 1 && digitsWY[0] > 0 && date.yearFirst && /\d+-\d+/.test(value)) {
        // The exeption here: typing 2017-05 and then `-` character should allow type date in 2017-05-20 format, - should not work as decreasing date, the date should not be valid;
        date.valid = false
      } else if (digitsWY.length === 1 && digitsWY[0] > 0) {
        if (!date.year) date.year = moment().format('YYYY')
        const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
        for (let i = 0; i < months.length; i++) {
          const r = new RegExp(months[i])
          if (r.test(value)) {
            date.month = i + 1
            break
          }
        }
        if (date.month) {
          // if (date.month < moment().format('M') && !date.yearFixed) date.year++
          if (digitsWY[0] <= moment(date.year + '-' + date.month, 'YYYY-M').daysInMonth()) {
            date.day = digitsWY[0]
            date.valid = true
          }
        } else if (digitsWY[0] < 32) {
          const m = moment()
          if (m.format('D') > digitsWY[0]) {
            m.add(1, 'months')
          }
          if (m.daysInMonth < digitsWY[0]) {
            m.add(1, 'months')
          }
          date.month = m.format('M')
          date.day = digitsWY[0]
          date.valid = true
        }
      } else if (digitsWY.length === 2) {
        if (!date.year) date.year = moment().format('YYYY')
        if (date.yearFirst) {
          date.month = digitsWY[0]
          date.day = digitsWY[1]
        } else {
          date.month = digitsWY[1]
          date.day = digitsWY[0]
        }
        if (date.month > 0 && date.month < 13 && date.day > 0 && date.day <= moment(date.year + '-' + date.month, 'YYYY-M').daysInMonth()) {
          date.valid = true
        }
      } else if ('today'.indexOf(value) === 0) {
        const m = moment()
        date.year = m.format('YYYY')
        date.month = m.format('M')
        date.day = m.format('D')
        date.valid = true
      } else if ('tomorrow'.indexOf(value) === 0) {
        const m = moment().add(1, 'days')
        date.year = m.format('YYYY')
        date.month = m.format('M')
        date.day = m.format('D')
        date.valid = true
      } else {
        const wd = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
        for (let i = 0; i < wd.length; i++) {
          const r = new RegExp(wd[i])
          if (r.test(value)) {
            const m = moment()
            if (m.format('E') - 1 < i) {
              m.isoWeekday(i + 1)
            } else {
              m.add(1, 'weeks').isoWeekday(i + 1)
            }
            date.year = m.format('YYYY')
            date.month = m.format('M')
            date.day = m.format('D')
            date.valid = true
          }
        }
      }
      if (date.valid) {
        if (date.empty) {
          date.date = null
          date.formattedDate = ''
        } else {
          date.m = moment(date.year + '-' + date.month + '-' + date.day, 'YYYY-M-D')
          date.date = date.m.format('YYYY-MM-DD')
          date.formattedDate = this.formatDate(date.date)
        }
      }
      return date
    },
    setPickerPosition () {
      this.pickerOptionsPosition = this.determinePickerPosition()
    },
    setDate () {
      const date = this.parseDate(this.innerValue)
      if (date.valid) {
        this.innerValue = date.formattedDate
        this.emitChange(date.date)
      } else {
        this.innerErrors.push('Invalid date')
      }
      this.pickerVisible = false
    },
    setDateFromPicker (date) {
      this.innerValue = date
      this.setDate()
      // Hide picker on date change;
      this.hidePicker()
    },
    clearDate () {
      this.innerValue = ''
      this.emitChange(null)
      this.hidePicker()
    },
    parseInput (value) {
      this.innerValue = value
      this.innerErrors = []
      this.pickerVisible = true
    },
    handleKeydown (e) {
      if (e.key === 'Enter') {
        if (this.pickerVisible) {
          this.setDate()
        } else {
          this.showPicker()
        }
      } else if (e.key === 'Tab') {
        this.setDate()
      } else if (e.key === 'Escape') {
        const date = this.parseDate(this.value)
        if (date.valid) {
          this.innerValue = date.formattedDate
        } else {
          this.innerValue = this.value
          this.innerErrors = ['Invalid date']
        }
        this.hidePicker()
      } else if (e.key === '+' || e.key === '-') {
        const date = this.parseDate(this.innerValue)
        if (date.valid && !date.empty) {
          e.preventDefault()
          const m = date.m.clone()
          this.innerValue = this.formatDate(m.add(e.key === '+' ? 1 : -1, 'days').format('YYYY-MM-DD'))
        }
      }
    },
    pickerClickOutside () {
      this.setDate()
      this.hidePicker()
    }
  }
}
</script>
