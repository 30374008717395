<template>
  <v-radio-group v-model="innerValue" v-bind="inputOptionsComputed">
    <v-radio
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :label="$t(option.label)"
    />
  </v-radio-group>
</template>

<script>
export default {
  props: ['value', 'options', 'inputOptions'],
  data () {
    return {
      innerValue: this.value
    }
  },
  computed: {
    inputOptionsComputed () {
      const out = {row: true, ...this.inputOptions}
      return out
    }
  },
  watch: {
    value (value) {
      this.innerValue = value
    },
    innerValue (value) {
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }
}
</script>
