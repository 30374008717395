<template>
  <div class="fc-map__page -with-header">
    <div class="fc-map__page-header">
      <v-row align="center">
        <v-col>
          <h1 class="title">{{ $t('menu.settings') }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn :to="{name: 'main'}" icon exact>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="fc-map__page-body py-3">
      <div class="d-flex align-center mb-3">
        <div :style="{width: '120px'}" class="pr-3">{{ $t('form.language') }}:</div>
        <v-btn-toggle :value="currentLocaleIndex" mandatory @change="setLocaleByIndex">
          <v-btn v-for="locale in locales" :key="locale.key">
            <v-img :src="require('~/assets/flags/' + locale.icon + '.svg')" height="20" width="30" class="mr-3" />
            {{ locale.name }}
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex align-center mb-3">
        <div :style="{width: '120px'}" class="pr-3">{{ $t('form.timezone') }}:</div>
        <div :style="{width: '120px'}">
          <v-select
            :value="serverSettings.timeOffset"
            :items="timezones"
            outlined
            hide-details
            dense
            item-value="key"
            item-text="name"
            @change="setTimeOffsetByKey"
          />
        </div>
      </div>
      <div class="d-flex align-center">
        <div :style="{width: '120px'}" class="pr-3">{{ $t('form.dateFormat') }}:</div>
        <div :style="{width: '200px'}">
          <v-select
            :value="serverSettings.dateFormat"
            :items="dateFormats"
            outlined
            hide-details
            dense
            item-value="key"
            item-text="name"
            @change="setDateFormat"
          />
        </div>
      </div>
    </div>
    <div class="fc-map__page-footer" />
  </div>
</template>

<script>
export default {
  computed: {
    serverSettings () { return this.$store.state.ux.serverSettings },
    locales () { return this.$store.state.i18n.locales },
    currentLocaleIndex () {
      return this.locales.findIndex(locale => locale.key === this.serverSettings.locale)
    },
    timezones () {
      const out = []
      for (let i = -12; i <= 12; i++) {
        out.push({key: i, name: i >= 0 ? '+' + i : i})
      }
      return out
    },
    dateFormats () {
      return [{key: 'YYYY-MM-DD', name: 'YYYY-MM-DD'}, {key: 'DD.MM.YYYY', name: 'DD.MM.YYYY'}]
    }
  },
  methods: {
    setLocaleByIndex (index) {
      this.$store.dispatch('ux/saveServerSettings', {locale: this.locales[index].key})
    },
    setTimeOffsetByKey (key) {
      this.$store.dispatch('ux/saveServerSettings', {timeOffset: key})
    },
    setDateFormat (key) {
      this.$store.dispatch('ux/saveServerSettings', {dateFormat: key})
    }
  }
}
</script>
