<template>
  <privacy width="800" />
</template>

<script>
import Privacy from '~/components/privacy/Privacy'

export default {
  components: {Privacy}
}
</script>
