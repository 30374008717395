<template>
  <div class="fc-map__page -full-width -full-height -outer-corners">
    <div class="fc-map__page-body">
      <forecast-data :data="chartData['Weather Forecast']" :fullscreen=true />
    </div>
  </div>
</template>

<script>
import ForecastData from '~/components/chart/ForecastData'
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  components: {
    ForecastData,
  },
  mixins: [generalFormMixin],
  data() {
    return {
      loading: false,
      chartData: {},
      instance: [],
    }
  },
  computed: {
    showChart () {
      return this.data && !_.isEmpty(this.data) && this.provider
    },
    id() { return this.$route.query.id },

  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.loadData()
      }
    }
  },
  methods: {
    loadData(force) {
      this.setLoading(true)

      this.chartData = {}

      const params = { id: this.id, period: 14 }
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', { path: 'meteo.request', data: params, force }))
        .then((response) => {
          this.chartData = response.data
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
  }

}
</script>