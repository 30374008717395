import configI18n from '~/config/i18n'

const {defaultLocale, locales, translations} = configI18n

export const state = () => ({
  locale: defaultLocale,
  locales
})

export const getters = {
  check: (state) => (messageKey) => {
    const path = messageKey.split(/\./)
    let target = translations
    for (let i = 0; i < path.length; i++) {
      if (_.isObject(target) && target.hasOwnProperty(path[i])) {
        target = target[path[i]]
      } else return false
    }
    return (_.isObject(target) && target.hasOwnProperty('en'))
  },
  t: (state) => (messageKey) => {
    if (!messageKey) { return messageKey }
    const path = messageKey.split(/\./)
    let target = translations
    for (let i = 0; i < path.length; i++) {
      if (_.isObject(target) && target.hasOwnProperty(path[i])) {
        target = target[path[i]]
      } else return messageKey
    }
    if (_.isObject(target) && target[state.locale]) return target[state.locale]
    return messageKey
  },
  locale: (state) => {
    return locales.find(locale => locale.key === state.locale)
  }
}

export const mutations = {
  setLocale (state, value) {
    state.locale = value
  }
}

export const actions = {
  setLocale ({commit, state}, value) {
    const newLocale = state.locales.find(locale => locale.key === value)
    if (newLocale) {
      commit('setLocale', value)
    }
    return Promise.resolve()
  }
}
