<template>
  <div class="highcharts-wrapper" v-if="showChart">
    <v-btn icon @click="fullScreen" class="fullscreen-icon">
      <v-icon>mdi-overscan</v-icon>
    </v-btn>
    <highcharts v-if="showChart" :options="options" ref="highcharts" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    showChart() {
      return this.data['Air Rh'] && !_.isEmpty(this.data['Air Rh'])
    },
    options() {
      if (!this.showChart) {
        return null
      }
      // const maxValue = Math.max(...$.map(this.data['Air Rh'], val => val))
      // const minValue = Math.min(...$.map(this.data['Air Rh'], val => val))
      return {
        exporting: {
          enabled: false,
        },
        chart: {
          type: '',
          spacingLeft: 0,
          alignTicks: true,
          animation: false,
          height: 500,
        },
        credits: {
          enabled: false
        },
        title: {
          text: '.',
          style: {
            color: '#FFF',
          }
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          categories: this.data.dates,
          crosshair: {
            width: 1,
            color: 'red'
          },
          // tickInterval: 4,
          labels: {
            rotation: -45,
          }
        },
        yAxis: {
          tickInterval: 5,
          // max: 100,
          // min: minValue - (minValue * 0.1),
          startOnTick: false,
          endOnTick: false,
          title: {
            text: this.$t('charts.watermark.wet') + ', %',
            margin: 15
          },
          labels: {
            formatter: function () {
              return this.value + '%';
            }
          },
          gridLineWidth: 0.5,
        },
        plotOptions: {
          series: {
            animation: false,
          },
          column: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.y > 0) {
                  return this.y;
                }
              },
              crop: false,
              overflow: 'none'
            }
          },
        },
        tooltip: {
          shared: true,
          formatter: function () {
            var s = '<b>' + this.x + '</b>';

            $.each(this.points, function () {
              s += '<br/><span style="color:' + this.series.color + '">\u25CF</span> ' + this.series.name + ': ' +
                this.y + '%';
            });

            return s;
          },
        },
        legend: {
          enabled: true
        },
        series: [{
          name: 'Макс',
          type: 'spline',
          marker: {
            symbol: 'circle',
            enabled: true
          },
          data: this.data['Air Rh']['max']
        },
        {
          name: 'Среднее',
          type: 'spline',
          marker: {
            symbol: 'circle',
            enabled: true
          },
          data: this.data['Air Rh']['avg']
        },
        {
          name: 'Мин',
          type: 'spline',
          marker: {
            symbol: 'circle',
            enabled: true
          },
          data: this.data['Air Rh']['min']
        },

        ]
      }
    }
  },
  methods: {
    fullScreen() {
      this.$refs.highcharts.chart.fullscreen.toggle()
    },
  }
}
</script>
