<template>
  <div class="highcharts-wrapper">
    <highcharts :options="options" ref="highcharts" />
  </div>
</template>



<script>
export default {
  props: {
    pump: {
      default: () => [],
    },
    index: {
      type: Number,
      default: null,
    },
  },
  computed: {
    showChart() {
      return this.pump && !_.isEmpty(this.pump);
    },
    options() {
      if (!this.showChart) {
        return null;
      }

      return {
        exporting: {
          enabled: false,
        },
        chart: {
          type: "solidgauge",
          //   width: 300,
          //   height: 200,
        },
        tooltip: {
          enabled: false,
        },
        title: null,
        pane: {
          // size: '150%',
          center: ["50%", "32%"],
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: "#fafafa",
            borderRadius: 5,
            innerRadius: "60%",
            outerRadius: "100%",
            shape: "arc",
          },
        },
        yAxis: {
          min: 0,
          max: 50,
          stops: [
            [0.5, "#DDDF0D"], // green
            [0.97, "#55BF3B"], // yellow
            [1, "#DF5353"], // red
          ],
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: 0,
          tickAmount: 0,
          tickPositions: [0, 50],
          title: null,
          //   title: {
          //     text: "Насос " + (this.index + 1),
          //     y: -50,
          //     style: {
          //       fontSize: 14,
          //     },
          //   },
          labels: {
            y: 16,
          },
        },

        plotOptions: {
          solidgauge: {
            borderRadius: 3,
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },

        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Speed",
            data: [this.pump.speed],
            dataLabels: {
              y: -30,
              format:
                '<div style="text-align:center">' +
                '<span style="font-size:18px">{y}</span><br/>' +
                '<span style="font-size:12px;opacity:0.4">Гц</span>' +
                "</div>",
            },
            tooltip: {
              valueSuffix: " Гц",
            },
          },
        ],
      };
    },
  },
};
</script>