<template>
  <v-row>
    <v-col :class="colClass">
      <div v-if="showRelayButton">
       <v-btn :loading="loading" :disabled="loading" icon class="fc-map__table-control btn-power-engine" @click="request">
        <span class="v-btn__content">
          <img :src="getRelayIcon(relay)" class="fc-map__table-icon">
        </span>
       </v-btn>
      </div>
      <v-menu v-for="control in controls" :key="control.key" :disabled="disabled || (control.key === 'irrigation' || control.key === 'acc') ? autoMode : false" offset-y>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" class="fc-map__table-control">
            <img :src="getControlIcon(control)" class="fc-map__table-icon">
          </v-btn>
        </template>
        <v-list v-for="option in control.options" :key="option.id" dense>
          <v-list-item @click="setControl(control, option)">
            <v-list-item-icon class="mr-3">
              <img :src="require('~/assets/icons/icon-' + option.icon + '.svg')" class="fc-map__table-icon">
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('controls.' + control.key + '.' + option.id) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <slot />
  </v-row>
</template>

<script>
import configControls from '~/config/controls'
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  mixins: [generalFormMixin],
  props: ['value', 'colClass', 'disabled', 'autoMode'],
  computed: {
    controls () { return configControls.filter(control => {
      return (control.key == 'accMixer' && this.showMixerButton || control.key != 'accMixer')
    }) },
    id () { return Number(this.$route.query.id) || 0 },
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) },
    showRelayButton () { return !this.item.relays ? false : true },
    showMixerButton () { return !this.item.deviceStatus.accMixer ? false : true },
    isReadOnly () { return this.$store.getters['auth/isReadOnly'] },
    relay () { 
      if (this.item.relays) {
        if (this.item.relays.power === 1) {
          return 'stop';
        } else {
          return 'start';
        }
      }
      return null;
    },
  },
  methods: {
    getRelayIcon (relay) {
      if (relay === 'stop') {
        return require('~/assets/icons/icon-power-engine-start.svg')
      }
      else if (relay === 'start') {
        return require('~/assets/icons/icon-power-engine-stop.svg')
      }
      return null;
    },
    getControlIcon (control) {
      const controlOption = control.options.find(option => option.id === this.value[control.key])
      if (controlOption && controlOption.icon) {
        return require(`~/assets/icons/icon-${controlOption.icon}.svg`)
      }
      return require(`~/assets/icons/icon-stop.svg`)
    },
    setControl(control, option) {
      const newValue = {...this.value}
      newValue[control.key] = option.id
      this.$emit('input', newValue)
    },
    request ({callback}) {
      if (this.isReadOnly) {
        return null
      }

      let text = this.$t('success.confirmStartDisel')

      if (this.relay == 'stop') {
        text = this.$t('success.confirmStopDisel')
      }

      this.$swal({
          icon: 'warning',
          text: text,
          showCancelButton: true,
          confirmButtonText: this.$t('actions.apply'),
          cancelButtonText: this.$t('actions.cancel'),
          focusConfirm: false
        }).then((result) => {
          if(result.value) {
            this.setLoading(true)

            let path = 'rtu.powerRelay.' + this.relay
      
            // if (this.item.manufacturer.search(/Galileosky/i) >= 0) {
            //   path = 'rtu.gApp'
            // }

            Promise.resolve()
              .then(() => this.$store.dispatch('fetch/fetch', {path: path, data: {id: this.id}}, {root: true}))
              .then(() => new Promise((resolve) => {
                this._timeout && clearTimeout(this._timeout)
                this._timeout = setTimeout(resolve, 2000)
              }))
              .then(() => this.$store.dispatch('rtu/fetch'))
              .then(() => {
                this.$swal({icon: 'success', text: this.$t('success.cmdSent')})
                callback && callback()
              })
              .catch(this.handleError)
              .finally(() => {
                this.setLoading(false)
              })
          }
        })
    }
  }
}
</script>
