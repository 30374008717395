<template>
  <v-row>
    <v-col :class="colClass">
      <v-menu v-for="control in controls" :key="control.key" :disabled="disabled" offset-y>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" class="fc-map__table-control">
            <img :src="getControlIcon(control)" class="fc-map__table-icon">
          </v-btn>
        </template>
        <v-list v-for="option in control.options" :key="option.id" dense>
          <v-list-item @click="setControl(control, option)">
            <v-list-item-icon class="mr-3">
              <img :src="require('~/assets/icons/icon-' + option.icon + '.svg')" class="fc-map__table-icon">
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('controls.' + control.key + '.' + option.id) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <slot />
  </v-row>
</template>

<script>
import configControls from '~/config/controls'

export default {
  props: ['value', 'colClass', 'disabled'],
  computed: {
    controls () { return configControls }
  },
  methods: {
    getControlIcon (control) {
      const controlOption = control.options.find(option => option.id === this.value[control.key])
      if (controlOption && controlOption.icon) {
        return require(`~/assets/icons/icon-${controlOption.icon}.svg`)
      }
      return require(`~/assets/icons/icon-stop.svg`)
    },
    setControl(control, option) {
      const newValue = {...this.value}
      newValue[control.key] = option.id
      this.$emit('input', newValue)
    }
  }
}
</script>
