<template>
  <div class="highcharts-wrapper" v-if="showChart">
    <v-btn icon @click="fullScreen" class="fullscreen-icon">
      <v-icon>mdi-overscan</v-icon>
    </v-btn>
    <highcharts v-if="showChart" :options="options" ref="highcharts"/>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    showChart () {
      return this.data && (typeof this.data === 'object') && this.data.range && this.data.temperature
    },
    options () {
      if (!this.showChart) {
        return null
      }
      return {
        exporting: {
          enabled: false,
        },
        chart: {
          type: '',
          spacingLeft: 0,
          alignTicks: true,
          animation: false,
          height: 500,
        },
        credits: {
          enabled: false
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          type: 'datetime',
          labels: {
            rotation: -45,
          }
        },
        yAxis: {
          tickInterval: 5,
          min: 0,
          startOnTick: false,
          endOnTick: false,
          title: {
            text: this.$t('charts.air_temperature') + ', °C',
            margin: 15
          },
          labels: {
            enabled: true
          },
          gridLineWidth: 0.5,
        },
        plotOptions: {
          series: {
            animation: false,
          },
          column: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                if (this.y > 0) {
                  return this.y;
                }
              },
              crop: false,
              overflow: 'none'
            }
          },
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          enabled: false
        },
        series: [{
          name: 'Temperature',
          data: this.data.temperature
        },
        {
          name: 'Range',
          yAxis: 0,
          zIndex: 5,
          events:{
            legendItemClick: function(){
              return false;
            }
          },
          data: this.data.range,
          enableMouseTracking: false,
          type: 'arearange',
          lineColor: '#4670bd',
          color: '#4670bd',
          lineWidth: 1,
          marker: {
            symbol: 'circle',
            enabled: true
          }
        }]
      }
    }
  },
  methods: {
    fullScreen() {
      this.$refs.highcharts.chart.fullscreen.toggle()
    },
  }
}
</script>
