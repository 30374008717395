<template>
  <div class="fc-map__page-body">
    <div class="body-1 my-3">
      <v-alert v-if="!item.snapshot" type="info" outlined>
        {{ $t('success.noCamera') }}
      </v-alert>
      <template v-else>
        <div class="d-flex align-center justify-center">
          <v-img
            :src="item.snapshot.big"
            :lazy-src="item.snapshot.small"
            height="400"
            contain
          >
            <template v-slot:placeholder>
              <div class="d-flex fill-height align-center justify-center">
                <v-progress-circular indeterminate />
              </div>
            </template>
          </v-img>
        </div>
      </template>
      <div class="d-flex flex-wrap justify-end mt-3">
        <v-btn :loading="loading" text class="ml-3" @click="refreshSnapshot">
          {{ $t('actions.refresh') }}
        </v-btn>
        <v-btn v-if="isAdmin" text class="ml-3" @click="editCamera">
          {{ $t('actions.' + (item.camera ? 'editCamera' : 'addCamera')) }}
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="opened" :persistent="loading" max-width="600">
      <v-card>
        <v-card-title>
          {{ $t('camera.' + (instance.ip ? 'edit' : 'add')) }}
        </v-card-title>
        <v-card-text>
          <alert :msg.sync="alert" />
          <fields v-model="instance" :fields="fields" />
        </v-card-text>
        <v-card-actions class="px-6 pb-4">
          <v-spacer />
          <v-btn :loading="loading" text @click="opened = false">
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn :loading="loading" color="primary" @click="save">
            {{ instance.ip ? $t('actions.save') : $t('actions.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import configFields from '~/config/fields'
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  mixins: [generalFormMixin],
  props: ['id', 'tab'],
  data () {
    return {
      instance: {},
      opened: false
    }
  },
  computed: {
    isAdmin () { return this.$store.getters['auth/isAdmin'] },
    fields () { return configFields.camera },
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) }
  },
  beforeDestroy () {
    this._timeout && clearTimeout(this._timeout)
  },
  methods: {
    editCamera () {
      this.instance = this.prepareInstance(this.item.camera, this.fields)
      this.opened = true
    },
    refreshSnapshot () {
      this.setLoading(true)
      Promise.resolve()
        .then(() => new Promise((resolve, reject) => {
          this.$bus.$emit('refreshSnapshot', {id: this.id, resolve, reject})
        }))
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    save () {
      this.setLoading(true)
      this.resetAlert()
      const data = this.prepareFormSubmitData(this.instance, this.fields)
      data.id = this.id
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.updateCamera', data}))
        .then(() => new Promise((resolve) => {
          this._timeout && clearTimeout(this._timeout)
          this._timeout = setTimeout(resolve, 2000)
        }))
        .then(() => this.$store.dispatch('rtu/fetch'))
        .then(() => {
          this.$swal({icon: 'success', text: this.$t('success.cameraSettingsUpdated')})
          this.opened = false
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
