<template>
  <div class="fc-grid-container">
    <div
      v-if="$slots.head || $scopedSlots.head"
      :style="headWrapperBox"
      class="fc-grid-head__wrapper"
    >
      <div
        ref="head"
        v-dragscroll="dragScrollEnabledInner"
        :style="headScrollBox"
        class="fc-grid-head__scroll"
        @scroll="syncScroll('head')"
      >
        <slot name="head" v-bind="defaultSlotProps" />
      </div>
    </div>
    <div
      v-if="$slots.side || $scopedSlots.side"
      :style="sideWrapperBox"
      class="fc-grid-side__wrapper"
    >
      <div
        ref="side"
        v-dragscroll="dragScrollEnabledInner"
        :style="sideScrollBox"
        class="fc-grid-side__scroll"
        @scroll="syncScroll('side')"
      >
        <slot name="side" v-bind="defaultSlotProps" />
      </div>
    </div>
    <div
      ref="body"
      v-dragscroll="dragScrollEnabledInner"
      v-node-resize="handleNodeResize"
      :style="bodyScrollBox"
      class="fc-grid-body__scroll"
      @scroll="syncScroll('body')"
    >
      <slot name="body" v-bind="defaultSlotProps" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headSize: {
      type: Number,
      default: 0
    },
    sideSize: {
      type: Number,
      default: 0
    },
    dragScrollEnabled: {
      type: Boolean,
      default: undefined
    },
    xScroll: {
      type: Number,
      default: undefined
    },
    yScroll: {
      type: Number,
      default: undefined
    },
    syncPropDirection: {
      type: String,
      default: 'inner->value'
    }
  },
  data() {
    return {
      dragScrollEnabledInner: true,
      syncScrollDirection: null,
      xScrollInner: 0,
      yScrollInner: 0,
      xScrollbarVisible: false,
      yScrollbarVisible: false,
      bodyClientWidth: null,
      bodyClientHeight: null,
      bodyScrollWidth: null,
      bodyScrollHeight: null
    }
  },
  computed: {
    scrollbarSize () {
      return this.$store.state.ux.scrollbarSize
    },
    mobileScrollbarSize () {
      return this.$store.state.ux.mobileScrollbarSize
    },
    headWrapperBox() {
      return {
        top: 0,
        right: this.yScrollbarVisible ? this.scrollbarSize + 'px' : 0,
        bottom: 'auto',
        left: this.sideOverlay ? 0 : this.sideSize + 'px',
        height: this.headSize + 'px'
      }
    },
    sideWrapperBox() {
      return {
        top: this.headOverlay ? 0 : this.headSize + 'px',
        right: 'auto',
        bottom: this.xScrollbarVisible ? this.scrollbarSize + 'px' : 0,
        left: 0,
        width: this.sideSize + 'px'
      }
    },
    headScrollBox() {
      // If the scrollbarSize === 0, count it as mobile with semitransparent absolute scrollbars; We want to hide them also, and set paddingBottom for this case;
      return {
        bottom: (this.scrollbarSize ? -this.scrollbarSize : -this.mobileScrollbarSize) + 'px',
        paddingBottom: (this.scrollbarSize ? 0 : this.mobileScrollbarSize) + 'px'
      }
    },
    sideScrollBox() {
      return {
        right: (this.scrollbarSize ? -this.scrollbarSize : -this.mobileScrollbarSize) + 'px',
        paddingRight: (this.scrollbarSize ? 0 : this.mobileScrollbarSize) + 'px'
      }
    },
    bodyScrollBox() {
      return {
        position: 'absolute',
        top: this.headOverlay ? 0 : this.headSize + 'px',
        right: 0,
        bottom: 0,
        left: this.sideOverlay ? 0 : this.sideSize + 'px'
      }
    },
    defaultSlotProps() {
      return {
        dragScrollEnabled: this.dragScrollEnabledInner,
        dragScrollEnable: this.dragScrollEnable,
        dragScrollDisable: this.dragScrollDisable,
        bodyClientWidth: this.bodyClientWidth,
        bodyClientHeight: this.bodyClientHeight,
        bodyScrollWidth: this.bodyScrollWidth,
        bodyScrollHeight: this.bodyScrollHeight,
        resize: this.handleNodeResize
      }
    }
  },
  watch: {
    dragScrollEnabled: {
      immediate: true,
      handler(value) {
        if (value !== undefined) {
          this.dragScrollEnabledInner = value
        }
      }
    },
    xScroll: {
      immediate: true,
      handler(value) {
        if (this.syncPropDirection === 'inner->value') { return false }
        this.setScrolls(value, this.yScrollInner, true)
      }
    },
    yScroll: {
      immediate: true,
      handler(value) {
        if (this.syncPropDirection === 'inner->value') { return false }
        this.setScrolls(this.xScrollInner, value, true)
      }
    },
    xScrollInner: {
      immediate: true,
      handler(value) {
        if (this.syncPropDirection === 'value->inner') { return false }
        this.$emit('update:xScroll', value)
      }
    },
    yScrollInner: {
      immediate: true,
      handler(value) {
        if (this.syncPropDirection === 'value->inner') { return false }
        this.$emit('update:yScroll', value)
      }
    },
    dragScrollEnabledInner: {
      immediate: true,
      handler(value) {
        this.$emit('update:dragScrollEnabled', value)
      }
    }
  },
  methods: {
    handleNodeResize() {
      if (!this.$refs.body) { return false }
      this.xScrollbarVisible = this.$refs.body.scrollWidth > this.$refs.body.clientWidth
      this.yScrollbarVisible = this.$refs.body.scrollHeight > this.$refs.body.clientHeight
      this.bodyClientWidth = this.$refs.body.clientWidth
      this.bodyClientHeight = this.$refs.body.clientHeight
      this.bodyScrollWidth = this.$refs.body.scrollWidth
      this.bodyScrollHeight = this.$refs.body.scrollHeight
      this.$emit('resize')
    },
    dragScrollEnable() {
      this.dragScrollEnabledInner = true
    },
    dragScrollDisable() {
      this.dragScrollEnabledInner = false
    },
    syncScroll(direction) {
      // Sync should go in one direction;
      // When we start syncing, we update scroll for the other containers, and they emit scroll events that we have to discard;
      if (this.syncScrollDirection && this.syncScrollDirection !== direction) { return false }
      this.setSyncScrollDirection(direction)
      // Instead of using xScroll + direction for setting the actuall scrollTop/scrollLeft of the containers, set them directly;
      let newXScroll = this.xScrollInner
      let newYScroll = this.yScrollInner
      if (direction === 'head' && this.$refs.head) {
        newXScroll = this.$refs.head.scrollLeft
      } else if (direction === 'side' && this.$refs.side) {
        newYScroll = this.$refs.side.scrollTop
      } else if (direction === 'body' && this.$refs.body) {
        newXScroll = this.$refs.body.scrollLeft
        newYScroll = this.$refs.body.scrollTop
      }
      this.setScrolls(newXScroll, newYScroll)
    },
    setScrolls(x, y, setAll) {
      this.xScrollInner = x
      this.yScrollInner = y
      if ((setAll || this.syncScrollDirection !== 'head') && this.$refs.head) {
        this.$refs.head.scrollLeft = x
      }
      if ((setAll || this.syncScrollDirection !== 'side') && this.$refs.side) {
        this.$refs.side.scrollTop = y
      }
      if ((setAll || this.syncScrollDirection !== 'body') && this.$refs.body) {
        this.$refs.body.scrollLeft = x
        this.$refs.body.scrollTop = y
      }
    },
    setSyncScrollDirection(direction) {
      this.syncScrollDirection = direction
      this._directionTimeout && clearTimeout(this._directionTimeout)
      if (this.syncScrollDirection) {
        this._directionTimeout = setTimeout(() => {
          this.syncScrollDirection = null
        }, 200)
      }
    }
  }
}
</script>