import Vue from 'vue'

const bus = new Vue({})

const VueBus = function () {
}

VueBus.install = function (Vue) {
  Vue.bus = bus
  if (!Vue.prototype.hasOwnProperty('$bus')) {
    Object.defineProperty(Vue.prototype, '$bus', {
      get: function () {
        return bus
      }
    })
  }
}

Vue.use(VueBus)
