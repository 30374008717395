<template>
  <div class="highcharts-wrapper" v-if="showChart">
    <v-btn icon @click="fullScreen" class="fullscreen-icon">
      <v-icon>mdi-overscan</v-icon>
    </v-btn>
    <highcharts v-if="showChart" :options="options" ref="highcharts"/>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => []
    }
  },
  computed: {
    showChart () {
      return this.data['Wind Speed'] && !_.isEmpty(this.data['Wind Speed'])
    },
    options () {
      if (!this.showChart) {
        return null
      }
      return {
        exporting: {
          enabled: false,
        },
        chart: {
          type: '',
          spacingLeft: 0,
          alignTicks: true,
          animation: false,
          height: 300,
        },
        credits: {
          enabled: false
        },
        title: {
          text: '.',
          style: {
            color: '#FFF',
          }
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          categories: this.data.dates,
          // tickInterval: 4,
          crosshair: {
            width: 1,
            color: 'red'
          },
          labels: {
            rotation: -45,
          }
        },
        yAxis: {
          tickInterval: 0.2,
          startOnTick: false,
          endOnTick: false,
          title: {
            text: this.$t('charts.wind_speed') + ', ' + this.$t('charts.units.ms'),
            margin: 15
          },
          labels: {
            formatter: function () {
              return this.value + ' мс';
            }
          },
          gridLineWidth: 0.5,
        },
        plotOptions: {
          series: {
            animation: false,
            pointWidth: 0,
            marker: {
              symbol: 'circle',
              enabled: false
            },
          },
        },
        tooltip: {
          shared: true,
          enabled: true,
          formatter: function () {
            var s = '<b>' + this.x + '</b>';

            $.each(this.points, function () {
              s += '<br/><span style="color:' + this.series.color + '">\u25CF</span> Скорость ветра: ' +
                this.y + ' мс';
            });

            return s;
          },
        },
        legend: {
          enabled: false
        },
        series: [{
          yAxis: 0,
          zIndex: 2,
          events:{
            legendItemClick: function(){
              return false;
            }
          },
          data: this.data['Wind Speed'],
          type: 'spline',
          marker: {
            symbol: 'circle',
            enabled: true
          },
          lineColor: 'rgb(46, 125, 50)',
          color: 'rgb(46, 125, 50)',
          lineWidth: 1,
        }]
      }
    }
  },
  methods: {
    fullScreen() {
      this.$refs.highcharts.chart.fullscreen.toggle()
    },
  }
}
</script>
