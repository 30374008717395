export const state = () => ({
  items: [],
  editingItemId: null,
  editingCoords: null,
  ready: false,
  randomToken: ''
})

export const getters = {
  items(state, getters, rootState) {
    return state.items.map((item) => {
      return item
    })
  }
}

export const mutations = {
  setItems(state, items) {
    state.items = items
  },
  setReady(state, value) {
    state.ready = value
  },
  setEditingItemId(state, value) {
    state.editingItemId = value
  },
  setEditingCoords(state, value) {
    state.editingCoords = value
  },
}

export const actions = {
  fetch({ commit, dispatch }, options) {
    if (!options) { options = {} }
    return Promise.resolve()
      .then(() => dispatch('fetch/fetch', { path: 'fields', force: options.force }, { root: true }))
      .then((response) => {
        commit('setItems', response.data)
      })
      .then(() => {
        commit('setReady', true)
      })
  },
  setEditingItemId ({commit}, id) {
    commit('setEditingItemId', id)
    return Promise.resolve()
  },
  setEditingCoords ({commit}, coords) {
    commit('setEditingCoords', coords)
    return Promise.resolve()
  }, 
}
