import Vue from 'vue'
import configSettings from '~/config/settings'
import clientStorage from '~/clientStorage'

const defaultServerSettings = {
  dateFormat: 'YYYY-MM-DD',
  timeFormat: 'HH:mm',
  timeOffset: moment().utcOffset() / 60,
  locale: 'en'
}

export const state = () => ({
  settings: {...configSettings.default},
  serverSettings: {...defaultServerSettings},
  snack: {},
  scrollbarSize: 15,
  mobileScrollbarSize: 15 // That's some predefined value - if the scrollbarSize === 0, we count it as mobile hideable scrollbar. In some cases we want to hide it as well, and add a padding for about 15px for that.
})

export const getters = {
  serverSettings (state) {
    return state.serverSettings
  }
}

export const mutations = {
  replaceSettings (state, data) {
    state.settings = data
  },
  extendSettings (state, data) {
    // We need deep merge here;
    Vue.set(state, 'settings', _.merge({updated: true}, state.settings, data))
  },
  updateSnack (state, data) {
    state.snack = data
  },
  updateServerSettings (state, data) {
    state.serverSettings = {...defaultServerSettings, ...(state.serverSettings || {}), ...(data || {})}
  },
  setServerSettings (state, data) {
    state.serverSettings = {...defaultServerSettings, ...(data || {})}
  },
  setScrollbarSize (state, data) {
    state.scrollbarSize = data
  }
}

export const actions = {
  updateSettings ({commit, dispatch, state}, data) {
    commit('extendSettings', data)
    clientStorage.set(configSettings.cookieName, state.settings)
    return Promise.resolve(data)
  },
  clearSettings ({commit, dispatch, state}) {
    commit('replaceSettings', {...configSettings.default})
    clientStorage.remove(configSettings.cookieName)
    return Promise.resolve()
  },
  updateSnack ({commit}, value) {
    commit('updateSnack', value)
    return Promise.resolve(value)
  },
  saveServerSettings ({commit, state, dispatch}, data) {
    commit('updateServerSettings', data)
    const promises = []
    promises.push(dispatch('i18n/setLocale', state.serverSettings.locale, {root: true}))
    promises.push(dispatch('fetch/fetch', {path: 'auth.settings', data: {personalize: JSON.stringify(state.serverSettings)}}, {root: true}))
    return Promise.all(promises)
  },
  loadServerSettings ({commit, state, dispatch}, user) {
    try {
      const settings = user.personalize ? JSON.parse(user.personalize) : {}
      commit('setServerSettings', settings)
    } catch (error) {
      console.error('Failed to JSON.parse user.personalize settings: ', error)
    }
    return dispatch('i18n/setLocale', state.serverSettings.locale, {root: true})
  },
  setScrollbarSize ({commit}, data) {
    commit('setScrollbarSize', data)
    return Promise.resolve()
  }
}
