export const state = () => ({
    items: [],
    editingCoords: null,
    ready: false,
    randomToken: ''
})

export const getters = {
    items (state, getters, rootState) {
        return state.items.map((item) => {
            return item
        })
    }
}

export const mutations = {
    setItems (state, items) {
        state.items = items
    },
    setReady (state, value) {
        state.ready = value
    },
    setEditingCoords (state, value) {
        state.editingCoords = value
    },
}

export const actions = {
    fetch ({commit, dispatch}, options) {
        if (!options) { options = {} }
        return Promise.resolve()
            .then(() => dispatch('fetch/fetch', {path: 'station.list', force: options.force}, {root: true}))
            .then((response) => {
                commit('setItems', response.data || [])
            })
            .then(() => {
                commit('setReady', true)
            })
    }
}
