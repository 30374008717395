<template>
  <v-row wrap>
    <v-col v-for="field in fields" v-show="!field.hasOwnProperty('show') || field.show(value)" :key="field.key" :cols="field.cols || 12">
      <field-control
        v-model="innerValue[field.key]"
        :disabled="disabled"
        :autosave="autosave"
        :field="field"
        :instance="innerValue"
        :validation-errors="validationErrors"
        @update:validationErrors="updateValidationErrors"
        @input="(value) => $emit('updateField', {key: field.key, value})"
      />
    </v-col>
    <slot :value="innerValue" />
  </v-row>
</template>

<script>
export default {
  props: ['fields', 'value', 'validationErrors', 'disabled', 'autosave'],
  data () {
    return {
      innerValue: _.cloneDeep(this.value)
    }
  },
  watch: {
    value: {
      deep: true,
      handler (value) {
        this.innerValueEmitDisabled = true
        this.innerValue = _.cloneDeep(value)
        this.$nextTick(() => {
          this.innerValueEmitDisabled = false
        })
      }
    },
    innerValue: {
      deep: true,
      handler (value) {
        if (this.innerValueEmitDisabled) return false
        this.$emit('input', value)
      }
    }
  },
  methods: {
    updateValidationErrors (value) {
      this.$emit('update:validationErrors', value)
    }
  }
}
</script>
