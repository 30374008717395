<template>
  <div class="fc-map__page-body">
    <div class="body-1">
      <v-row>
        <v-col cols="12" sm="5">
          <map-item :item="item" :icon-mode="{lineWidth: 1, size: 260}" :editing="editingInstance" class="fc-map__map-item" />
        </v-col>
        <v-col cols="12" sm="7">
          <div class="title">
            {{ $t('mainMenu.map') }}
          </div>
          <div>
            <fields v-model="instance" :disabled="!editingInstance" :fields="fields" />
            <!-- <v-row>
              <v-col>
                <v-select
                :value="currPlacement"
                :items="getPlacementsList"
                hide-details
                ref="selectedEl"
                :disabled="!editingInstance"
                :label="$t(`form.placement`)"
                item-value="key"
                item-text="name"
                @change="setPlacementCoords"
              />
              </v-col>
            </v-row> -->
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-3">
      <v-col class="d-flex flex-wrap justify-end">
        <v-btn v-if="!editingInstance" text @click="start">{{ $t('actions.editOnTheMap') }}</v-btn>
        <template v-else>
          <v-btn :loading="loading" class="ml-3" text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
          <v-btn :loading="loading" class="ml-3" color="primary" @click="save">{{ $t('actions.save') }}</v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import configFields from '~/config/fields'
import generalFormMixin from '~/components/mixins/generalFormMixin'
import MapItem from '~/components/map/MapItem'

export default {
  components: {MapItem},
  mixins: [generalFormMixin],
  props: ['id', 'tab'],
  data () {
    return {
      instance: {},
      placementsList: []
      
    }
  },
  computed: {
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) },
    placements () { return this.$store.state.auth.user?.placements || [] },
    type () { return this.item?.displaySettings?.type || 'circle' },
    // fields () { return this.type === 'lateral' ? configFields.itemMapLateral : configFields.itemMapCircle },
    fields () { 
      let configFieldsItem = this.type === 'lateral' ? configFields.itemMapLateral : configFields.itemMapCircle
      return configFieldsItem.filter(field => {
        if (field.key === 'placement') {        
          field.dataSource = this.placements
        }
        return field
    }) 
    },
    editingItemId () { return this.$store.state.rtu.editingItemId },
    editingInstance () { return this.id === this.editingItemId },
    updateInstanceTrigger () {
      return JSON.stringify({id: this.id, item: this.item, editingInstance: this.editingInstance})
    },
    resetEditingInstanceTrigger () {
      return this.id + this.tab
    },
    editingCoordsTrigger () { return JSON.stringify(this.$store.state.rtu.editingCoords) },
    instanceTrigger () { return JSON.stringify(this.instance) },
    currPlacement() {
      return this.item.displaySettings.placement
    },
    getPlacementsList () { 
      Object.keys(this.placements).forEach((i) => {
        this.placementsList.push({
          key: this.placements[i].id,
          name: this.placements[i].name,
        })
        
      })
      return this.placementsList
     },
  },
  watch: {
    updateInstanceTrigger: {
      immediate: true,
      handler () {
        if (this.editingInstance) { return false }
        this.instance = this.prepareMapInstance(this.item)
      }
    },
    resetEditingInstanceTrigger: 'cancel',
    editingCoordsTrigger () {
      if (this._coordsSyncDisabled === 'map->fields') { return false }
      this._coordsSyncDisabled = 'fields->map'
      if (this.editingInstance) {
        const data = this.$store.state.rtu.editingCoords
        if (data) {
          if (this.type === 'lateral') {
            if (data.lateralStart) {
              this.instance.startLat = data.lateralStart.lat
              this.instance.startLng = data.lateralStart.lng
            }
            if (data.lateralEnd) {
              this.instance.endLat = data.lateralEnd.lat
              this.instance.endLng = data.lateralEnd.lng
            }
            this.instance.height = data.lateralHeight
          } else {
            if (data.center) {
              this.instance.lat = data.center.lat
              this.instance.lng = data.center.lng
            }
            this.instance.radius = data.circlePrimaryRadius
          }
        }
      }
      this.$nextTick(() => {
        this._coordsSyncDisabled = false
      })
    },
    instanceTrigger () {
      if (this._coordsSyncDisabled === 'fields->map') { return false }
      this._coordsSyncDisabled = 'map->fields'
      if (this.editingInstance) {
        const data = {...this.$store.state.rtu.editingCoords}
        if (this.type === 'lateral') {
          Object.assign(data, {
            lateralStart: {
              lat: this.instance.startLat,
              lng: this.instance.startLng
            },
            lateralEnd: {
              lat: this.instance.endLat,
              lng: this.instance.endLng
            },
            lateralHeight: this.instance.height,
            lateralLength: this.instance.length,
            lateralStartPosition: this.instance.startPosition,
            lateralEndPosition: this.instance.endPosition
          })
        } else {
          if (this.instance.placement) {
            let coords = (this.instance.placement.coords).split(',')
            this.instance.lat = coords[0]
            this.instance.lng = coords[1]
          }
          Object.assign(data, {
            center: {
              lat: this.instance.lat,
              lng: this.instance.lng
            },
            circlePrimaryRadius: this.instance.radius,
            circleStartAngle: this.instance.startAngle,
            circleEndAngle: this.instance.endAngle
          })
        }
        this.$store.dispatch('rtu/setEditingCoords', data)
      }
      this.$nextTick(() => {
        this._coordsSyncDisabled = false
      })
    }
  },
  methods: {
    prepareMapInstance (item) {
      let data = {}
      if (this.item && this.item.displaySettings && this.item.deviceSettings) {
        if (this.type === 'lateral') {
          data = {
            startLat: this.item.displaySettings.path_coords?.start_lat,
            startLng: this.item.displaySettings.path_coords?.start_lng,
            endLat: this.item.displaySettings.path_coords?.end_lat,
            endLng: this.item.displaySettings.path_coords?.end_lng,
            height: this.item.deviceSettings.height,
            length: this.item.displaySettings.length,
            startPosition: this.item.displaySettings.startPosition,
            endPosition: this.item.displaySettings.endPosition
          }
        } else {
          data = {
            lat: this.item.displaySettings.center?.lat,
            lng: this.item.displaySettings.center?.lng,
            radius: this.item.displaySettings.radius,
            startAngle: this.item.displaySettings.startAngle,
            endAngle: this.item.displaySettings.endAngle,
            offsetAngle: this.item.displaySettings.offsetAngle,
            placement: this.item.displaySettings.placement
          }
        }
      }
      return this.prepareInstance(data, this.fields)
    },
    start () {
      this.$store.dispatch('rtu/setEditingItemId', this.item.id)
    },
    save () {
      this.setLoading(true)
      const data = this.prepareFormSubmitData(this.instance, this.fields)
      data.id = this.id
      data.coords = data.lat + ',' + data.lng
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {
          path: 'rtu.updateCoords',
          data
        }))
        .then((response) => {
          if (response.data && response.data.error) {
            throw new Error(response.data.error)
          }
        })
        .then(() => this.$store.dispatch('rtu/fetch'))
        .then(() => {
          this.$swal({icon: 'success', text: this.$t('success.coordsUpdated')})
          this.cancel()
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    cancel () {
      this.instance = this.prepareMapInstance(this.item)
      this.$store.dispatch('rtu/setEditingItemId', null)
    }
  }
}

</script>
