<template>
  <v-app>
    <v-main>
      <component :is="layout">
        <router-view />
      </component>
    </v-main>
    <snack />
  </v-app>
</template>

<script>
import DefaultLayout from '~/layouts/Default'
import CenteredLayout from '~/layouts/Centered'
import configI18n from '~/config/i18n'
import clientStorage from '~/clientStorage'

export default {
  components: {DefaultLayout, CenteredLayout},
  created () {
    $('html').addClass('overflow-hidden')
  },
  beforeDestroy () {
    $('html').removeClass('overflow-hidden')
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'Default') + 'Layout'
    },
    locale () {
      return this.$store.getters['i18n/locale']
    }
  },
  watch: {
    locale: {
      immediate: true,
      handler (value) {
        if (value && value.key) {
          moment.locale(value.key)
          clientStorage.set(configI18n.cookieName, value.key)
          this.$vuetify.lang.current = value.key
        }
      }
    }
  }
}
</script>
