<template>
  <div v-if="showActual" class="fc-map__page-body" style="overflow: unset">
    <div class="body-1">
      <v-card-text>
      <div class="title mb-2">Актуальные данные</div>
      <v-row style="margin-top: 0;">
        <v-col style="padding-top: 0; padding-bottom: 0;">Обновлено: {{ this.data.date }} </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            {{ $t("form.temperature") }}: {{ this.data.Temperature }}
            {{ $t("form.temperatureSuffix") }}
          </div>
          <div>
            Влажность: {{ this.data["Air Rh"]
            }}{{ $t("form.watermarkSuffix") }}
          </div>
          <div>
            {{ $t("charts.dew_point") }}: {{ this.data.Dewpoint }}
            {{ $t("form.temperatureSuffix") }}
          </div>
        </v-col>
        <v-col>
          <div>
            Ветер: {{ this.data["Wind Speed"] }}
            {{ this.$t("charts.units.ms") }}
          </div>
          <div>
            Порывы до {{ this.data["Wind Speed Max"] }}
            {{ this.$t("charts.units.ms") }}
          </div>
          <div>Направление: {{ this.data["Wind Direction"] }}</div>
        </v-col>
        <v-col>
            <div>Дождь</div>
            <div>За час: {{ this.data["Rain 1H"] }}</div>
            <div>За 12 часов: {{ this.data["Rain 12H"] }}</div>
            <!-- <div>С полуночи: {{ this.data["Rain midnight"] }}</div> -->
        </v-col>
      </v-row>
    </v-card-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  computed: {
    showActual() {
      return this.data && !_.isEmpty(this.data);
    },
    timeOffset() {
      let timeOffset = this.$store.getters['ux/serverSettings'].timeOffset
      return timeOffset - 3
    }
  },
};
</script>