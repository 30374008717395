import Vue from 'vue'

const t = function (data) {
  return this.$store.getters['i18n/t'](data)
}

const T = function () {
}

T.install = function (Vue) {
  Vue.t = t
  if (!Vue.prototype.hasOwnProperty('$t')) {
    Object.defineProperty(Vue.prototype, '$t', {
      get: function () {
        return t
      }
    })
  }
}

Vue.use(T)
