<template>
  <div>
    <slot name="activator" :on="{click: editSettings}">
      <v-btn icon @click="editSettings">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </slot>
    <v-dialog v-model="opened" :persistent="loading" max-width="600">
      <v-card>
        <v-card-title>
          {{ $t('admin.settings') }}
        </v-card-title>
        <v-card-text>
          <alert :msg.sync="alert" />
          <fields v-model="instance" :fields="fields" />
        </v-card-text>
        <v-card-actions class="px-6 pb-4">
          <v-spacer />
          <v-btn :loading="loading" color="primary" @click="save">
            {{ $t('actions.save') }}
          </v-btn>
          <v-btn :loading="loading" text @click="opened = false">
            {{ $t('actions.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import configFields from '~/config/fields'
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  mixins: [generalFormMixin],
  props: ['id'],
  data () {
    return {
      instance: {},
      opened: false
    }
  },
  computed: {
    fields () { return configFields.adminItem },
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) }
  },
  methods: {
    editSettings () {
      this.instance = this.prepareInstance(this.item, this.fields)
      this.opened = true
    },
    save () {
      this.setLoading(true)
      this.resetAlert()
      const data = this.prepareFormSubmitData(this.instance, this.fields)
      data.id = this.id
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.updateAdminSettings', data}))
        .then(() => this.$store.dispatch('rtu/fetch'))
        .then(() => {
          this.$swal({icon: 'success', text: this.$t('success.settingsUpdated')})
          this.opened = false
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
