<template>
  <div class="highcharts-wrapper" v-if="showChart">
    <v-btn icon @click="fullScreen" class="fullscreen-icon">
      <v-icon>mdi-overscan</v-icon>
    </v-btn>
    <highcharts v-if="showChart" :options="options" ref="highcharts"/>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    showChart () {
      return this.data['Air Temperature'] && !_.isEmpty(this.data['Air Temperature'])
    },
    options () {
      if (!this.showChart) {
        return null
      }
      // const maxValue = Math.max(...$.map(this.data['Air Temperature'], val => val))
      // const minValue = Math.min(...$.map(this.data['Air Temperature'], val => val))
      return {
        exporting: {
          enabled: false,
        },
        chart: {
          type: '',
          spacingLeft: 0,
          alignTicks: true,
          animation: false,
          height: 500,
        },
        credits: {
          enabled: false
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          categories: this.data.dates,
          // tickInterval: 4,
          labels: {
            rotation: -45,
          }
        },
        yAxis: {
          tickInterval: 5,
          max: 50,
          min: 0,
          startOnTick: false,
          endOnTick: false,
          title: {
            text: this.$t('charts.air_temperature') + ', °C',
            margin: 15
          },
          labels: {
            formatter: function () {
              return this.value + '°';
            }
          },
          gridLineWidth: 0.5,
        },
        plotOptions: {
          series: {
            animation: false,
          },
          column: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                if (this.y > 0) {
                  return this.y;
                }
              },
              crop: false,
              overflow: 'none'
            }
          },
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          enabled: true
        },
        series: [
        {
          name: 'Среднее',
          data: this.data['Air Temperature']
        }
        ]
      }
    }
  },
  methods: {
    fullScreen() {
      this.$refs.highcharts.chart.fullscreen.toggle()
    },
  }
}
</script>
