<script>
import { 
  // marker, 
  // icon, 
  polygon, polyline } from "leaflet";

export default {
  props: ["item", "map", "selected"],
  data() {
    return {
      dotObject1: null,
      dotObject2: null,
    };
  },
  computed: {
    hideTitles() {
      return this.$store.state.map.hideTitles;
    },
    displaySettings() {
      return this.item.displaySettings || {};
    },
    coordinates() {
      return this.displaySettings.coordinates;
    },
    label() {
      return this.displaySettings.name;
    },
    color() {
      return this.displaySettings.color;
    },
    figure() {
      return this.displaySettings.figure;
    },
    start() {
      return {
        lat: "45.09610724978197",
        lng: "43.75596225332066",
      };
    },
    end() {
      return {
        lat: "45.08573485674411",
        lng: "43.76697622254176",
      };
    },
    drawTrigger() {
      return JSON.stringify({
        // map: !!this.map,
        coordinates: this.coordinates,
        label: this.label,
        hideTitles: this.hideTitles,
      });
    },
    dotTrigger() {
      return JSON.stringify({
        coordinates: this.coordinates,
        hideTitles: this.hideTitles,
      });
    },
    selectedTrigger() {
      return JSON.stringify({
        selected: this.selected,
        editing: this.editing,
      });
    },
  },
  watch: {
    drawTrigger: {
      immediate: true,
      handler() {
        if (this.mapObject) {
          this.mapObject.remove();
        }
        if (!this.map) {
          return false;
        }

        if (this.figure == "polygon") {
          this.mapObject = polygon(this.coordinates, { color: this.color });
        } else if (this.figure == "polyline") {
          this.mapObject = polyline(this.coordinates, {
            color: this.color,
            weight: 5,
          });
        }

        this.mapObject.bindTooltip(this.label, {
          permanent: !this.hideTitles,
          direction: "center",
          className: "labelstyle",
        });
        this.mapObject.addTo(this.map);
        this.mapObject.bringToBack();
      },
    },
    // dotTrigger: {
    //   immediate: true,
    //   handler() {
    //     if (this.dotObject1) {
    //       this.dotObject1.remove();
    //     }
    //     if (this.dotObject2) {
    //       this.dotObject2.remove();
    //     }

    //     if (!this.map) {
    //       return false;
    //     }

    //     if (this.start) {
    //       this.dotObject1 = marker(this.start, {
    //         zIndexOffset: 1,
    //         icon: icon({
    //           iconUrl: require("~/assets/icons/icon-dot.svg"),
    //           iconSize: 20,
    //           iconAnchor: [10, 10],
    //         }),
    //       });
    //       this.dotObject1.addTo(this.map);
    //       this.dotObject1.on("click", () => {
    //         this.$emit("click");
    //       });
    //     }

    //     if (this.end) {
    //       this.dotObject2 = marker(this.end, {
    //         zIndexOffset: 1,
    //         icon: icon({
    //           iconUrl: require("~/assets/icons/icon-dot.svg"),
    //           iconSize: 20,
    //           iconAnchor: [10, 10],
    //         }),
    //       });
    //       this.dotObject2.addTo(this.map);
    //       this.dotObject2.on("click", () => {
    //         this.$emit("click");
    //       });
    //     }
    //   },
    // },
    selectedTrigger: {
      immediate: true,
      handler(value) {
        if (!this.map) {
          return false;
        }

        if (!this.selected) {
          return false;
        }

        // console.log(this.item);
      },
    },
  },
  render() {
    return null;
  },
  beforeDestroy() {
    if (this.mapObject) {
      this.mapObject.remove();
    }
    if (this.dotObject1) {
      this.dotObject1.remove();
    }
    if (this.dotObject2) {
      this.dotObject2.remove();
    }
  },
};
</script>
