export function getTime (minute, format) {
  minute = Math.round(minute)
  const time = ('0' + Math.floor(minute / 60)).slice(-2) + ':' + ('0' + (minute % 60)).slice(-2) + ':00'
  if (!format) return time
  return moment(time, 'HH:mm:ss').format(format)
}

export function getMinute (time) {
  const ar = (time || '').trim().split(/:/)
  return Math.floor(ar[0] * 60) + Math.floor(ar[1])
}

export function sortByStr (str1, str2) {
  if (str1 < str2) { return -1 }
  if (str1 > str2) { return 1 }
  return 0
}

export function capitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
