<template>
  <div class="fc-map__page -with-header -full-width">
    <div class="fc-map__page-header">
      <v-row align="center">
        <v-col>
          <h1 class="title">{{ $t('menu.reports') }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn :to="{name: 'main'}" icon exact>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="fc-map__page-body"  style="overflow: unset">
      <div class="body-1">
        <v-card-text>
        <v-row>
          <v-col
            cols="10"
            >
            <fields v-model="instance" :fields="fields" />
          </v-col>
          <v-col
            cols="2"
            >
            <v-card-actions class="px-6 pb-4">
              <v-spacer />
              <v-btn :loading="loading" color="primary" @click="find">{{ $t('actions.find') }}</v-btn>
              <v-btn :loading="loading" color="success" v-if="isAdmin" @click="exportToXls">XLS</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        </v-card-text>
      </div>
    </div>
    <template>
      <div class="fc-map__page-body pb-3">
          <v-data-table
            :headers="headers"
            :items="items"
          >
            <template v-slot:item="{item}">
              <tr>
                <td v-for="header in headers" :key="header.value" nowrap>
                  <template>
                    {{ getItemValue(item, header) }}
                  </template>
                </td>
              </tr>
              
            </template>
            
            <template v-if="items" slot="body.append">
              <!-- <tr>
                <td>Итого</td>
                <td v-if="isAdmin"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td nowrap>{{ totals.ha }} Га</td>
                <td></td>
                <td nowrap>{{ totals.flow }} м³</td>
                <td></td>
              </tr> -->
              <tr>
                <td colspan="12" style="border-width:2px;height: 1px;"></td>
              </tr>
              <tr>
                <td colspan="12" style="border-width:2px;height: 1px;"></td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">Движения без воды (Сухой след)</td>
                <td colspan="1" style="border-right:1px solid">{{totals['Общее время движения без воды']}}</td>

                <td colspan="4" style="border-right:1px solid;">Площадь сухого следа</td>
                <td colspan="1" style="border-right:1px solid;" nowrap>{{totals['Площадь сухого следа']}}</td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">Общее время движения с водой</td>
                <td colspan="1" style="border-right:1px solid">{{totals['Общее время движения с водой']}}</td>

                <td colspan="4" style="border-right:1px solid">Площадь мокрого следа с водой</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Площадь мокрого следа с водой']}}</td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">Общее время движения с удобрениями</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Общее время движения с удобрениями']}}</td>

                <td colspan="4" style="border-right:1px solid">Площадь мокрого следа с удобрениями</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Площадь мокрого следа с удобрениями']}}</td>
                
              </tr>
              <!-- <tr>
                <td colspan="4" style="border-right:1px solid"></td>
                <td colspan="3" style="border-right:1px solid">Общая площадь</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Общая площадь']}}</td>
              </tr> -->
              <tr>
                <td colspan="3" style="border-right:1px solid">Общий расход воды</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Общий расход воды']}}</td>

                <td colspan="4" style="border-right:1px solid; font-weight: bold;">Общий пройденный путь</td>
                <td colspan="1" style="border-right:1px solid; font-weight: bold;" nowrap>{{totals['Общий пройденный путь']}}</td>
              </tr>
              <tr>
                <td colspan="9" style="border-width:1px;height: 1px;"></td>
              </tr>
              <tr>
                <td colspan="9" style="border-width:1px;height: 1px;"></td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">Остановка для обслуживания</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Остановка для обслуживания']}}</td>

                <td colspan="4" style="border-right:1px solid">Движение прекращено</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Движение прекращено']}}</td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">Ошибка выравнивания</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Ошибка выравнивания']}}</td>

                <td colspan="4" style="border-right:1px solid">Восстановление давления</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Восстановление давления']}}</td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">Ошибка низкого давления</td>
                <td colspan="1" style="border-right:1px solid">{{totals['Ошибка низкого давления']}}</td>
                
                <td colspan="4" style="border-right:1px solid">Задержка ожидания давления</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Задержка ожидания давления']}}</td>
              </tr>
              <tr>
                <td colspan="3" style="border-right:1px solid">Нет связи</td>
                <td colspan="1" style="border-right:1px solid" nowrap>{{totals['Нет связи']}}</td>

                <td colspan="4" style="border-right:1px solid; font-weight: bold;">Общее время простоя</td>
                <td colspan="1" style="border-right:1px solid; font-weight: bold;" nowrap>{{totals['Общее время простоя']}}</td>
              </tr>
            </template>  
          </v-data-table>
          
        </div>
    </template>
    
    <div class="fc-map__page-footer" />
  </div>
</template>
<style>
.v-data-table tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>
<script>
import generalFormMixin from '~/components/mixins/generalFormMixin'
// import ReportItems from '~/components/report/ReportItems'
import configFields from '~/config/fields'

export default {
  mixins: [generalFormMixin],
  data () {
    return {
      items: [],
      header: [],
      totals: [],
      instance: {
        date_from: null,
        date_to: null,
        time_from: null,
        time_to: null
      }
    }
  },
  computed: {
    isAdmin () { return this.$store.getters['auth/isAdmin'] },
    fields () {
      return configFields.reportFilter
      .filter(field => {
          if (!field.permission) { return true }
          return (this.$store.state.auth.user.permissions || []).includes(field.permission)
        })
    },
    headers () {
      var header = [
          {text: this.$t('report.pivot'), value: 'rtu'},
        ]

        if (this.isAdmin)
          header.push({text: this.$t('report.organization'), value: 'organization'})

        header.push(
          {text: this.$t('report.date'), value: 'date', units: ''},
          {text: this.$t('report.status'), value: 'status', units: ''},
          {text: this.$t('report.field'), value: 'field', units: ''},
          {text: this.$t('report.position'), value: 'position_u', units: 'м'},
          {text: this.$t('report.passed'), value: 'distance_u', units: ''},
          {text: this.$t('report.speed'), value: 'speed_u', units: ''},
          {text: this.$t('report.depth'), value: 'depth_u', units: ''},
          {text: this.$t('report.ha'), value: 'ha_u', units: ''},
          {text: this.$t('report.bar'), value: 'bar_u', units: ''},
          {text: this.$t('report.flow'), value: 'flow_u', units: ''},
          {text: this.$t('report.duration'), value: 'duration_human', units: ''}
          )
      return header
    }
  },
  created () {
    this.loadItems()
  },
  methods: {
    getItemValue (item, header) {
      const pathParts = (header.field || header.value || header.units || '').split('.')
      let result = item
      for (let i = 0; i < pathParts.length; i++) {
        if (result && Object.prototype.hasOwnProperty.call(result, pathParts[i])) {
          result = result[pathParts[i]]
        } else {
          return header.emptyPlaceholder || null
        }
      }
      return result || header.emptyPlaceholder
    },
    loadItems (force) {
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'newReport', force}))
        .then((response) => {
          this.items = response.data.items
          this.totals = response.data.total
          if (this.items?.length) {
            this.instance.from = moment(this.items[this.items.length - 1].date, 'DD.MM.YYYY HH:I').format('YYYY-MM-DD')
            this.instance.to = moment(this.items[0].date, 'DD.MM.YYYY HH:I').format('YYYY-MM-DD')

            
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    find () {
      this.setLoading(true)
      const data = this.prepareFormSubmitData(this.instance, this.fields)
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'newReport', data}))
        .then((response) => {
          this.items = response.data.items
          this.totals = response.data.total
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    exportToXls () {
      this.setLoading(true)
      const data = this.prepareFormSubmitData(this.instance, this.fields)
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'exportToXls', data}, {responseType: 'blob'}))
        .then((response) => {
          if (response.data) {
            const link = document.createElement('a');
            link.href = "https://api.fieldconnect.ru/reports/" + response.data
            link.setAttribute('download', response.data);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
