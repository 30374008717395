<template>
  <v-switch
    v-model="innerValue"
    :error="!!(errors || []).length"
    :error-messages="errors || []"
    v-bind="switchOptionsComputed"
  />
</template>

<script>
export default {
  props: ['value', 'label', 'errors', 'switchOptions'],
  data () {
    return {
      innerValue: this.value
    }
  },
  computed: {
    switchOptionsComputed () {
      return {
        color: 'success',
        hideDetails: true,
        label: this.label,
        ...this.switchOptions
      }
    }
  },
  watch: {
    value (value) {
      this.innerValue = value
    },
    innerValue (value) {
      this.$emit('update:errors', null)
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }
}
</script>
