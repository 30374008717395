<template>
  <div class="highcharts-wrapper" v-if="showChart">
    <v-btn icon @click="fullScreen" class="fullscreen-icon">
      <v-icon>mdi-overscan</v-icon>
    </v-btn>
    <highcharts v-if="showChart" :options="options" ref="highcharts"/>
  </div>
</template>


<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    },
    provider: {
      type: Number,
      default: null
    },
    reversed: {
      type: Boolean,
      default: false
    },
    dates: {
      type: Array,
      default: () => []
    },
    soil: {
      type: Object,
      default: null
    }
  },
  computed: {
    showChart () {
      return this.data && !_.isEmpty(this.data) && this.provider
    },
    options () {
      if (!this.showChart) {
        return null
      }
      const maxValue = Math.max(...$.map(this.data, val => val))
      // const minValue = Math.min(...$.map(this.data, val => val))
      const yAxisMax = this.provider === 1 ? 100 : null
      const yAxisMin = this.provider === 1 ? 0 : this.soil.wilting_point == 45 ? 30 : null
      // const yAxisMin = this.soil.wilting_point == 45 ? 30 : null
      // const yAxisMax = 100
      // const yAxisMin = 0
      const blueFill = this.soil.wilting_point < 45 ? maxValue + maxValue * 0.15 : 100
      const out = {
        exporting: {
          enabled: false,
        },
        chart: {
          type: '',
          spacingLeft: 0,
          alignTicks: false,
          marginTop: 50,
          height: 500
        },
        credits: {
          enabled: false
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          categories: this.dates,
          crosshair: {
            width: 1,
            color: 'red'
          },
          labels: {
            rotation: -45,
          }
        },
        yAxis: [{
          max: yAxisMax,
          min: yAxisMin,
          tickInterval: 20,
          startOnTick: false,
          endOnTick: false,
          title: {
            text: this.$t('charts.watermark.soil_wet') + ', %',
            margin: 15
          },
          labels: {
            enabled: true
          },
          gridLineWidth: 0.5,
          reversed: this.reversed,
        }],
        plotOptions: {
          series: {
            animation: false,
            pointWidth: 0,
            marker: {
              symbol: 'circle',
              enabled: false
            },
          },
        },
        tooltip: {
          shared: true,
          formatter: function () {
            var s = '<b>' + this.x + '</b>';

            $.each(this.points, function () {
              s += '<br/><span style="color:' + this.series.color + '">\u25CF</span> ' + this.series.name + ': ' +
                this.y + '%';
            });

            return s;
          },
        },
        legend: {
          enabled: true
        },
        series: []                      
      }

      if (this.provider === 10) {
        out.series.push(
          // Orange
          {
            yAxis: 0,
            zIndex: 2,
            type: 'areaspline',
            fillColor: 'rgba(255,228,186)',
            lineWidth: 0,
            data: Array(this.dates.length).fill(100),
            enableMouseTracking: false,
            showInLegend: false,
            marker: {
              enabled: false
            },
          },
          // Green
          {
            yAxis: 0,
            zIndex: 3,
            type: 'areaspline',
            fillColor: 'rgba(178,217,191)',
            lineWidth: 0,
            data: Array(this.dates.length).fill(90),
            enableMouseTracking: false,
            showInLegend: false,
            marker: {
              enabled: false
            },
          },
          // Blue
          {
            yAxis: 0,
            zIndex: 4,
            type: 'areaspline',
            fillColor: 'rgb(85,185,231)',
            lineWidth: 0,
            data: Array(this.dates.length).fill(7),
            enableMouseTracking: false,
            showInLegend: false,
            marker: {
              enabled: false
            },
          }
        )
      } else if (this.provider === 1 || this.provider === 2) {

        out.series.push(
          // Blue
          {
            yAxis: 0,
            zIndex: 2,
            type: 'areaspline',
            fillColor: '#55b9e7',
            lineWidth: 0,
            data: Array(this.dates.length).fill(blueFill),
            // data: Array(this.dates.length).fill(out.yAxis[0].max),
            enableMouseTracking: false,
            showInLegend: false,
            marker: {
              enabled: false
            },
          },
          // Green
          {
            yAxis: 0,
            zIndex: 3,
            type: 'areaspline',
            fillColor: '#b2d9bf',
            lineWidth: 0,
            // data: Array(this.dates.length).fill(maxValue - maxValue * 0.05), 
            data: Array(this.dates.length).fill(this.soil.stop_watering), 
            enableMouseTracking: false,
            showInLegend: false,
            marker: {
              enabled: false
            },
          },
          // Yellow
          {
            yAxis: 0,
            zIndex: 4,
            type: 'areaspline',
            fillColor: '#ffe4ba',
            lineWidth: 0,
            // data: Array(this.dates.length).fill(minValue + maxValue * 0.02),
            data: Array(this.dates.length).fill(this.soil.start_watering),
            enableMouseTracking: false,
            showInLegend: false,
            marker: {
              enabled: false
            },
          },
          // Orange
          {
            yAxis: 0,
            zIndex: 4,
            type: 'areaspline',
            fillColor: '#EEACA0',
            lineWidth: 0,
            // data: Array(this.dates.length).fill(minValue + maxValue * 0.02),
            data: Array(this.dates.length).fill(this.soil.wilting_point),
            enableMouseTracking: false,
            showInLegend: false,
            marker: {
              enabled: false
            },
          }
        )
      }        

// TODO перенести в цикл
      if (this.data[10] !== undefined) {
        out.series.push(
          // 10cm
          {
            yAxis: 0,
            zIndex: 5,
            data: this.data[10],
            type: 'spline',
            lineColor: '#b2b224',
            color: '#b2b224',
            lineWidth: 1,
            name: '10 ' + this.$t('charts.units.cm'),
            marker: {
              symbol: 'circle',
              enabled: true
            }
          }
        )
      }
     
     if (this.data[20] !== undefined) {
        out.series.push(
          // 10cm
          {
            yAxis: 0,
            zIndex: 5,
            data: this.data[20],
            type: 'spline',
            lineColor: '#B2242C',
            color: '#B2242C',
            lineWidth: 1,
            name: '20 ' + this.$t('charts.units.cm'),
            marker: {
              symbol: 'circle',
              enabled: true
            }
          }
        )
      }
     
      if (this.data[25] !== undefined) {
        out.series.push(
          // 25cm
          {
            yAxis: 0,
            zIndex: 5,
            data: this.data[25],
            type: 'spline',
            lineColor: '#B2242C',
            color: '#B2242C',
            lineWidth: 1,
            name: '25 ' + this.$t('charts.units.cm'),
            marker: {
              symbol: 'circle',
              enabled: true
            }
          }
        )
      }

      if (this.data[30] !== undefined) {
        out.series.push(
          // 30cm
          {
            yAxis: 0,
            zIndex: 5,
            data: this.data[30],
            type: 'spline',
            lineColor: '#b12eb2',
            color: '#b12eb2',
            lineWidth: 1,
            name: '30 ' + this.$t('charts.units.cm'),
            marker: {
              symbol: 'circle',
              enabled: true
            }
          }
        )
      }

      if (this.data[40] !== undefined) {
        out.series.push(
          // 40cm
          {
            yAxis: 0,
            zIndex: 5,
            data: this.data[40],
            type: 'spline',
            lineColor: '#00ccff',
            color: '#00ccff',
            lineWidth: 1,
            name: '40 ' + this.$t('charts.units.cm'),
            marker: {
              symbol: 'circle',
              enabled: true
            }
          }
        )
      }

      if (this.data[50] !== undefined) {
        out.series.push(
          // 50cm
          {
            yAxis: 0,
            zIndex: 5,
            data: this.data[50],
            type: 'spline',
            lineColor: '#009900',
            color: '#009900',
            lineWidth: 1,
            name: '50 ' + this.$t('charts.units.cm'),
            marker: {
              symbol: 'circle',
              enabled: true
            }
          }
        )
      }
      
      if (this.data[60] !== undefined) {
        out.series.push(
          // 60cm
          {
            yAxis: 0,
            zIndex: 5,
            data: this.data[60],
            type: 'spline',
            lineColor: '#3232b2',
            color: '#3232b2',
            lineWidth: 1,
            name: '60 ' + this.$t('charts.units.cm'),
            marker: {
              symbol: 'circle',
              enabled: true
            }
          }
        )
      }
      
      if (this.data[70] !== undefined) {
        out.series.push(
          // 70cm
          {
            yAxis: 0,
            zIndex: 5,
            data: this.data[70],
            type: 'spline',
            lineColor: '#3232b2',
            color: '#3232b2',
            lineWidth: 1,
            name: '70 ' + this.$t('charts.units.cm'),
            marker: {
              symbol: 'circle',
              enabled: true
            }
          }
        )
      }
      
      if (this.data[90] !== undefined) {
        out.series.push(
          // 90cm
          {
            yAxis: 0,
            zIndex: 5,
            data: this.data[90],
            type: 'spline',
            lineColor: '#B2242C',
            color: '#B2242C',
            lineWidth: 1,
            name: '90 ' + this.$t('charts.units.cm'),
            marker: {
              symbol: 'circle',
              enabled: true
            }
          }
        )
      }

      return out
    }
  },
  methods: {
    fullScreen() {
      this.$refs.highcharts.chart.fullscreen.toggle()
    },
  }
}
</script>

