<template>
  <div class="fc-map__page -full-height -outer-corners meteo-tabs">
    <v-tabs :value="tab" grow dark show-arrows background-color="grey darken-2" class="fc-map__page-tabs-container"
      @change="setTab">
      <v-tabs-slider />
      <v-tab v-for="tab in tabs" :key="tab">
        <span class="hidden-xs-only">
          {{ tab }}
        </span>
        <v-icon class="hidden-sm-and-up">
          mdi-rotate-left
        </v-icon>
      </v-tab>

      <v-tab-item>
        <summary-watermark-list />
      </v-tab-item>

      <v-tab-item>
        <summary-meteo-list />
      </v-tab-item>

    </v-tabs>






  </div>
</template>

<style>
.meteo-tabs .fc-grid-body__scroll {
  position: relative !important;
}
</style>
<script>

import SummaryWatermarkList from '~/components/chart/SummaryWatermarkList'
import SummaryMeteoList from '~/components/chart/SummaryMeteoList'

export default {
  components: {
    SummaryWatermarkList,
    SummaryMeteoList
  },
  computed: {
    tab() { return Number(this.$route.query.tab) || 0 },
    tabs() { return [this.$t('charts.watermark.tab'), this.$t('charts.meteo.tab')] },
  },
  watch: {
    instanceTrigger: {
      immediate: true,
      handler() {
        const instance = {}
        const loading = {}


        this.instance = instance
        this.loading = loading
      }
    }
  },
  beforeDestroy() {
    this._timeout && clearTimeout(this._timeout)
  },
  methods: {
    setTab(tab) {
      this.$router.push({ name: 'meteos', query: { id: this.id, tab } })
    },
  }
}
</script>
