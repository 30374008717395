<template>
    <div v-if="show" class="fc-map__page-body" style="overflow: unset">
        <div class="body-1">
            <v-card-text>
                <div class="title mb-2">Прогноз на {{periodDays}} дней</div>
                <table class="fc-map__table table_meteo_forecast">
                    <thead>
                        <tr>
                            <th rowspan="2">
                                
                            </th>
                            <th v-for="(item, date) in data['daily']" :key="date" style="text-align: center;">
                                <div>{{ dayName(date) }}</div>
                                <div>{{ formatedDate(date) }}</div>
                            </th>
                        </tr>
                        <tr>
                            <td v-for="(item, index) in data['daily']" :key="index">
                                <v-list-item-icon class="mr-3">
                                    <img :src="require('~/assets/icons/icon-weather-' + icons[data['daily'][index].weather_code] + '.svg')"
                                        class="fc-map__table-icon">
                                </v-list-item-icon>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td v-for="(item, index) in data['daily']" :key="index" style="vertical-align: top;"><div style="margin-top: -10px; line-height: 12px;">{{$t('weatherCode.' + icons[data['daily'][index].weather_code])}}</div></td>
                        </tr>
                    </thead>
                    <template v-if="fullscreen">
                        <tbody>
                            <!-- <tr v-for="(item1, field) in data['daily'][Object.keys(data['daily'])[0]]" :key="field">
                                <th>{{$t('forecastData.' + field)}}</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index][field] }}
                                </td>
                            </tr> -->
                            <tr>
                                <th>t°C max</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].temperature_2m_max }}°C
                                </td>
                            </tr>
                            <tr>
                                <th>t°C max, Ощущается как</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].apparent_temperature_max }}°C
                                </td>
                            </tr>
                            <tr>
                                <th>t°C min</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].temperature_2m_min }}°C
                                </td>
                            </tr>
                            <tr>
                                <th>t°C min, Ощущается как</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].apparent_temperature_min }}°C
                                </td>
                            </tr>
                            <tr>
                                <th>Ветер</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ (data['daily'][index].wind_speed_10m_max).toFixed(1) }} {{ $t('charts.units.ms') }}
                                </td>
                            </tr>
                            <tr>
                                <th>Порывы до</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ (data['daily'][index].wind_gusts_10m_max).toFixed(1) }} {{ $t('charts.units.ms') }}
                                </td>
                            </tr>
                            <tr>
                                <th>Направление</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ windDirection(data['daily'][index].wind_direction_10m_dominant) }}
                                </td>
                            </tr>
                            <tr>
                                <th>Осадки</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].precipitation_sum }} {{ $t('charts.units.mm') }}
                                </td>
                            </tr>
                            <tr>
                                <th>Вероятность осадков</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].precipitation_probability_max }}%
                                </td>
                            </tr>
                            <tr>
                                <th>Солнечная радиация, МДж/м²</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].shortwave_radiation_sum }}
                                </td>
                            </tr>
                            <tr>
                                <th>Эвапотранспирация</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].et0_fao_evapotranspiration }} {{ $t('charts.units.mm') }}
                                </td>
                            </tr>
                            <tr>
                                <th>Восход</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ formatedTime(data['daily'][index].sunrise) }}
                                </td>
                            </tr>
                            <tr>
                                <th>Закат</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ formatedTime(data['daily'][index].sunset) }}
                                </td>
                            </tr>
                            <tr>
                                <th>Длительность дня</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ dayDuration(data['daily'][index].daylight_duration) }}
                                </td>
                            </tr>
                            <tr>
                                <th>УФ-индекс</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].uv_index_max }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template v-else>
                        <tbody>
                            <tr>
                                <th>t°C max</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].temperature_2m_max }}°C
                                </td>
                            </tr>
                            <tr>
                                <th>t°C min</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].temperature_2m_min }}°C
                                </td>
                            </tr>
                            <tr>
                                <th>Ветер</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ (data['daily'][index].wind_speed_10m_max).toFixed(1) }} {{ $t('charts.units.ms') }}
                                </td>
                            </tr>
                            <tr>
                                <th>Осадки</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].precipitation_sum }} {{ $t('charts.units.mm') }}
                                </td>
                            </tr>
                            <tr>
                                <th>Вероятность</th>
                                <td v-for="(item, index) in data['daily']" :key="index">
                                    {{ data['daily'][index].precipitation_probability_max }}%
                                </td>
                            </tr>
                            
                        </tbody>
                    </template>
                </table>
                <v-btn icon @click="fullScreenOn" v-if="!fullscreen" class="fullscreen-icon">
                    <v-icon>mdi-overscan</v-icon>
                </v-btn>
                <v-btn icon @click="fullScreenExit" v-if="fullscreen" class="fullscreen-icon">
                    <v-icon>mdi-fullscreen-exit</v-icon>
                </v-btn>
                <!-- <div style="font-size: 80%; text-align: right; margin-right: 5px;">
                    Источник данных: open-meteo.com
                </div> -->
            </v-card-text>
        </div>
    </div>
</template>

<style>
.table_meteo_forecast {
    font-size: 12px;
}

.table_meteo_forecast th {
    padding: 3px 5px;
    text-align: left;
}

.table_meteo_forecast td {
    padding: 3px 5px;
    text-align: center;
}

/* .table_meteo_forecast thead > tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .09);
 } */

.table_meteo_forecast tbody>tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .05);
}

.table_meteo_forecast .v-list-item__icon {
    margin: 0;
}

.table_meteo_forecast .fc-map__table-icon {
    width: 50px;
    height: 50px;
}

.table_meteo_forecast .current .temp {
    font-size: 20px;
}

.table_meteo_forecast .v-list-item__icon {
    margin: 0 auto;
    margin-right: unset !important;
}
</style>

<script>
import moment from 'moment';

export default {
    props: ['data', 'fullscreen'],
    computed: {
        id() { return this.$route.query.id },
        show() {
            return this.data && !_.isEmpty(this.data);
        },
        periodDays() {
            return this.fullscreen ? 14 : 7
        },
        serverSettings() { return this.$store.state.ux.serverSettings },
        icons() {
            return {
                0: 'clear-sky', //
                1: 'mainly-clear', //
                2: 'partly-cloudy', //
                3: 'overcast', //
                45: 'fog', //
                48: 'clear-sky', //depositing rime fog
                51: 'drizzle', //
                53: 'drizzle', //
                55: 'drizzle', //
                56: 'freezing-drizzle', //
                57: 'freezing-drizzle', //
                61: 'rain-slight',//
                63: 'rain-moderate',//
                65: 'rain-heavy-intensity',//
                66: 'freezing-rain-light',//
                67: 'freezing-rain-heavy-intensity',//
                71: 'snow-slight',//
                73: 'snow-fall-moderate',//
                75: 'snow-fall-heavy-intensity',//
                77: 'snow-grains',//
                80: 'rain-showers-slight',//
                81: 'rain-showers-moderate',//
                82: 'rain-showers-heavy-intensity',//
                85: 'snow-showers-slight',//
                86: 'snow-showers-heavy',//
                95: 'thunderstorm',//
                96: 'thunderstorm-slight-hail',//
                99: 'thunderstorm-heavy-hail',//
            }
        },
        
    },
    methods: {
        dayDuration(value) {
            let h = Math.floor(value / 3600)
            let m = Math.floor((value - h * 3600) / 60)
            return String(h) + ':' + String(m);
        },
        dayName(value) {
            let date = moment(value).format('llll')
            date = date.split(',')
            return date[0]
        },
        formatedDate(value) {
            let date = moment(value).format('llll')
            date = date.split(',')
            date = date[1].split('.')
            return date[0].trim()
        },
        formatedTime(value) {
            let date = moment(value).add(3, 'hours').format('HH:mm')
            return date
        },
        windDirection(degree) {
            let compas = 'Север';

            if (degree > 348.75 && degree <= 360) {
                compas = 'Север';
            } else if (degree >= 0 && degree <= 11.25) {
                compas = 'Север';
            } else if (degree > 11.25 && degree <= 33.75) {
                compas = 'ССВ';
            } else if (degree > 33.75 && degree <= 56.25) {
                compas = 'СВ';
            } else if (degree > 56.25 && degree <= 78.75) {
                compas = 'ВСВ';
            } else if (degree > 78.75 && degree <= 101.25) {
                compas = 'Восток';
            } else if (degree > 101.25 && degree <= 123.75) {
                compas = 'ВЮВ';
            } else if (degree > 123.75 && degree <= 146.25) {
                compas = 'ЮВ';
            } else if (degree > 146.25 && degree <= 168.75) {
                compas = 'ЮЮВ';
            } else if (degree > 168.75 && degree <= 191.25) {
                compas = 'Юг';
            } else if (degree > 191.25 && degree <= 213.75) {
                compas = 'ЮЮЗ';
            } else if (degree > 213.75 && degree <= 236.25) {
                compas = 'ЮЗ';
            } else if (degree > 236.25 && degree <= 258.75) {
                compas = 'ЗЮЗ';
            } else if (degree > 258.75 && degree <= 281.25) {
                compas = 'Запад';
            } else if (degree > 281.25 && degree <= 303.75) {
                compas = 'ЗСЗ';
            } else if (degree > 303.75 && degree <= 326.25) {
                compas = 'СЗ';
            } else if (degree > 326.25 && degree <= 348.75) {
                compas = 'ССЗ';
            }

            return compas
        },
        // __windDirection(degree) {
        //     let compas = 'ССВ'
            
        //     if (degree >= 0 && degree <= 22.5) {
        //         compas = 'ССВ';
        //     } else if (degree > 22.5 && degree <= 45) {
        //         compas = 'СВ';
        //     } else if (degree > 45 && degree <= 67.5) {
        //         compas = 'ВСВ';
        //     } else if (degree > 67.5 && degree <= 90) {
        //         compas = 'Восток';
        //     } else if (degree > 90 && degree <= 112.5) {
        //         compas = 'ВЮВ';
        //     } else if (degree > 112.5 && degree <= 135) {
        //         compas = 'ЮВ';
        //     } else if (degree > 135 && degree <= 157.5) {
        //         compas = 'ЮЮВ';
        //     } else if (degree > 157.5 && degree <= 180) {
        //         compas = 'Юг';
        //     } else if (degree > 180 && degree <= 202.5) {
        //         compas = 'ЮЮЗ';
        //     } else if (degree > 202.5 && degree <= 225) {
        //         compas = 'ЮЗ';
        //     } else if (degree > 225 && degree <= 247.5) {
        //         compas = 'ЗЮЗ';
        //     } else if (degree > 247.5 && degree <= 270) {
        //         compas = 'Запад';
        //     } else if (degree > 270 && degree <= 292.5) {
        //         compas = 'ЗСЗ';
        //     } else if (degree > 292.5 && degree <= 315) {
        //         compas = 'СЗ';
        //     } else if (degree > 315 && degree <= 337.5) {
        //         compas = 'ССЗ';
        //     } else if (degree > 337.5 && degree <= 360) {
        //         compas = 'Север';
        //     }

        //     return compas
        // },
        fullScreenOn() {
            this.$router.push({ name: 'forecast', query: { id: this.id } })
        },
        fullScreenExit() {
            this.$router.push({ name: 'meteo', query: { id: this.id } })
        }
    }
}
</script>