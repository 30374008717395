import Vue from 'vue'
import Alert from '~/components/utils/Alert'
import AnimHeight from '~/components/utils/AnimHeight'
import ClickOutside from '~/components/utils/ClickOutside'
import Snack from '~/components/utils/Snack'
import FieldControl from '~/components/controls/FieldControl'
import Fields from '~/components/controls/Fields'

Vue.component('Alert', Alert)
Vue.component('AnimHeight', AnimHeight)
Vue.component('ClickOutside', ClickOutside)
Vue.component('Snack', Snack)
Vue.component('FieldControl', FieldControl)
Vue.component('Fields', Fields)
