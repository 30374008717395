<template>
  <login-card :show-logo="true" width="400" />
</template>

<script>
import LoginCard from '~/components/auth/LoginCard'

export default {
  components: {LoginCard}
}
</script>
