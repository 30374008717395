<template>
  <div class="fc-map__page-body">
    <div class="body-1 my-3">
      <v-alert v-if="!instance.length" type="info" outlined>
        {{ $t('success.noEndGuns') }}
      </v-alert>
      <template v-else>
        <v-card v-for="(gun, index) in instance" :key="gun.id" class="my-3 elevation-1">
          <v-card-title>
            <v-row>
              <v-col class="title">
                {{ $t('endGun.title') + ' ' + (index + 1) + ' (' + gun.distance + $t('endGun.suffix') + ')' }}
              </v-col>
              <v-col cols="auto">
                <field-control v-model="gun.status" :disabled="!editingInstance" :field="fieldsRev.status"
                  class="ma-0" />
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-alert v-if="!gun.areas || !gun.areas.length" type="info" outlined>
              {{ $t('endGun.noAreas') }}
            </v-alert>
            <table v-if="gun.areas && gun.areas.length" class="fc-map__table">
              <thead>
                <tr>
                  <th>
                    {{ $t('form.gunIndex') }}
                  </th>
                  <th>
                    {{ $t(type === 'lateral' ? 'form.gunStartLateral' : 'form.gunStart') }}
                  </th>
                  <th>
                    {{ $t(type === 'lateral' ? 'form.gunEndLateral' : 'form.gunEnd') }}
                  </th>
                  <th v-if="editingInstance" class="fc-map__table-cell -remove-control" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(area, index) in gun.areas" :key="area.id" class="fc-map__table-row">
                  <td class="fc-map__table-cell">
                    {{ index + 1 }}
                  </td>
                  <td class="fc-map__table-cell">
                    <field-control v-model="area.startAngle" :disabled="!editingInstance"
                      :field="fieldsRev.startAngle" />
                  </td>
                  <td class="fc-map__table-cell">
                    <field-control v-model="area.endAngle" :disabled="!editingInstance" :field="fieldsRev.endAngle" />
                  </td>
                  <td v-if="editingInstance" class="fc-map__table-cell -remove-control">
                    <v-btn icon @click="removeEndGunArea(gun, index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="gun.areas.length < maxGunAreasCount && editingInstance" class="d-flex justify-end ma-2">
              <v-btn icon @click="addEndGunArea(gun)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-card v-if="isFC">
          <v-card-text>
            <table class="fc-map__table">
              <tbody>
                <tr class="fc-map__table-row">
                  <td class="fc-map__table-cell">
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="5">
                        <v-text-field 
                          v-model="instance[0].delay"
                          label="Задержка запуска"
                          :disabled="!editingInstance" 
                          :field="gunStartDelayField"
                          hide-details
                          class="ma-0"
                          :suffix="$t('form.timeSuffixSec')" 
                          type="number"
                          hide-spin-buttons
                          />
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </template>
    </div>
    <v-row class="mt-3">
      <v-col class="d-flex flex-wrap justify-end">
        <template v-if="editingInstance">
          <v-btn :loading="loading" text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
          <v-btn :loading="loading" color="primary" @click="save">{{ $t('actions.save') }}</v-btn>
        </template>
        <template v-else>
          <v-btn :loading="loading" class="mr-3" text @click="request">{{ $t('actions.request') }}</v-btn>
          <v-btn text @click="start">{{ $t('actions.edit') }}</v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
<script>
import configFields from '~/config/fields'
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  props: ['id', 'tab'],
  mixins: [generalFormMixin],
  data() {
    return {
      instance: [],
      editingInstance: false,
      maxGunAreasCount: 8
    }
  },
  computed: {
    item() { return this.items.find(item => item.id === this.id) },
    isFC() {
      if (this.item.manufacturer === "FC") {
        return true;
      } else {
        return false;
      }
    },
    type() { return this.item?.displaySettings?.type || 'circle' },
    fields() { return configFields.gun },
    fieldsRev() {
      return this.fields.reduce((out, field) => {
        out[field.key] = field
        return out
      }, {})
    },
    gunStartDelayField() { return configFields.gun.delay },
    items() { return this.$store.getters['rtu/items'] },
    updateInstanceTrigger() {
      return JSON.stringify({ id: this.id, item: this.item })
    },
    resetEditingInstanceTrigger() {
      return this.id + this.tab
    }
  },
  watch: {
    updateInstanceTrigger: {
      immediate: true,
      handler() {
        if (this.editingInstance) { return false }
        this.instance = this.prepareEndGunsInstance(this.item)
      }
    },
    resetEditingInstanceTrigger: 'cancel'
  },
  methods: {
    prepareEndGunsInstance(item) {
      if (item && item.deviceSettings && item.deviceSettings.endGuns && item.deviceSettings.endGuns.length) {
        const instance = _.cloneDeep(item.deviceSettings.endGuns)
        instance.forEach((gun) => {
          if (!gun.areas) {
            gun.areas = []
          } else {
            gun.areas = gun.areas.map(area => ({
              startAngle: parseFloat(area.startAngle),
              endAngle: parseFloat(area.endAngle),
              start: parseInt(area.start),
              end: parseInt(area.end),
              id: _.uniqueId()
            }))
          }
        })
        return instance
      } else {
        return []
      }
    },
    prepareEndGunsFormData(data) {
      if (!data || !data.length) { return [] }
      data = _.cloneDeep(data)
      data.forEach((gun) => {
        if (gun.areas && !gun.areas.length) {
          delete gun.areas
        } else {
          gun.areas = gun.areas.map(area => ({
            startAngle: area.startAngle.toString(),
            endAngle: area.endAngle.toString()
          }))
        }
      })
      return data
    },
    addEndGunArea(gun) {
      if (!gun.areas) {
        gun.areas = []
      }
      gun.areas.push({
        id: _.uniqueId(),
        startAngle: 0,
        endAngle: 0
      })
    },
    removeEndGunArea(gun, index) {
      if (gun.areas && gun.areas.length) {
        gun.areas.splice(index, 1)
      }
    },
    start() {
      this.editingInstance = true
    },
    save() {
      this.setLoading(true)
      const data = this.prepareEndGunsFormData(this.instance)
      const promises = data.map((endGun, index) =>
        setTimeout(() => {
          this.$store.dispatch('fetch/fetch', { path: 'rtu.updateEndGuns', data: { deviceId: this.id, ...endGun } })
        }, 1000 * index))
      Promise.all(promises)
        .then(() => this.$store.dispatch('rtu/fetch'))
        .then(() => {
          this.$swal({ icon: 'success', text: this.$t('success.endGunsUpdated') })
          this.cancel()
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    cancel() {
      this.instance = this.prepareEndGunsInstance(this.item)
      this.editingInstance = false
    },
    request() {
      this.setLoading(true)
      Promise.resolve()
        .then(() => new Promise((resolve, reject) => {
          this.$emit('request', {
            type: 'guns',
            callback: resolve
          })
        }))
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
