export default {
  cookieName: 'fc_settings',
  default: {
    map: {
      // center: {lat: 49.9941989381303, lng: 45.63427700772411},
      center: {lat: 48.50932644976633, lng: 45.274658203125},
      zoom: 6,
      provider: 'esri',
      hideFields: true,
      hideTitles: true,
      hidePivots: false,
      hideMeteoStations: false,
      hideWaterMeters: false,
    },
  },
  cookieOptions: {
    expires: 7,
    path: ''
  }
}
