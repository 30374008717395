<template>
  <div class="fc-map__page-body">
    <div class="body-1 my-3">
      <v-card class="my-3 elevation-1">
        <v-card-title>
          <v-row>
            <v-col class="title">
              {{ $t('barrier.title') }}
            </v-col>
            <v-col cols="auto">
                <field-control v-model="instance.status" :disabled="!editingInstance" :field="fieldsRev.status" class="ma-0" />
              </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div>
                <fields v-model="instance" :fields="fields" :disabled="!editingInstance"/>
              </div>
            </v-col>
          </v-row> 
        </v-card-text>
      </v-card>
    </div>
    <v-row class="mt-3">
      <v-col class="d-flex flex-wrap ustify-end">
        <template v-if="editingInstance">
          <v-btn :loading="loading" text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
          <v-btn :loading="loading" color="primary" @click="save">{{ $t('actions.save') }}</v-btn>
        </template>
        <template v-else>
          <v-btn :loading="loading" class="mr-3" text @click="request('barrier')">{{ $t('actions.request') }}</v-btn>
          <v-btn text @click="start">{{ $t('actions.edit') }}</v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import configFields from '~/config/fields'
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  mixins: [generalFormMixin],
  props: ['id', 'tab'],
  data () {
    return {
      instance: [],
      editingInstance: false
    }
  },
  computed: {
    fields () { return configFields.softBarrier },
    items () { return this.$store.getters['rtu/items'] },
    item () { return this.items.find(item => item.id === this.id) },
    fieldsRev () {
      return this.fields.reduce((out, field) => {
        out[field.key] = field
        return out
      }, {})
    },
    updateInstanceTrigger () {
      return JSON.stringify({id: this.id, item: this.item})
    },
  },
  watch: {
    updateInstanceTrigger: {
      immediate: true,
      handler () {
        if (this.editingInstance) { return false }
        this.instance = this.prepareSoftBarrierInstance(this.item)
      }
    },
  },
  methods: {
    prepareSoftBarrierInstance(item) {
      if (
        item &&
        item.deviceSettings &&
        item.deviceSettings.softBarrier
      ) {
        return item.deviceSettings.softBarrier
      } else {
        return []
      }
    },
    start() {
      this.editingInstance = true
    },
    save() {
      this.setLoading(true)
      const data = this.instance
      Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {
          path: 'rtu.updateSoftBarrierSettings', 
          data: {deviceId: this.id, ...data}
        }))
        .then((response) => {
          if (response.data && response.data.error) {
            throw new Error(response.data.error)
          }
        })
        .then(() => new Promise((resolve) => {
          this._timeout && clearTimeout(this._timeout)
          this._timeout = setTimeout(resolve, 1500)
        }))
        .then(() => this.$store.dispatch('fetch/fetch', {path: 'rtu.id', data: {id: this.item.id}}, {root: true}))
        .then((response) => {
          return this.$store.dispatch('rtu/updateItemId', response.data)
        })
        .then(() => {
          this.$snack({type: 'success', message: this.$t('success.barrierUpdated')})
          this.cancel()
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    },
    cancel() {
      this.instance = this.prepareSoftBarrierInstance(this.item)
      this.editingInstance = false
    },
    request (target) {
      this.setLoading(true)
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: `rtu.${target}Request`, data: {id: this.id}}))
        .then(() => this.$store.dispatch('rtu/fetch', {force: true}))
        .then(() => {
          this.$snack({type: 'success', message: this.$t(`success.${target}Requested`)})
        })
        .catch(this.handleError)
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
