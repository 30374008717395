<template>
  <v-tooltip left>
    <template v-slot:activator="{on}">
      <img :src="require('~/assets/icons/icon-connection-' + (item.deviceStatus.online ? 'on' : 'off') + '.svg')" class="fc-map__page-icon" v-on="on">
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import dateTimeFormat from '~/components/mixins/dateTimeFormat'

export default {
  mixins: [dateTimeFormat],
  props: ['item'],
  computed: {
    tooltip () {
      let str = this.$t('status.' + (this.item.deviceStatus.online ? 'online' : 'offline'))
      if (this.item.deviceStatus.lastConnect) {
        str += ` (${this.formatDateTime(this.item.deviceStatus.lastConnect)})`
      }
      return str
    }
  }
}
</script>