<template>
  <component
    :is="component"
    :value="value"
    :instance="instance"
    :errors="errors || []"
    v-bind="fieldComputed"
    v-on="listenersComputed"
  />
</template>

<script>
import configFieldOptions from '~/config/fieldOptions'
import TextControl from '~/components/controls/primitives/Text'
import NumberControl from '~/components/controls/primitives/Number'
import BooleanControl from '~/components/controls/primitives/Boolean'
import RadioControl from '~/components/controls/primitives/Radio'
import DateControl from '~/components/controls/primitives/Date'
import TimeControl from '~/components/controls/primitives/Time2'
import SelectControl from '~/components/controls/primitives/Select'

export default {
  props: ['field', 'instance', 'value', 'validationErrors', 'disabled'],
  computed: {
    errors () { return (this.validationErrors || {})[this.field.key] },
    fieldComputed () {
      const realFieldType = (configFieldOptions[this.field.type] || {}).type || this.field.type
      const out = _.merge({}, configFieldOptions[this.field.type] || {}, this.field)  
      out.type = realFieldType
      if (out.label) {
        out.label = this.$t(out.label)
      }
      if (out.inputOptions && out.inputOptions.hint) {
        out.inputOptions.hint = this.$t(out.inputOptions.hint)
      }
      if (out.inputOptions && out.inputOptions.suffix) {
        out.inputOptions.suffix = this.$t(out.inputOptions.suffix)
      }
      if (out.inputOptions && out.inputOptions.prefix) {
        out.inputOptions.prefix = this.$t(out.inputOptions.prefix)
      }
      if (out.required && out.label && out.label[out.label.length - 1] !== '*') out.label += '*'
      if (this.disabled) {
        out.inputOptions = {...(out.inputOptions || {}), disabled: this.disabled}
        out.switchOptions = {...(out.switchOptions || {}), disabled: this.disabled}
        out.selectOptions = {...(out.selectOptions || {}), disabled: this.disabled}
      }
      return out
    },
    listenersComputed () {
      return {
        ...this.$listeners,
        'update:errors': this.updateErrors
      }
    },
    component () {
      if (this.field.type === 'boolean') return BooleanControl
      if (this.field.type === 'radio') return RadioControl
      if (this.field.type === 'date') return DateControl
      if (this.field.type === 'time') return TimeControl
      if (this.field.type === 'number') return NumberControl
      if (this.field.type === 'select') return SelectControl
      return TextControl
    }
  },
  methods: {
    updateErrors (value) {
      const validationErrors = {...this.validationErrors}
      if (value) {
        validationErrors[this.field.key] = value
      } else {
        delete validationErrors[this.field.key]
      }
      this.$emit('update:validationErrors', validationErrors)
    }
  }
}
</script>
