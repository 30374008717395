<template>
  <v-menu left offset-y>
    <template v-slot:activator="{on}">
      <slot name="activator" :on="on" />
    </template>
    <v-list>
      <v-list-item v-if="isAdmin" link :to="{name: 'admin'}">
        <v-list-item-icon>
          <v-icon>mdi-shield-key</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ $t('actions.admin') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isNotificated" link :to="{name: 'notifications'}">
        <v-list-item-icon>
          <v-icon>mdi-message-badge-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ $t('actions.notifications') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item link :to="{name: 'settings'}">
        <v-list-item-icon>
          <v-icon>mdi-cogs</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ $t('actions.settings') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ $t('actions.logout') }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  computed: {
    isAdmin () { return this.$store.getters['auth/isAdmin'] },
    isNotificated () { return this.$store.getters['auth/isNotificated'] }
  },
  methods: {
    logout () {
      Promise.resolve()
        .then(() => this.$store.dispatch('auth/logout'))
        .then(() => this.$router.push('/login'))
        .then(() => this.$snack(this.$t('success.loggedOut')))
    }
  }
}
</script>
