<script>
export default {
  props: {
    data: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      offset: 20,
      width: 20,
    }
  },
  computed: {
    showChart () {
      return this.data && !_.isEmpty(this.data) && this.provider
    },
    height () {
      return this.offset*this.data.length
    },
    code () {
      let code = ''
      let y = 0
      
      this.data.forEach((prop) => {
        code += `<g>
          <rect x="0" y="${y}" width="${this.width}" height="17" fill="${prop.color}"></rect>
          <text x="2" data-z-index="1" y="${y+11}" style="color:black;font-size:8px;fill:black;">${prop.value}</text>
          </g>`  
        y = y + this.offset
      });
      
      return code
    },
    
  },
  
  render (createElement) {
      return createElement('svg', {
        attrs: {
          xmlns: 'http://www.w3.org/2000/svg',
          width: 20,
          height: this.height,
        },
        domProps: {
          innerHTML: this.code
        }
      })
    },  

}
</script>