export default {
  defaultLocale: 'ru',
  locales: [{
    key: 'en', name: 'English', icon: 'us'
  }, {
    key: 'ru', name: 'Русский', icon: 'ru'
  }],
  cookieName: 'fc_locale',
  translations: {
    menu: {
      map: {
        en: 'Map',
        ru: 'Карта'
      },
      irrigation: {
        en: 'Irrigation',
        ru: 'Орошение'
      },
      meteos: {
        en: 'Meteos',
        ru: 'Станции'
      },
      reports: {
        en: 'Reports',
        ru: 'Отчеты'
      },
      motohours: {
        en: 'Moto Hours',
        ru: 'Моточасы'
      },
      help: {
        en: 'Help',
        ru: 'Помощь'
      },
      contact: {
        en: 'Contact',
        ru: 'Контакты'
      },
      login: {
        en: 'Login',
        ru: 'Логин'
      },
      restorePassword: {
        en: 'Restore password',
        ru: 'Восстановление пароля'
      },
      settings: {
        en: 'Settings',
        ru: 'Настройки'
      },
      admin: {
        en: 'Admin Panel',
        ru: 'Администрирование'
      },
      notifications: {
        en: 'Notifications',
        ru: 'Уведомления'
      }
    },
    mainMenu: {
      settings: {
        en: 'Settings',
        ru: 'Режим работы'
      },
      map: {
        en: 'Position on the Map',
        ru: 'Положение на карте'
      },
      guns: {
        en: 'End Guns',
        ru: 'Концевые пушки'
      },
      hoseStop: {
        en: 'Hose Stop',
        ru: 'Гидранты'
      },
      areaPlan: {
        en: 'Plans',
        ru: 'План полива'
      },
      camera: {
        en: 'Camera',
        ru: 'Камера'
      },
      pivot: {
        en: 'Pivot',
        ru: 'Пивот'
      },
      systemSettings: {
        en: 'System Settings',
        ru: 'Системные настройки'
      },
      barrierSettings: {
        en: 'Barrier',
        ru: 'Барьер'
      },
      reports: {
        en: 'Reports',
        ru: 'Отчеты'
      },
      history: {
        en: 'History',
        ru: 'История'
      },
      log: {
        en: 'Log',
        ru: 'Лог'
      },
      watermark: {
        en: 'Watermark',
        ru: 'Влажность почвы'
      }
    },
    form: {
      timezone: {
        en: 'Timezone',
        ru: 'Таймзона'
      },
      dateFormat: {
        en: 'Date format',
        ru: 'Формат даты'
      },
      direction: {
        frw: {
          en: 'Clockwise',
          ru: 'По часовой стрелке'
        },
        rev: {
          en: 'Counter Clockwise',
          ru: 'Против часовой стрелки'
        }
      },
      email: {
        en: 'Email',
        ru: 'Адрес электронной почты'
      },
      password: {
        en: 'Password',
        ru: 'Пароль'
      },
      speed: {
        en: 'Speed',
        ru: 'Скорость'
      },
      depth: {
        en: 'Depth',
        ru: 'Норма'
      },
      delay: {
        en: 'Delay',
        ru: 'Пауза'
      },
      depthSuffix: {
        en: 'mm',
        ru: 'мм'
      },
      time: {
        en: 'Time',
        ru: 'Время'
      },
      timeWithHoseStops: {
        en: 'Time',
        ru: 'Время 2'
      },
      timeSuffix: {
        en: 'h',
        ru: 'ч'
      },
      timeSuffixHour: {
        en: 'h',
        ru: 'ч'
      },
      timeSuffixMinute: {
        en: 'min',
        ru: 'мин'
      },
      timeSuffixSec: {
        en: 'sec',
        ru: 'сек'
      },
      pressure: {
        en: 'Pressure',
        ru: 'Давление'
      },
      pressureSuffix: {
        en: 'Bar',
        ru: 'Бар'
      },
      uart: {
        en: 'UART',
        ru: 'UART'
      },
      gnss: {
        en: 'GNSS',
        ru: 'GNSS'
      },
      startWoGnss: {
        en: 'W/O GNSS',
        ru: 'Без GNSS'
      },
      sensor: {
        en: 'Sensor',
        ru: 'Sensor'
      },
      bar: {
        en: 'Bar',
        ru: 'Бар'
      },
      flow: {
        en: 'Flow',
        ru: 'Поток'
      },
      flowSuffix: {
        // en: 'l/s',
        // ru: 'л/c',
        en: 'm³/h',
        ru: 'м³/ч'
      },
      voltage: {
        en: 'Voltage',
        ru: 'Напряжение'
      },
      voltageSuffix: {
        en: 'Volt',
        ru: 'Вольт'
      },
      temperature: {
        en: 'Temperature',
        ru: 'Температура'
      },
      temperatureSuffix: {
        en: '°C',
        ru: '°C'
      },
      serviceStop: {
        en: 'Service Stop',
        ru: 'Сервисная остановка'
      },
      parking: {
        en: 'Parking',
        ru: 'Парковка'
      },
      serviceStopRepeat: {
        en: 'Repeat',
        ru: 'Повтор'
      },
      toggle: {
        en: 'On',
        ru: 'Вкл'
      },
      comment: {
        en: 'Comment',
        ru: 'Комментарий'
      },
      lat: {
        en: 'Latitude',
        ru: 'Широта'
      },
      lng: {
        en: 'Longitude',
        ru: 'Долгота'
      },
      startLat: {
        en: 'Start Latitude',
        ru: 'Начальная широта'
      },
      startLng: {
        en: 'Start Longitude',
        ru: 'Начальная долгота'
      },
      endLat: {
        en: 'End Latitude',
        ru: 'Конечная широта'
      },
      endLng: {
        en: 'End Longitude',
        ru: 'Конечная долгота'
      },
      lateralHeight: {
        en: 'Device Height',
        ru: 'Длина устройства'
      },
      lateralLength: {
        en: 'Field Length',
        ru: 'Длина поля'
      },
      startPosition: {
        en: 'Start Position',
        ru: 'Начальное положение'
      },
      endPosition: {
        en: 'Run Length',
        ru: 'Длина гона'
        // en: 'End Position',
        // ru: 'Конечное положение'
      },
      radius: {
        en: 'Radius',
        ru: 'Радиус'
      },
      radiusSuffix: {
        en: 'm',
        ru: 'м'
      },
      hoseStopSuffix: {
        en: 'm',
        ru: 'м'
      },
      watermarkSuffix: {
        en: '%',
        ru: '%'
      },
      gunIndex: {
        en: 'Area',
        ru: 'Область'
      },
      gunStart: {
        en: 'Start Angle',
        ru: 'Начальный угол'
      },
      gunEnd: {
        en: 'End Angle',
        ru: 'Конечный угол'
      },
      gunStartLateral: {
        en: 'Start Position',
        ru: 'Начальное положение'
      },
      gunEndLateral: {
        en: 'End Position',
        ru: 'Конечное положение'
      },
      startAngle: {
        en: 'Start Angle',
        ru: 'Начальный угол'
      },
      endAngle: {
        en: 'End Angle',
        ru: 'Конечный угол'
      },
      offsetAngle: {
        en: 'Offset Angle',
        ru: 'Угол смещения'
      },
      language: {
        en: 'Language',
        ru: 'Язык'
      },
      barrierFrom: {
        en: 'Forward',
        ru: 'Начало',
      },
      barrierTo: {
        en: 'Reverse',
        ru: 'Конец',
      },
      barrierHoldFor: {
        en: 'Hold For',
        ru: 'Задержка',
      },
      motohour: {
        en: 'Moto Hour',
        ru: 'Моточасы',
      },
      circles: {
        en: 'Circles',
        ru: 'Круги'
      },
      user: {
        name: {
          en: 'User Name',
          ru: 'Имя пользователя'
        },
        email: {
          en: 'Email',
          ru: 'Адрес электронной почты'
        },
        password: {
          en: 'Password',
          ru: 'Пароль'
        },
        role: {
          en: 'Role',
          ru: 'Роль'
        },
        organization: {
          en: 'Organization',
          ru: 'Организация'
        },
        allDevices: {
          en: 'All Devices Accessible',
          ru: 'Все устройства доступны'
        },
        allDevicesShort: {
          en: 'All',
          ru: 'Все'
        },
        devices: {
          en: 'Accessible Devices',
          ru: 'Доступные устройства'
        }
      },
      pivot: {
        uuid: {
          en: 'UUID',
          ru: 'UUID'
        },
        ip: {
          en: 'Socket ID',
          ru: 'ID сокета'
        },
        name: {
          en: 'Name',
          ru: 'Название'
        },
        manufacturer: {
          en: 'Manufacturer',
          ru: 'Производитель'
        }
      },
      placement: {
        en: 'Placement',
        ru: 'Размещение'
      },
      device: {
        uid: {
          en: 'UID',
          ru: 'UID'
        },
        mac: {
          en: 'Id',
          ru: 'MAC/IMEI/ID'
        }
      },
      organization: {
        name: {
          en: 'Organization Name',
          ru: 'Название организации'
        },
        devices: {
          en: 'Accessible Devices',
          ru: 'Доступные устройства'
        },
        proxy: {
          ip: {
            en: 'IP',
            ru: 'IP'
          },
          port: {
            en: 'Port',
            ru: 'Порт'
          }
        }
      },
      camera: {
        ip: {
          en: 'IP',
          ru: 'IP'
        },
        port: {
          en: 'Port',
          ru: 'Порт'
        },
        login: {
          en: 'Login',
          ru: 'Логин'
        },
        password: {
          en: 'Password',
          ru: 'Пароль'
        },
        status: {
          en: 'Status',
          ru: 'Статус'
        },
        cameraNum: {
          en: 'Camera No',
          ru: 'Номер камеры'
        }
      },
      settings: {
        timeOffset: {
          en: 'Time Offset',
          ru: 'Таймзона'
        },
        timeOffsetHint: {
          en: 'In hours, relatively to UTC e.g. +1, +2, etc.',
          ru: 'В часах относительно UTC, например +1, +2, итд.'
        }
      },
      hoseStopPosition: {
        en: 'Position',
        ru: 'Позиция'
      },
      hoseStopAlertDistance: {
        en: 'Alert Distance',
        ru: 'Предупреждение'
      },
      hoseStopAlign: {
        en: 'Align',
        ru: 'Выравнивание'
      },
      hoseStopVisibility: {
        en: 'Show',
        ru: 'Отображать'
      },
      areaPlanStart: {
        en: 'Start',
        ru: 'Начало'
      },
      areaPlanEnd: {
        en: 'End',
        ru: 'Конец'
      },
      areaPlanIrrigation: {
        en: 'Irrigation',
        ru: 'Полив'
      },
      watermarkDate: {
        en: 'Date',
        ru: 'Дата'
      },
      watermarkDepth: {
        en: 'Depth',
        ru: 'Глубина'
      },
      watermarkValue: {
        en: 'Value',
        ru: 'Значение'
      },
    },
    actions: {
      requestPivot: {
        en: 'Request Pivot Settings',
        ru: 'Запросить'
      },
      requestLog: {
        en: 'Request Pivot Log',
        ru: 'Запросить лог устройства'
      },
      requestPump: {
        en: 'Request Pump Settings',
        ru: 'Запросить настройки насоса'
      },
      firmware: {
        en: 'Firmware',
        ru: 'Прошить'
      },
      startEditEndGuns: {
        en: 'Edit End Guns',
        ru: 'Редактировать концевые пушки'
      },
      edit: {
        en: 'Edit',
        ru: 'Редактировать'
      },
      login: {
        en: 'Log In',
        ru: 'Войти'
      },
      logout: {
        en: 'Log Out',
        ru: 'Выйти'
      },
      settings: {
        en: 'Settings',
        ru: 'Настройки'
      },
      notifications: {
        en: 'Notifications',
        ru: 'Уведомления'
      },
      admin: {
        en: 'Admin',
        ru: 'Админ'
      },
      restorePassword: {
        en: 'Restore Password',
        ru: 'Восстановить пароль'
      },
      centerMap: {
        en: 'Center Map',
        ru: 'Центрировать на объектах'
      },
      refresh: {
        en: 'Refresh',
        ru: 'Обновить'
      },
      apply: {
        en: 'Apply',
        ru: 'Применить'
      },
      add: {
        en: 'Add',
        ru: 'Добавить'
      },
      create: {
        en: 'Create',
        ru: 'Создать'
      },
      save: {
        en: 'Save',
        ru: 'Сохранить'
      },
      cancel: {
        en: 'Cancel',
        ru: 'Отмена'
      },
      editOnTheMap: {
        en: 'Edit Position',
        ru: 'Изменить положение на карте'
      },
      request: {
        en: 'Request',
        ru: 'Опросить'
      },
      gotoLogin: {
        en: 'Login',
        ru: 'Войти'
      },
      gotoRestorePassword: {
        en: 'Restore password',
        ru: 'Восстановление пароля'
      },
      close: {
        en: 'Close',
        ru: 'Закрыть'
      },
      userNew: {
        en: 'Create New User',
        ru: 'Создать нового пользователя'
      },
      organizationNew: {
        en: 'Create New Organization',
        ru: 'Создать новую организацию'
      },
      pivotNew: {
        en: 'Create New Pivot',
        ru: 'Создать новую машину'
      },
      delete: {
        en: 'Delete',
        ru: 'Удалить'
      },
      addCamera: {
        en: 'Add',
        ru: 'Добавить'
      },
      editCamera: {
        en: 'Edit',
        ru: 'Редактировать'
      },
      requestReport: {
        en: 'Load data',
        ru: 'Загрузить отчет'
      },
      find: {
        en: 'Find',
        ru: 'Найти'
      },
      locateMe: {
        en: 'Locate Me',
        ru: 'Найти меня'
      },
      editWatermarkSensor: {
        en: 'Watermark Sensor',
        ru: 'Настройки датчиков'
      }
    },
    irrigationColumns: {
      equipment: {
        en: 'Name',
        ru: 'Имя'
      },
      status: {
        en: 'Status',
        ru: 'Статус'
      },
      speed: {
        en: 'Speed',
        ru: 'Скорость'
      },
      depth: {
        en: 'Depth',
        ru: 'Норма'
      },
      time: {
        en: 'Time',
        ru: 'Время'
      },
      serviceStop: {
        en: 'Serv. Stop',
        ru: 'Серв. Ост.'
      },
      management: {
        en: 'Management',
        ru: 'Управление'
      },
      camera: {
        en: 'Camera',
        ru: 'Камера'
      },
      connection: {
        en: 'Connection',
        ru: 'Связь'
      },
      last_update: {
        en: 'Updated',
        ru: 'Обнов.'
      }
    },
    meteosColumns: {
      id: {
        en: 'Id',
        ru: 'Id'
      },
      name: {
        en: 'Name',
        ru: 'Имя'
      },
      device_name: {
        en: 'Station name',
        ru: 'Имя устройства'
      },
      type: {
        en: 'Type',
        ru: 'Тип'
      },
      connection: {
        en: 'Connection',
        ru: 'Связь'
      },
      moisture: {
        en: 'Moisture',
        ru: 'Влажность'
      },
      temperature: {
        en: 'Temp',
        ru: 'Темп'
      },
      airrh: {
        en: 'Air Rh',
        ru: 'Влажн'
      },
      dewpoint: {
        en: 'Dew Point',
        ru: 'Т.росы'
      },
      windspeed: {
        en: 'Wind Speed',
        ru: 'Ветер'
      },
      windspeedmax: {
        en: 'Wind Gust',
        ru: 'Порывы'
      },
      winddirection: {
        en: 'Wind Dir',
        ru: 'Направл'
      },
      rain: {
        en: 'Rain',
        ru: 'Дождь'
      },
      rain1h: {
        en: 'Rain 1 Hour',
        ru: 'Дождь,1ч'
      },
      date: {
        en: 'Date',
        ru: 'Дата'
      }
    },
    status: {
      no_connection: {
        en: 'No Connection',
        ru: 'Нет связи'
      },
      alg_err: {
        en: 'alg_err',
        ru: 'Ошибка выравнивания'
      },
      lic_error: {
        en: 'lic_error',
        ru: 'Ошибка лицензии Valley'
      },
      prsr_low: {
        en: 'prsr_low',
        ru: 'Низкое давление'
      },
      rst_dly: {
        en: 'rst_dly',
        ru: 'Задержка перезапуска'
      },
      prsr_wait: {
        en: 'prsr_wait',
        ru: 'Задержка ожид.давл.'
      },
      prsr_rcy: {
        en: 'prsr_rcy',
        ru: 'Восст-е давления'
      },
      prsr_min: {
        en: 'prsr_min',
        ru: 'Мин.допустимое давл.'
      },
      over_water: {
        en: 'Over Water',
        ru: 'Over Water'
      },
      prepare: {
        en: 'Prepare',
        ru: 'Подготовка'
      },
      gps_error: {
        en: 'GPS Error',
        ru: 'Ошибка GPS'
      },
      sft_error: {
        en: 'Safety Error',
        ru: 'Цепь безопасности'
      },
      enrg_low: {
        en: 'Low Energy Error',
        ru: 'Ошибка низкого напряжения'
      },
      voltage_error: {
        en: 'Voltage Error',
        ru: 'Отсутствие напряжения'
      },
      pos_sensor_error: {
        en: 'Position Sensor Error',
        ru: 'Связь с датчиком положения'
      },
      temp_error: {
        en: 'Temperature Error',
        ru: 'Ошибка температуры'
      },
      service_stop: {
        en: 'Service Stop',
        ru: 'Сервисная остановка'
      },
      barrier_stop: {
        en: 'Barrier Stop',
        ru: 'Автоматическая остановка у барьера'
      },
      acc_frw: {
        en: 'Forward with ACC',
        ru: 'Движение вперед с удобрениями'
      },
      acc_rev: {
        en: 'Reverse with ACC',
        ru: 'Движение назад с удобрениями'
      },
      irrigation_frw: {
        en: 'Forward with irrigation',
        ru: 'Движение вперед с водой'
      },
      irrigation_rev: {
        en: 'Reverse with irrigation',
        ru: 'Движение назад с водой'
      },
      frw: {
        en: 'Forward',
        ru: 'Движение вперед'
      },
      rev: {
        en: 'Reverse',
        ru: 'Движение назад'
      },
      stop: {
        en: 'Stop',
        ru: 'Движение прекращено'
      },
      move: {
        en: 'Move',
        ru: 'Движ.'
      },
      online: {
        en: 'Online',
        ru: 'Онлайн'
      },
      offline: {
        en: 'Offline',
        ru: 'Оффлайн'
      },
      finish: {
        en: 'Stop',
        ru: 'Движение прекращено'
      },
      soft_barrier: {
        en: 'Soft Barrier',
        ru: 'Мягкий барьер'
      },
      relay_online: {
        en: 'Relay Online',
        ru: 'Реле Онлайн'
      },
      relay_offline: {
        en: 'Relay Offline',
        ru: 'Реле Оффлайн'
      },
      engine_off: {
        en: 'Engine Off',
        ru: 'Двигатель выключен'
      }
    },
    statusShort: {
      no_connection: {
        en: 'No Connection',
        ru: 'Нет связи'
      },
      alg_err: {
        en: 'alg_err',
        ru: 'Ошибка выравн.'
      },
      lic_error: {
        en: 'lic_error',
        ru: 'Ошибка лицензии Valley'
      },
      enrg_low: {
        en: 'Low Energy Error',
        ru: 'Низкое напряжение'
      },
      prsr_low: {
        en: 'prsr_low',
        ru: 'Низкое давление'
      },
      rst_dly: {
        en: 'rst_dly',
        ru: 'Задержка перезапуска'
      },
      prsr_wait: {
        en: 'prsr_wait',
        ru: 'Задержка ожид.давл.'
      },
      prsr_rcy: {
        en: 'prsr_rcy',
        ru: 'Восст-е давл.'
      },
      prsr_min: {
        en: 'prsr_min',
        ru: 'Мин.доп.давл.'
      },
      over_water: {
        en: 'Over Water',
        ru: 'Over Water'
      },
      end_tower_sensor: {
        en: 'End Tower Sensor',
        ru: 'End Tower Sensor'
      },
      wrong_dir: {
        en: 'Wrong Direction',
        ru: 'Ошибка направления'
      },
      pl_con_err: {
        en: 'PL Comm Lost',
        ru: 'Нет соединения с PL'
      },
      ppt_pro_err: {
        en: 'PPT Pro Error',
        ru: 'Ошибка PPT Pro'
      },
      prepare: {
        en: 'Prepare',
        ru: 'Подготовка'
      },
      gps_error: {
        en: 'GPS Error',
        ru: 'Ошибка GPS'
      },
      voltage_error: {
        en: 'Voltage Error',
        ru: 'Отсутствие напряжения'
      },
      sft_error: {
        en: 'Safety Error',
        ru: 'Цепь безопасности'
      },
      pos_sensor_error: {
        en: 'Position Sensor Error',
        ru: 'Связь с датчиком положения'
      },
      temp_error: {
        en: 'Temperature Error',
        ru: 'Ошибка температуры'
      },
      service_stop: {
        en: 'Srv.Stop',
        ru: 'Серв.Ост.'
      },
      barrier_stop: {
        en: 'Barrier.Stop',
        ru: 'Барьер'
      },
      acc_frw: {
        en: 'Frw with ACC',
        ru: 'Дв.вперед с удобр.'
      },
      acc_rev: {
        en: 'Rev with ACC',
        ru: 'Дв.назад с удобр.'
      },
      irrigation_frw: {
        en: 'Frw with irrigation',
        ru: 'Дв.вперед с водой'
      },
      irrigation_rev: {
        en: 'Rev with irrigation',
        ru: 'Дв.назад с водой'
      },
      frw: {
        en: 'Frw',
        ru: 'Дв.вперед'
      },
      rev: {
        en: 'Rev',
        ru: 'Дв.назад'
      },
      stop: {
        en: 'Stop',
        ru: 'Останов.'
      },
      soft_barrier: {
        en: 'Soft Barrier',
        ru: 'Мягкий барьер'
      }
    },
    workPlan: {
      manual: {
        en: 'Manual management',
        ru: 'Ручное управление'
      },
      auto: {
        en: 'Automatic management',
        ru: 'Автоматическое управление'
      }
    },
    controls: {
      move: {
        start: {
          en: 'Start',
          ru: 'Запустить'
        },
        off: {
          en: 'Stop',
          ru: 'Остановить'
        },
        frw: {
          en: 'Forward',
          ru: 'Вперед'
        },
        rev: {
          en: 'Reverse',
          ru: 'Назад'
        },
        finish: {
          en: 'Stop',
          ru: 'Остановка'
        }
      },
      irrigation: {
        off: {
          en: 'Irrigaion Off',
          ru: 'Выключить воду'
        },
        on: {
          en: 'Irrigation On',
          ru: 'Включить воду'
        }
      },
      acc: {
        off: {
          en: 'ACC Off',
          ru: 'Выключить ACC'
        },
        on: {
          en: 'ACC On',
          ru: 'Включить ACC'
        }
      },
      accMixer: {
        off: {
          en: 'ACC Mixer Off',
          ru: 'Выкл ACC Микс'
        },
        on: {
          en: 'ACC Mixer On',
          ru: 'Вкл ACC Микс'
        }
      },
      autoRev: {
        off: {
          en: 'Autoreverse Off',
          ru: 'Выключить Авторазворот'
        },
        on: {
          en: 'Autoreverse On',
          ru: 'Включить Авторазворот'
        }
      },
      autoStart: {
        off: {
          en: 'Autorestart Off',
          ru: 'Выключить Автоперезапуск'
        },
        on: {
          en: 'Autorestart On',
          ru: 'Включить Автоперезапуск'
        }
      }
    },
    errors: {
      incorrectToken: {
        en: 'Incorrect authentication token',
        ru: 'Неверный токен авторизации'
      },
      formValidation: {
        en: 'Some form fields are not filled correctly',
        ru: 'Некоторые поля формы заполнены неверно'
      },
      incorrectEmailOrPassword: {
        en: 'Incorrect Email or Password',
        ru: 'Неправильный email или пароль'
      },
      fields: {
        noEmail: {
          en: 'Email is not specified',
          ru: 'Емейл не указан'
        },
        noPassword: {
          en: 'Password is not specified',
          ru: 'Пароль не указан'
        }
      },
      updateSnapshot: {
        en: 'Failed receiving the new snapshot',
        ru: 'Ошибка получения нового снимка камеры'
      }
    },
    success: {
      pivotRequested: {
        en: 'Pivot Requested',
        ru: 'Настройки пивота запрошены'
      },
      pumpRequested: {
        en: 'Pump Requested',
        ru: 'Настройки насоса запрошены'
      },
      barrierRequested: {
        en: 'Soft Barrier Requested',
        ru: 'Настройки барьера запрошены'
      },
      logRequested: {
        en: 'Log Requested',
        ru: 'Лог запрошен'
      },
      noSystemSettings: {
        en: 'No System Settings',
        ru: 'Системные настройки не найдены'
      },
      settingsUpdated: {
        en: 'Settings Updated',
        ru: 'Настройки обновлены'
      },
      barrierUpdated: {
        en: 'Soft Barrier Updated',
        ru: 'Данные барьера обновлены'
      },
      cameraSettingsUpdated: {
        en: 'Camera Settings Updated',
        ru: 'Настройки камеры обновлены'
      },
      userCreated: {
        en: 'New User Created',
        ru: 'Создан новый пользователь'
      },
      userUpdated: {
        en: 'User Data Updated',
        ru: 'Данные пользователя обновлены'
      },
      userDeleted: {
        en: 'User Deleted',
        ru: 'Пользователь удален'
      },
      organizationCreated: {
        en: 'New Organization Created',
        ru: 'Создана новая организация'
      },
      organizationUpdated: {
        en: 'Organization Data Updated',
        ru: 'Данные организации обновлены'
      },
      organizationDeleted: {
        en: 'Organization Deleted',
        ru: 'Организация удалена'
      },
      loggedIn: {
        en: 'You\'ve logged in',
        ru: 'Вы вошли в систему'
      },
      loggedOut: {
        en: 'You\'ve logged out',
        ru: 'Вы вышли из системы'
      },
      cmdSent: {
        en: 'The command has been sent to the device',
        ru: 'Команда отправлена на устройство'
      },
      coordsUpdated: {
        en: 'Device position updated',
        ru: 'Положение на карте сохранено'
      },
      requestSent: {
        en: 'Request sent',
        ru: 'Данные обновлены'
      },
      noEndGuns: {
        en: 'End guns are not set for this device',
        ru: 'Концевые пушки для данного устройства не установлены'
      },
      endGunsUpdated: {
        en: 'End guns updated',
        ru: 'Данные по концевым пушкам проставлены'
      },
      hoseStopsUpdated: {
        en: 'Hose stops updated',
        ru: 'Настройки заданы'
      },
      areaPlansUpdated: {
        en: 'Area Plans updated',
        ru: 'Настройки заданы'
      },
      watermarkUpdated: {
        en: 'Watermark updated',
        ru: 'Данные датчика влажности почвы обновлены'
      },
      watermarkSettingsUpdated: {
        en: 'Watermark settings updated',
        ru: 'Настройки датчика влажности почвы обновлены'
      },
      noWatermark: {
        en: 'Watermark are not set for this device',
        ru: 'Показатели датчика влажности почвы не заданы'
      },
      noCamera: {
        en: 'Camera is not set for this device',
        ru: 'Камера для данного устройства не установлена'
      },
      noReport: {
        en: 'Data not found',
        ru: 'Данные не найдены'
      },
      confirmStartDisel: {
        en: 'Confirm diesel start',
        ru: 'Подтвердите запуск двигателя'
      },
      confirmStopDisel: {
        en: 'Confirm diesel stop',
        ru: 'Подтвердите выключение двигателя'
      },
      confirmFirmware: {
        en: 'Confirm Firmware Initial',
        ru: 'Подтвердите начало перепрошивки'
      },
      firmwareStart: {
        en: 'Firmware Start',
        ru: 'Процесс перепрошивки запущен'
      },
      noMeteoData: {
        en: 'Meteo data not found',
        ru: 'Данные не найдены'
      }
    },
    endGun: {
      title: {
        en: 'End Gun',
        ru: 'Концевая пушка'
      },
      suffix: {
        en: 'm',
        ru: 'м'
      },
      noAreas: {
        en: 'End gun areas are not defined',
        ru: 'Области не заданы'
      }
    },
    hoseStop: {
      title: {
        en: 'Hose Stop',
        ru: 'Гидранты'
      },
      suffix: {
        en: 'm',
        ru: 'м'
      },
    },
    watermark: {
      title: {
        en: 'Watermark. Manual input',
        ru: 'Влажность почвы. Ручной ввод'
      },
      suffix: {
        en: 'mm',
        ru: 'мм'
      },
      wilting_point: {
        en: 'Wilting point',
        ru: 'Точка увядания'
      },
      start_watering: {
        en: 'Start Watering',
        ru: 'Начало полива'
      },
      stop_watering: {
        en: 'Stop Watering',
        ru: 'Окончание полива'
      },
      smallest_field_capacity: {
        en: 'Smallest Field Capacity',
        ru: 'Наименьшая влагоемкость'
      }
    },
    areaPlan: {
      title: {
        en: 'Plans',
        ru: 'План полива'
      },
      suffix: {
        en: 'm',
        ru: 'м'
      },
    },
    mapLayers: {
      titles: {
        en: 'Titles',
        ru: 'Названия'
      },
      fields: {
        en: 'Fields',
        ru: 'Поля'
      },
    },
    mapProviders: {
      osm: {
        en: 'Open Street Map',
        ru: 'Схема'
      },
      esri: {
        en: 'Esri',
        ru: 'Спутник'
      },
      titles: {
        en: 'Titles',
        ru: 'Названия'
      },
      fields: {
        en: 'Fields',
        ru: 'Поля'
      },
      pivots: {
        en: 'Pivots',
        ru: 'Машины'
      },
      meteostations: {
        en: 'Meteo Stations',
        ru: 'Метеостанции'
      },
      watermeters: {
        en: 'Water meters',
        ru: 'Датчики влажности'
      }
    },
    admin: {
      settings: {
        en: 'Settings',
        ru: 'Настройки'
      },
      deviceSettings: {
        en: 'Admin Device Settings',
        ru: 'Настройки доступа'
      },
      menu: {
        users: {
          en: 'Users',
          ru: 'Пользователи'
        },
        organizations: {
          en: 'Organizations',
          ru: 'Организации'
        },
        pivots: {
          en: 'Devices',
          ru: 'Машины'
        },
        telegram: {
          en: 'Telegram',
          ru: 'Телеграм'
        }
      },
      users: {
        id: {
          en: 'ID',
          ru: '#'
        },
        name: {
          en: 'Name',
          ru: 'Имя'
        },
        email: {
          en: 'Email',
          ru: 'Электронная почта'
        },
        role: {
          en: 'Role',
          ru: 'Роль'
        },
        organization: {
          en: 'Organization',
          ru: 'Организация'
        },
        devices: {
          en: 'Devices',
          ru: 'Устройства'
        },
        noDataText: {
          en: 'No Users Available',
          ru: 'Список пользователей пуст'
        }
      },
      organizations: {
        id: {
          en: 'ID',
          ru: '#'
        },
        name: {
          en: 'Name',
          ru: 'Название'
        },
        devices: {
          en: 'Devices',
          ru: 'Устройства'
        },
        noDataText: {
          en: 'No Organizations Available',
          ru: 'Список организаций пуст'
        }
      },
      pivots: {
        id: {
          en: 'ID',
          ru: '#'
        },
        name: {
          en: 'Name',
          ru: 'Название'
        },
        organization: {
          en: 'Organization',
          ru: 'Организация'
        },
        noDataText: {
          en: 'No Pivot Available',
          ru: 'Список машин пуст'
        }
      },
      user: {
        title: {
          en: 'User',
          ru: 'Пользователь'
        },
        newTitle: {
          en: 'New User',
          ru: 'Новый пользователь'
        }
      },
      organization: {
        title: {
          en: 'Organization',
          ru: 'Организация'
        },
        newTitle: {
          en: 'New Organization',
          ru: 'Новая организация'
        }
      },
      pivot: {
        title: {
          en: 'Pivot',
          ru: 'Машина'
        },
        newTitle: {
          en: 'New Pivot',
          ru: 'Новая Машина'
        }
      },
    },
    camera: {
      add: {
        en: 'Add Camera',
        ru: 'Добавить камеру'
      },
      edit: {
        en: 'Edit Camera Settings',
        ru: 'Редактировать настройки камеры'
      }
    },
    pivot: {
      field: {
        en: 'Field',
        ru: 'Параметр'
      },
      value: {
        en: 'Value',
        ru: 'Значение'
      }
    },
    meteoTable: {
      dates: {
        en: 'Date',
        ru: 'Дата',
      },
      Temperature: {
        en: 'Temperature',
        ru: 'Температура',
      },
      AirRh: {
        en: 'Air Rh',
        ru: 'Влажность воздуха',
      },
      Dewpoint: {
        en: 'Dewpoint',
        ru: 'Точка росы',
      },
      Precipitation: {
        en: 'Precipitation',
        ru: 'Осадки',
      },
      WindSpeed: {
        en: 'Wind speed',
        ru: 'Скорость ветра',
      },
    },
    meteoSummary: {
      sta: {
        en: 'SAT',
        ru: 'Сумма акт. температур'
      },
      gdd: {
        en: 'GDD',
        ru: 'Градусо-дни'
      },
      chu: {
        en: 'CHU',
        ru: 'Всего единиц тепла'
      },
    },
    forecastData: {
      temperature_2m_max: {
        en: 't°C max',
        ru: 't°C max',
      },
      temperature_2m_min: {
        en: 't°C min',
        ru: 't°C min',
      },
      wind_speed_10m_max: {
        en: 'Wind',
        ru: 'Ветер',
      },
      precipitation_sum: {
        en: 'Precipitation',
        ru: 'Осадки',
      },
      precipitation_probability_max: {
        en: 'Вероятность',
        ru: 'Вероятность',
      }
    },
    report: {
      pivot: {
        en: 'Pivot',
        ru: 'Пивот'
      },
      organization: {
        en: 'Organization',
        ru: 'Организация'
      },
      date: {
        en: 'Date/Time',
        ru: 'Дата/Время'
      },
      status: {
        en: 'Status',
        ru: 'Статус'
      },
      position: {
        en: 'Position',
        ru: 'Позиция'
      },
      field: {
        en: 'Field',
        ru: 'Поле'
      },
      passed: {
        en: 'Distance',
        ru: 'Пройдено'
      },
      speed: {
        en: 'Speed',
        ru: 'Скорость'
      },
      depth: {
        en: 'Depth',
        ru: 'Норма'
      },
      duration: {
        en: 'Duration',
        ru: 'Длительность'
      },
      ha: {
        en: 'Ha',
        ru: 'Га'
      },
      flow: {
        en: 'Water flow',
        ru: 'Расход воды'
      },
      bar: {
        en: 'Bar',
        ru: 'Давление'
      },
      motohours: {
        en: 'Moto Hours',
        ru: 'Моточасы'
      },
      motohourMax: {
        en: 'Moto Hours Limit',
        ru: 'Порог'
      }
    },
    backEndStatuses: {
      alg_err: {
        en: 'alg_err',
        ru: 'Ошибка выравнивания'
      },
      lic_error: {
        en: 'lic_error',
        ru: 'Ошибка лицензии Valley'
      },
      enrg_low: {
        en: 'Low Energy Error',
        ru: 'Низкое напряжение'
      },
      prsr_low: {
        en: 'prsr_low',
        ru: 'Низкое давление'
      },
      rst_dly: {
        en: 'rst_dly',
        ru: 'Задержка перезапуска'
      },
      prsr_wait: {
        en: 'prsr_wait',
        ru: 'Задержка ожид.давл.'
      },
      prsr_rcy: {
        en: 'prsr_rcy',
        ru: 'Восст-е давления'
      },
      prsr_min: {
        en: 'prsr_min',
        ru: 'Мин.допустимое давл.'
      },
      over_water: {
        en: 'Over Water',
        ru: 'Over Water'
      },
      prepare: {
        en: 'Prepare',
        ru: 'Подготовка'
      },
      gps_error: {
        en: 'GPS Error',
        ru: 'Ошибка GPS'
      },
      sft_error: {
        en: 'Safety Error',
        ru: 'Цепь безопасности'
      },
      voltage_error: {
        en: 'Voltage Error',
        ru: 'Отсутствие напряжения'
      },
      barrier_stop: {
        en: 'Barrier Stop',
        ru: 'Автоматическая остановка у барьера'
      },
      pos_sensor_error: {
        en: 'Position Sensor Error',
        ru: 'Связь с датчиком положения'
      },
      temp_error: {
        en: 'Temperature Error',
        ru: 'Ошибка температуры'
      },
      soft_barrier: {
        en: 'Soft Barrier',
        ru: 'Мягкий барьер'
      },
      pump_station_alarm: {
        en: 'Pump Station Alarm',
        ru: 'Авария насосной станции'
      },
      pump_alarm: {
        en: 'Pump Alarm',
        ru: 'Авария насоса'
      }
    },
    filter: {
      date_from: {
        en: 'Date From',
        ru: 'Дата от',
      },
      date_to: {
        en: 'Date To',
        ru: 'Дата до',
      },
      time_from: {
        en: 'Time From',
        ru: 'Время с',
      },
      time_to: {
        en: 'Time To',
        ru: 'Время до',
      },
      period: {
        en: 'Period',
        ru: 'Период',
      },
    },
    field_station: {
      en: 'Field Station',
      ru: 'Полевая',
    },
    meteo_station: {
      en: 'Meteo Station',
      ru: 'Метео',
    },
    charts: {
      watermark: {
        tab: {
          en: 'Watermark',
          ru: 'Влажность почвы',
        },
        soil_wet: {
          en: 'Soil Moisture',
          ru: 'Влажность почвы'
        },
        wet: {
          en: 'Air Rh',
          ru: 'Влажность воздуха'
        },
        precipitation: {
          en: 'Precipitation',
          ru: 'Осадки'
        },
        temperature: {
          en: 'Soil temperature',
          ru: 'Температура почвы'
        },
        vic: {
          en: 'Volumetric Ionic Content',
          ru: 'Засоленность почвы'
        },
      },
      air_temperature: {
        en: 'Air Temperature',
        ru: 'Температура воздуха'
      },
      solar_radiation: {
        en: 'Solar radiation',
        ru: 'Солнечная радиация'
      },
      wind_speed: {
        en: 'Wind Speed',
        ru: 'Скорость ветра'
      },
      daily_et0: {
        en: 'Daily ET0',
        ru: 'Дневная ET0'
      },
      dew_point: {
        en: 'Dew Point',
        ru: 'Точка росы'
      },
      meteo: {
        tab: {
          en: 'Monitoring',
          ru: 'Метеоданные'
        }
      },
      units: {
        mm: {
          en: 'mm',
          ru: 'мм'
        },
        cm: {
          en: 'cm',
          ru: 'см'
        },
        ms: {
          en: 'm/s',
          ru: 'м/с'
        }
      }
    },
    barrier: {
      title: {
        en: 'Barrier',
        ru: 'Барьер'
      },
      between: {
        en: 'Between',
        ru: 'Участок'
      }
    },
    weatherCode: {
      'clear-sky': {
        en: 'Clear Sky',
        ru: 'Ясно',
      },
      'mainly-clear': {
        en: 'Mainly Сlear',
        ru: 'Ясно',
      },
      'partly-cloudy': {
        en: 'Cloudy',
        ru: 'Облачно',
      },
      'overcast': {
        en: 'Overcast',
        ru: 'Пасмурно',
      },
      'fog': {
        en: 'Fog',
        ru: 'Туман',
      },
      'drizzle': {
        en: 'Drizzle',
        ru: 'Морось',
      },
      'freezing-drizzle': {
        en: 'Freezing Drizzle',
        ru: 'Изморозь',
      },
      'rain-slight': {
        en: 'Rain Slight',
        ru: 'Слабый дождь',
      },
      'rain-moderate': {
        en: 'Rain',
        ru: 'Дождь',
      },
      'rain-heavy-intensity': {
        en: 'Rain Heavy',
        ru: 'Сильный дождь',
      },
      'freezing-rain-light': {
        en: 'Freezing Rain',
        ru: 'Дождь со снегом',
      },
      'freezing-rain-heavy-intensity': {
        en: 'Freezing Rain',
        ru: 'Дождь со снегом',
      },
      'snow-slight': {
        en: 'Snow',
        ru: 'Снег',
      },
      'snow-fall-moderate': {
        en: 'Snow Fall',
        ru: 'Снегопад',
      },
      'snow-fall-heavy-intensity': {
        en: 'Snow Fall',
        ru: 'Снегопад',
      },
      'snow-grains': {
        en: 'Snow Grains',
        ru: 'Град',
      },
      'rain-showers-slight': {
        en: 'Rain Showers',
        ru: 'Ливень',
      },
      'rain-showers-moderate': {
        en: 'Rain Showers',
        ru: 'Ливень',
      },
      'rain-showers-heavy-intensity': {
        en: 'Rain Showers',
        ru: 'Ливень',
      },
      'snow-showers-slight': {
        en: 'Snow Showers',
        ru: 'Снегопад',
      },
      'snow-showers-heavy': {
        en: 'Snow Showers',
        ru: 'Снегопад',
      },
      'thunderstorm': {
        en: 'Thunderstorm',
        ru: 'Гроза',
      },
      'thunderstorm-slight-hail': {
        en: 'Thunderstorm',
        ru: 'Гроза',
      },
      'thunderstorm-heavy-hail': {
        en: 'Thunderstorm',
        ru: 'Гроза',
      },
    }
  }
}
