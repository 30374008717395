<template>
  <div class="highcharts-wrapper" v-if="showChart">
    <v-btn icon @click="fullScreen" class="fullscreen-icon">
      <v-icon>mdi-overscan</v-icon>
    </v-btn>
    <highcharts v-if="showChart" :options="options" ref="highcharts"/>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    dates: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    showChart () {
      return this.data['Daily ET0'] && !_.isEmpty(this.data['Daily ET0'])
    },
    options () {
      if (!this.showChart) {
        return null
      }
      return {
        exporting: {
          enabled: false,
        },
        chart: {
          type: '',
          spacingLeft: 0,
          alignTicks: true,
          animation: false,
          height: 500,
        },
        credits: {
          enabled: false
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          categories: this.data.dates,
          labels: {
            rotation: -45,
          }
        },
        yAxis: {
          tickInterval: 5,
          min: 0,
          startOnTick: false,
          endOnTick: false,
          title: {
            text: this.$t('charts.daily_et0') + ', ' + this.$t('charts.units.mm'),
            margin: 15
          },
          labels: {
            enabled: true
          },
          gridLineWidth: 0.5,
        },
        plotOptions: {
          series: {
            animation: false,
          },
          column: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.y > 0) {
                  return this.y
                }
              },
              crop: false,
              overflow: 'none'
            }
          },
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          enabled: false
        },
        series: [{
          yAxis: 0,
          zIndex: 5,
          events:{
            legendItemClick: function () {
              return false
            }
          },
          data: this.data['Daily ET0'],
          enableMouseTracking: false,
          type: 'areaspline',
          lineColor: 'rgba(255, 127, 0, 0.2)',
          color: 'rgba(255, 127, 0, 0.1)',
          lineWidth: 1,
          marker: {
            symbol: 'circle',
            enabled: true
          }
        }]
      }
    }
  },
  methods: {
    fullScreen() {
      this.$refs.highcharts.chart.fullscreen.toggle()
    },
  }
}
</script>
