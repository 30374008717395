<template>
  <div v-if="showActual" class="fc-map__page-body" style="overflow: unset">
    <div class="body-1">
      <v-card-text>
        <v-divider></v-divider>
        <p></p>
        <!-- <div class="title mb-2">{{ $t('meteoSummary.sta') }}</div> -->
        <v-row>
          <v-col>
            <div>
              <!-- <v-icon>mdi-help-circle-outline</v-icon> -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ $t('meteoSummary.sta') }} выше 0°C (SAT): {{ sat0 }}
                  </span>
                </template>
                <span>Сумма всех среднесуточных температур, превышающих 0°C.</span>
              </v-tooltip>
              <!-- {{ $t('meteoSummary.sta') }} выше 0°C (SAT): {{ sat0 }} -->
            </div>
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ $t('meteoSummary.sta') }} выше 5°C (SAT): {{ sat5 }}
                  </span>
                </template>
                <span>Сумма всех среднесуточных температур, превышающих 5°C.</span>
              </v-tooltip>
              <!-- {{ $t('meteoSummary.sta') }} выше 5°C (SAT): {{ sat5 }} -->
            </div>
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ $t('meteoSummary.sta') }} выше 10°C (SAT): {{ sat10 }}
                  </span>
                </template>
                <span>Сумма всех среднесуточных температур, превышающих 10°C.</span>
              </v-tooltip>
              <!-- {{ $t('meteoSummary.sta') }} выше 10°C (SAT): {{ sat10 }} -->
            </div>

          </v-col>
          <v-col>
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ $t('meteoSummary.gdd') }} 10°C (GDD1): {{ gdd1 }}
                  </span>
                </template>
                <span>Сумма среднесуточных температур, превышающих базовое значение 10°C. <br>
                GDD = max((T<sub>min</sub> + T<sub>max</sub>)/2 − T<sub>base</sub>, 0)
                </span>
              </v-tooltip>
              <!-- {{ $t('meteoSummary.gdd') }} 15,5°C (GDD1): {{ gdd1 }} -->
            </div>
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ $t('meteoSummary.gdd') }} 10°C (GDD2): {{ gdd2 }}
                  </span>
                </template>
                <span>Сумма среднесуточных температур, превышающих базовое значение 10°C.<br>
                T<sub>1</sub> = max(T<sub>min</sub>, T<sub>base</sub>)<br>
                T<sub>2</sub> = max(T<sub>max</sub>, T<sub>base</sub>)<br>
                GDD = (T<sub>1</sub> + T<sub>2</sub>)/2 − T<sub>base</sub>
                </span>
              </v-tooltip>
              <!-- {{ $t('meteoSummary.gdd') }} 15,5°C (GDD2): {{ gdd2 }} -->
            </div>
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ $t('meteoSummary.gdd') }} 10°C/30°C (GDD3): {{ gdd3 }}
                  </span>
                </template>
                <span>Сумма среднесуточных температур, превышающих базовое значение 10°C.<br>
                T<sub>1</sub> = max(T<sub>min</sub>, T<sub>base</sub>)<br>
                T<sub>2</sub> = min(max(T<sub>max</sub>, T<sub>base</sub>), T<sub>baseMax</sub>)<br>
                GDD = (T<sub>1</sub> + T<sub>2</sub>)/2 − T<sub>base</sub>
                </span>
              </v-tooltip>
              <!-- {{ $t('meteoSummary.gdd') }} 15,5°C (GDD3): {{ gdd3 }} -->
            </div>
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ $t('meteoSummary.chu') }} (CHU): {{ chu }}
                  </span>
                </template>
                <span>Единицы тепла (Crop Heat Units)</span><br>
                CHU = (1.8 * (T<sub>min</sub> − 4.4) + 3.33 * (T<sub>max</sub> − 10) − 0.084 * (T<sub>max</sub> − 10)<sup>2</sup>) / 2
              </v-tooltip>
              <!-- {{ $t('meteoSummary.chu') }} (CHU): {{ chu }} -->
            </div>
          </v-col>
        </v-row>

      </v-card-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      t_base_min_0: 0,
      t_base_min_5: 5,
      t_base_min_10: 10,
      t_base_min_15: 15.5,
      t_base_max: 30,
    }
  },
  computed: {
    showActual() {
      return this.data && !_.isEmpty(this.data);
    },
    t_min() {
      return this.data.min
    },
    t_max() {
      return this.data.max
    },
    t_means() {
      return this.data.avg
    },
    gdd1() {
      let sum = 0
      this.t_min.forEach((t, i) => {
        sum += this.GDD1(this.t_min[i], this.t_max[i])
      })
      return Math.floor(sum)
    },
    gdd2() {
      let sum = 0
      this.t_min.forEach((t, i) => {
        sum += this.GDD2(this.t_min[i], this.t_max[i])
      })
      return Math.floor(sum)
    },
    gdd3() {
      let sum = 0
      this.t_min.forEach((t, i) => {
        sum += this.GDD3(this.t_min[i], this.t_max[i])
      })
      return Math.floor(sum)
    },
    sat0() {
      return this.SAT(0);
    },
    sat5() {
      return this.SAT(5);
    },
    sat10() {
      return this.SAT(10);
    },
    chu() {
      let sum = 0
      this.t_min.forEach((t, i) => {
        sum += this.CHU(this.t_min[i], this.t_max[i])
      })
      return Math.floor(sum)
    },
  },
  methods: {
    TBaseMin(t_base_min = 5) {
      if (t_base_min === 0) {
        return this.t_base_min_0
      }
      else if (t_base_min === 5) {
        return this.t_base_min_5
      }
      else if (t_base_min === 10) {
        return this.t_base_min_10
      }
      else if (t_base_min === 15) {
        return this.t_base_min_15
      }
    },
    GDD1(t_min, t_max) {
      let t_mean = (t_min + t_max) / 2
      let x = (t_mean - this.t_base_min_10)
      return x <= 0 ? 0 : x
    },
    GDD2(t_min, t_max) {
      let a = t_min < this.t_base_min_10 ? this.t_base_min_10 : t_min
      let b = t_max < this.t_base_min_10 ? this.t_base_min_10 : t_max
      return ((a + b) / 2) - this.t_base_min_10
    },
    GDD3(t_min, t_max) {
      let a = t_min < this.t_base_min_10 ? this.t_base_min_10 : t_min
      let b = t_max < this.t_base_min_10 ? this.t_base_min_10 : (t_max > this.t_base_max ? this.t_base_max : t_max)
      return ((a + b) / 2) - this.t_base_min_10
    },
    SAT(t_base_min) {
      let sum = 0;
      let t_mean = 0;
      this.t_means.forEach((t) => {
        t_mean = t < this.TBaseMin(t_base_min) ? 0 : t
        sum += t_mean
      })
      return Math.ceil(sum)
    },
    CHU(t_min, t_max) {
      let x = (1.8 * (t_min - 4.4) + 3.33 * (t_max - 10) - 0.084 * Math.pow(t_max - 10, 2)) / 2
      return x
    }
  }
}
</script>