<template>
    <div>
      <alert :msg.sync="alert" />
      <v-data-table
        :headers="headers"
        :items="items"
        :no-data-text="$t(`admin.${mode}.noDataText`)"
        :loading="loading === mode"
      >
        <template v-slot:item="{item}">
          <tr class="clickable" @click="selectItem(item)">
            <td v-for="header in headers" :key="header.value">
              <template v-if="header.format === 'devices'">
                {{ (getItemValue(item, header) || []).map(device => device.name).join(', ') }}
              </template>
              <template v-else-if="header.format === 'controls'">
                <div class="d-flex align-center justify-end">
                  <v-btn icon @click="selectItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  
                </div>
              </template>
              <template v-else>
                {{ getItemValue(item, header) }}
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
      <edit-item :item="selectedItem" @close="deselectItem" @reload="reloadItemsCallback" />
      
    </div>
  </template>
  
  <script>
  import generalFormMixin from '~/components/mixins/generalFormMixin'
  import EditItem from '~/components/notifications/EditItem'
  
  export default {
    components: {EditItem},
    mixins: [generalFormMixin],
    props: ['mode'],
    data () {
      return {
        items: [],
        selectedItem: null
      }
    },
    computed: {
      modeMName () {
        return this.mode + 's'
      },
      headers () {
        return [
            {text: this.$t('admin.users.devices'), value: 'deviceNames'},
            {text: '', value: 'controls', sortable: false, format: 'controls'}
          ]
      }
    },
    created () {
      this.loadItems()
    },
    methods: {
      getItemValue (item, header) {
        const pathParts = (header.field || header.value || '').split('.')
        let result = item
        for (let i = 0; i < pathParts.length; i++) {
          if (result && Object.prototype.hasOwnProperty.call(result, pathParts[i])) {
            result = result[pathParts[i]]
          } else {
            return header.emptyPlaceholder || null
          }
        }
        return result || header.emptyPlaceholder
      },
      loadItems (force) {
        this.setLoading(this.mode)
        this.resetAlert()
        return Promise.resolve()
          .then(() => this.$store.dispatch('fetch/fetch', {path: 'notifications.list', force}))
          .then((response) => {
            this.items = response.data.map(this.prepareItem)
          })
          .catch(this.handleError)
          .finally(() => {
            this.setLoading(false)
          })
      },
      prepareItem (item) {
        return {
            ...item,
            all_devices: !item.devices || !item.devices.length,
            deviceNames: item.devices && item.devices.length ? item.devices.map(device => device.name).join(', ') : this.$t('form.user.allDevicesShort')
          }
      },
      reloadItemsCallback (callback) {
        return Promise.resolve()
          .then(() => this.loadItems(true))
          .then(() => callback && callback())
      },
      selectItem (item) {
        this.selectedItem = item
      },
      deselectItem () {
        this.selectedItem = null
      },
      addItem () {
        this.selectedItem = {all_devices: true}
      },
      deleteItem (item) {
        this.setLoading('delete-' + item.id)
        return Promise.resolve()
          .then(() => this.$store.dispatch('fetch/fetch', {path: `admin.${this.mode}.delete`, data: {id: item.id}}))
          .then(() => this.loadItems(true))
          .then(() => {
            this.$snack({type: 'success', message: this.$t(`success.${this.mode}Deleted`)})
          })
          .catch(this.handleError)
          .finally(() => {
            this.setLoading(false)
          })
      }
    }
  }
  </script>
  