<template>
  <v-dialog v-model="opened" :persistent="loading" max-width="600">
    <v-card>
      <v-card-title v-if="instance.id">
        {{ $t(`admin.${mode}.title`) }} #{{ instance.id }}
      </v-card-title>
      <v-card-title v-else>
        {{ $t(`admin.${mode}.newTitle`) }}
      </v-card-title>
      <v-card-text>
        <alert :msg.sync="alert" />
        <fields v-model="instance" :fields="fields" />
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <v-spacer />
        <v-btn :loading="loading" color="primary" @click="save">
          {{ instance.id ? $t('actions.save') : $t('actions.create') }}
        </v-btn>
        <v-btn :loading="loading" text @click="opened = false">
          {{ $t('actions.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import generalFormMixin from '~/components/mixins/generalFormMixin'
import configFields from '~/config/fields'

export default {
  mixins: [generalFormMixin],
  props: ['item', 'mode'],
  data () {
    return {
      opened: false,
      instance: {}
    }
  },
  computed: {
    fields () {
      return configFields[this.mode]
        .filter(field => {
          if (!field.permission) { return true }
          return (this.$store.state.auth.user.permissions || []).includes(field.permission)
        })
    }
  },
  watch: {
    item: {
      immediate: true,
      handler (item) {
        if (item) {
          this.instance = this.prepareInstance(item, this.fields)
          this.opened = true
        } else {
          this.opened = false
        }
      }
    },
    opened (value) {
      if (!value) {
        this.$emit('close')
      }
    }
  },
  methods: {
    save () {
      this.setLoading(true)
      this.resetAlert()
      const data = this.prepareFormSubmitData(this.instance, this.fields)
      if (data.all_devices) {
        data.devices = ''
        data.device = ''
      }
      return Promise.resolve()
        .then(() => this.$store.dispatch('fetch/fetch', {path: data.id ? `admin.${this.mode}.update` : `admin.${this.mode}.create`, data}))
        .then(() => new Promise((resolve, reject) => {
          this.$emit('reload', resolve)
        }))
        .then(() => {
          this.$snack({type: 'success', message: this.$t('success.' + this.mode + (data.id ? 'Updated' : 'Created'))})
          this.opened = false
        })
        .catch(error => this.handleError(error, {criticalToAlert: true}))
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
</script>
