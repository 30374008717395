import cookies from 'js-cookie'
import settings from '~/config/settings'

const getFromLocalStorage = (name) => {
  try {
    if (window.localStorage) {
      return window.localStorage.getItem(name)
    }
  } catch (error) {
    console.log('LocalStorage error', error)
  }
  return null
}

const getFromCookies = (name) => {
  try {
    return cookies.get(name)
  } catch (error) {
    console.log('Cookies error', error)
  }
  return null 
}

const setToLocalStorage = (name, value) => {
  try {
    if (window.localStorage) {
      window.localStorage.setItem(name, value)
    }
  } catch (error) {
    console.log('LocalStorage error', error)
  }
}

const setToCookies = (name, value) => {
  try {
    cookies.set(name, value, settings.cookieOptions)
  } catch (error) {
    console.log('Cookies error', error)
  }
}

const removeFromLocalStorage = (name) => {
  try {
    if (window.localStorage) {
      window.localStorage.removeItem(name)
    }
  } catch (error) {
    console.log('LocalStorage error', error)
  }
}

const removeFromCookies = (name) => {
  try {
    cookies.remove(name)
  } catch (error) {
    console.log('Cookies error', error)
  }
}

export default {
  get (name) {
    const value = getFromLocalStorage(name) || getFromCookies(name)
    if (value) {
      try {
        return JSON.parse(value)

      } catch (error) {
        console.log('JSON.parse error', error)
        return value
      }
    }
  },
  set (name, value) {
    const preparedValue = JSON.stringify(value)
    setToLocalStorage(name, preparedValue)
    setToCookies(name, preparedValue)
  },
  remove (name) {
    removeFromLocalStorage(name)
    removeFromCookies(name)
  }
}
