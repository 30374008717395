<template>
  <v-dialog 
    :value="true" 
    :max-width="width" 
    :fullscreen="$vuetify.breakpoint.xs"
    persistent
  >
    <v-card :width="width">
      <v-toolbar v-if="showTitle" dark dense color="primary">
        <v-toolbar-title>{{ $t('menu.' + mode) }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-img 
          v-if="showLogo" 
          :src="require('~/assets/logo.png')" 
          :max-width="350"
          :style="{margin: 'auto'}"
        />
        <alert :msg.sync="alert" :persistent="alert && alert.type === 'success'"/>
        <anim-height>
          <v-form v-if="!alert || alert.type !== 'success'" method="POST" @submit.prevent="submit">
            <v-card-text class="pa-2">
              <fields v-model="instance" :fields="fields" :validation-errors.sync="validationErrors" />
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn :loading="loading" text type="submit">
                {{ $t('actions.' + mode) }}
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-end">
              <v-btn v-if="mode === 'login'" text @click="mode = 'restorePassword'">
                {{ $t('actions.gotoRestorePassword') }}
              </v-btn>
              <v-btn v-else-if="mode === 'restorePassword'" text @click="mode = 'login'">
                {{ $t('actions.gotoLogin') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </anim-height>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import configFields from '~/config/fields'
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
  mixins: [generalFormMixin],
  props: ['width', 'showTitle', 'showLogo'],
  data () {
    return {
      instance: this.prepareInstance({}, configFields.login),
      mode: 'login'
    }
  },
  computed: {
    fields () { return configFields[this.mode] }
  },
  watch: {
    mode () {
      this.instance = this.prepareInstance({}, this.fields)
      this.alert = null
      this.resetValidationErrors()
    }
  },
  methods: {
    success (response) {
      return Promise.resolve()
        .then(() => {
          this.alert = {type: 'success', message: this.$t(response.data.message || response.data.response || 'success.loggedIn')}
          this.$snack(this.alert)
        })
        .then(() => new Promise((resolve, reject) => {
          if (this.mode === 'login') {
            setTimeout(() => {
              this.$router.push(this.$route.query.redirect || '/')
              resolve()
            }, 3000)
          } else resolve()
        }))
        .finally(() => {
          this.setLoading(false)
        })
    },
    submit () {
      this.resetAlert()
      this.setLoading(true)
      return Promise.resolve()
        .then(() => this.$store.dispatch('auth/' + this.mode, this.prepareFormSubmitData(this.instance, this.fields)))
        .then(this.success)
        .catch(this.handleError)
        .finally(() => this.setLoading(false))
    }
  }
}
</script>
